
import {computed, defineComponent, onMounted, reactive, ref, watch} from 'vue';
import {AbstractSearchItem} from '@/greeve/search/item/abstract_search_item.type';
import OutputCard from '@/components/assistant/Output/OutputCard.vue';
import DefaultOutputElement from '@/components/assistant/Output/Elements/DefaultOutputElement.vue';
import AudioOutputElement from '@/components/assistant/Output/Elements/AudioOutputElement.vue';
import ImageOutputElement from '@/components/assistant/Output/Elements/ImageOutputElement.vue';
import {GreeveSearchItemSubTypeInterface} from '@/greeve/search/item/search_item.interface';
import {RadioGroup, RadioGroupLabel, RadioGroupOption} from '@headlessui/vue';
import UploadInteraction from '@/components/assistant/Output/Elements/Interaction/UploadInteraction.vue';
import {MediaFileInfo} from '@/greeve/media/media_file_info.type';
import ImageVariationOutputElement from '@/components/assistant/Output/Elements/ImageVariationOutputElement.vue';
import ImageVariationRequestImageElement
	from '@/components/assistant/Output/Elements/ImageVariationRequestImageElement.vue';
import ImageVisionInitialImageElement from '@/components/assistant/Output/Elements/ImageVisionRequestImageElement.vue';
import useTranslation from '@/composable/translation/useTranslation';
import InfoQuickViewDialog from '@/components/modal/InfoQuickViewDialog.vue';

export default defineComponent({
	name: 'OutputContainer',
	components: {
		InfoQuickViewDialog,
		ImageVisionInitialImageElement,
		ImageVariationRequestImageElement,
		ImageVariationOutputElement,
		UploadInteraction,
		ImageOutputElement,
		AudioOutputElement,
		DefaultOutputElement,
		OutputCard,
		RadioGroup,
		RadioGroupLabel,
		RadioGroupOption,
	},
	props: {
		searchItem: {
			type: Object as () => AbstractSearchItem,
		},
	},
	emits: ['retryErrorItem', 'cancelUploadPrompt', 'uploadFinished', 'updateUploadInteractionType', 'enableTextInput', 'disableTextInput'],
	setup(props, {emit}) {
		const contentSearchItem = ref<AbstractSearchItem|undefined>(props.searchItem);
		const fileUploadLoading = ref(false);
		const {t} = useTranslation();

		const selectedImageUploadData = reactive({
			count: 1,
		});

		const selectedUploadAction = ref();
		const uploadActions =
				[
					// { globalType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE, subType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_EDIT, name: 'Edit Image', visible: true},
					// { globalType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE, subType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_REMOVE_BACKGROUND, name: 'Change Background', visible: true},
					{ globalType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE, subType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION, name: t('assistant.output.upload.file.image.variations.button.title'), visible: true},
					{ globalType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE, subType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION, name: t('assistant.output.upload.file.image.vision.button.title'), visible: true},
					{ globalType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT, subType: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT, name: t('assistant.output.upload.file.speech_to_text.variations.speech_to_text.button.title'), visible: true},
				];

		const getUploadAction = computed(() => {
			const subType = contentSearchItem.value?.subType;
			if (subType) {
				const action = uploadActions.find(action => action.subType === subType);
				if (action) {
					return action;
				}
				return uploadActions.find(action => action.globalType === subType);
			}
			return undefined;
		})

		
		const isUploadActionListVisible = computed(() => {
			switch (contentSearchItem.value?.subType) {
				case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
					return false;
				default:
					return true;
			}
		});

		const getAllowedUploadActionListBySubType = computed(() => {
			if (!isUploadActionListVisible.value) {
				return [];
			}
			let filterActions =  uploadActions.filter(action => action.globalType === contentSearchItem.value?.subType);
			if (!filterActions || !filterActions.length) {
				const actionBySubType = uploadActions.find(action => action.subType === contentSearchItem.value?.subType);
				if (actionBySubType && actionBySubType.globalType) {
					filterActions =  uploadActions.filter(action => action.globalType === actionBySubType.globalType);
				}
			}
			return filterActions;
		});


		const isInfoDialogVisible = ref(false);

		const GreeveSearchItemSubType = {
			SEARCH_ITEM_SUBTYPE_DEFAULT: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_DEFAULT,
			SEARCH_ITEM_SUBTYPE_CHAT: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_CHAT,
			SEARCH_ITEM_SUBTYPE_COMPLETION: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_COMPLETION,
			SEARCH_ITEM_SUBTYPE_IMAGE: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE,
			SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION,
			SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT,
			SEARCH_ITEM_SUBTYPE_TEXT_TO_SPEECH: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_TEXT_TO_SPEECH,
			SEARCH_ITEM_SUBTYPE_VISION: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION,
			SEARCH_ITEM_SUBTYPE_HTML: 'html',
			SEARCH_ITEM_SUBTYPE_RECIPE: 'recipe',
			SEARCH_ITEM_SUBTYPE_STUDY: 'study',
			SEARCH_ITEM_SUBTYPE_IMAGE_GENERATION: 'image_generation',
			SEARCH_ITEM_SUBTYPE_CODE: 'code',
			SEARCH_ITEM_SUBTYPE_BLOG: 'blog',
			SEARCH_ITEM_SUBTYPE_ARTICLE: 'article',
		}

		const getUploadTitle = computed(() => {
			if (!contentSearchItem.value?.isInteractionSearchItemUpload()) {
				return '';
			}

			switch (contentSearchItem.value?.subType) {
				case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
				case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
					return t('assistant.output.upload.file.image.title');
				case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
					return t('assistant.output.upload.file.speech_to_text.title');
				default:
					return '';
			}
		});

		const getUploadInfoTitle = computed(() => {
			if (!contentSearchItem.value?.isInteractionSearchItemUpload()) {
				return '';
			}

			switch (contentSearchItem.value?.subType) {
				case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
					return t('assistant.output.upload.file.image.variations.info.title');
				case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
					return t('assistant.output.upload.file.image.vision.info.title');
				case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
					return t('assistant.output.upload.file.speech_to_text.info.title');
				default:
					return '';
			}
		});

		const getUploadInfoSubTitle = computed(() => {
			if (!contentSearchItem.value?.isInteractionSearchItemUpload()) {
				return '';
			}

			switch (contentSearchItem.value?.subType) {
				case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
					return t('assistant.output.upload.file.image.variations.info.subTitle');
				case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
					return t('assistant.output.upload.file.image.vision.info.subTitle');
				case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
					return t('assistant.output.upload.file.speech_to_text.info.subTitle');
				default:
					return '';
			}
		});

		const getUploadInfoDescription = computed(() => {
			if (!contentSearchItem.value?.isInteractionSearchItemUpload()) {
				return '';
			}

			switch (contentSearchItem.value?.subType) {
				case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
					return t('assistant.output.upload.file.image.variations.info.description');
				case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
					return t('assistant.output.upload.file.image.vision.info.description');
				case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
					return t('assistant.output.upload.file.speech_to_text.info.description');
				default:
					return '';
			}
		});

		const getUploadAllowedFileTypeList = computed(() => {
			if (!contentSearchItem.value?.isInteractionSearchItemUpload()) {
				return [];
			}
			switch (contentSearchItem.value?.subType) {
				case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE:
				case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
				case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
					return ['image/jpeg', 'image/png', 'image/heic', 'image/heif', 'image/webp'];
				case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
					return ['audio/mpeg', 'audio/wav', 'audio/ogg', 'audio/mp4', 'audio/aac', 'audio/x-m4a', 'video/mp4'];
				default:
					return [];
			}
		});

		function retryErrorItem(searchItemUuid: string, subType: GreeveSearchItemSubTypeInterface) {
			emit('retryErrorItem', searchItemUuid, subType);
		}

		function cancelUploadPrompt(searchItemUuid: string|undefined) {
			emit('cancelUploadPrompt', searchItemUuid);
			emit('enableTextInput');
		}

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		function uploadStarted(file: Blob) {
			fileUploadLoading.value = true;
			emit('updateUploadInteractionType', contentSearchItem.value?.uuid, selectedUploadAction.value?.subType)
			emit('disableTextInput');
		}

		function uploadFinished(fileInfo: MediaFileInfo) {
			fileUploadLoading.value = false;
			emit('uploadFinished', contentSearchItem.value?.uuid, fileInfo)
			emit('enableTextInput');
		}

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		function uploadError(e: any) {
			fileUploadLoading.value = false;
			emit('enableTextInput');
		}

		watch(() => props.searchItem, (newItem) => {
			if (newItem && newItem !== contentSearchItem.value) {
				contentSearchItem.value = newItem;
			}
		});

		watch(() => selectedUploadAction.value, (newSelectedUploadAction, oldValue) => {
			if ((!oldValue || oldValue.subType !== newSelectedUploadAction.subType) && newSelectedUploadAction.subType) {
				emit('updateUploadInteractionType', contentSearchItem.value?.uuid, newSelectedUploadAction.subType)
			}
		});

		onMounted(() => {
			if (contentSearchItem.value && contentSearchItem.value?.isInteractionSearchItemUpload()) {
				if (!selectedUploadAction.value) {
					selectedUploadAction.value = getUploadAction.value;
				}

				emit('updateUploadInteractionType', contentSearchItem.value?.uuid, selectedUploadAction.value?.subType)
			}
		})

		return {
			contentSearchItem,
			GreeveSearchItemSubType,
			retryErrorItem,
			uploadActions,
			selectedUploadAction,
			cancelUploadPrompt,
			uploadStarted,
			uploadFinished,
			uploadError,
			fileUploadLoading,
			selectedImageUploadData,
			isInfoDialogVisible,
			getUploadTitle,
			getUploadInfoTitle,
			getUploadInfoSubTitle,
			getUploadInfoDescription,
			getUploadAllowedFileTypeList,
			isUploadActionListVisible,
			getAllowedUploadActionListBySubType,
		};
	}
});

