
import {ref, defineComponent, onBeforeMount} from 'vue';
import useShop from '@/composable/greeve/useShop';
import {
	CheckIcon,
	ChevronDownIcon,
} from '@heroicons/vue/24/outline';
import {useRouter} from 'vue-router';
import {ProductList} from '@/greeve/shop/product/product_list.type';
import {Product} from '@/greeve/shop/product/product.type';
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/vue';

export default defineComponent({
	name: 'GreeveOneTimeProducts',
	components: {
		ListboxOption,
		ListboxOptions,
		ListboxButton,
		Listbox,
		CheckIcon,
		ChevronDownIcon,
	},
	props: {
		showProductFeatures: {
			type: Boolean,
			default: false,
		},
		showTransition: {
			type: Boolean,
			default: false,
		},
		hideBottomPadding: {
			type: Boolean,
			default: false,
		},
	},
	emits: [],
	setup() {
		const router = useRouter();
		const {products, addProductToCart, initProducts} = useShop();
		const oneTimeProductList: ProductList | any[] | any = ref();
		const isProductLoading = ref(false);
		const selected: Product | any = ref();

		function addSelectedOneTimeToCart() {
			if (selected.value) {
				addProductToCart(selected.value.product_id);
				router.push('/shop');
			}
		}

		function getOneTimeProducts(force = false): ProductList | Product[] {
			if (!oneTimeProductList.value || force) {
				if (!isProductLoading.value) {
					oneTimeProductList.value = products.value.getOneTimeProductList();
					selected.value = oneTimeProductList.value[1];
				}
			}
			return oneTimeProductList.value;
		}

		async function initProductsForView() {
			getOneTimeProducts(true);
		}

		onBeforeMount(() => {
			try {
				//TODO performance!! if products older than 8 hours or dev
				if (products.value.getOneTimeProductList().length) {
					initProductsForView();
				} else {
					isProductLoading.value = true;
					initProducts(true).then(() => {
						isProductLoading.value = false;
						initProductsForView();
					});
				}
			} catch (e) {
				console.error(e);
			}
		});

		return {
			selected,
			products,
			getOneTimeProducts,
			addSelectedOneTimeToCart,
			oneTimeProductList,
		};
	},
});
