
import {defineComponent, onMounted} from 'vue';
import useFlow from '@/composable/greeve/useFlow';
import InfoCard from '@/components/modal/InfoCard.vue';
import AccountManagement from "@/components/shop/profile/AccoutManagement.vue";

export default defineComponent({
	name: 'CreditsPage',
	components: {
		InfoCard,
		AccountManagement,
	},
	setup() {
		const {flowTemplates, initFlowTemplates} = useFlow();

		onMounted(() => {
			initFlowTemplates(true);
		});

		return {flowTemplates};
	},
});

