
import {defineComponent} from 'vue';
import {SearchItemImageVariation} from '@/greeve/search/item/type/subtype/search_item_image_variation.type';
import ImageOutputElement from '@/components/assistant/Output/Elements/ImageOutputElement.vue';

export default defineComponent({
	name: 'ImageVariationOutputElement',
	components: {ImageOutputElement},
	props: {
		searchItem: {
			type: Object as () => SearchItemImageVariation,
		},
	},
	emits: [],
	setup() {
		return {}
	}
});

