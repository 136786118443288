
import {defineComponent, ref} from 'vue';
import {
	IonModal,
	IonContent,
	IonButton,
} from '@ionic/vue';
import {XMarkIcon} from '@heroicons/vue/24/outline';
import { useBackButton } from '@ionic/vue';

export interface ImageResponseInterface {
	url: string,
}

export default defineComponent({
	name: 'ImageOutputElement',
	components: {
		XMarkIcon,
		IonModal, IonContent,
		IonButton,
	},
	props: {
		response: {
			type: Array as () => Array<ImageResponseInterface> | undefined,
			required: true,
		},
	},
	emits: [],
	setup(props) {
		const showModal = ref(false);
		const modalImage = ref('');

		const openModal = (imageUrl: string) => {
			if (!imageUrl) return;
			modalImage.value = imageUrl;
			showModal.value = true;
		};

		const closeModal = () => {
			showModal.value = false;
		};

		const getImages = (): Array<string>|any => {

			const imageUrlList = props.response?.map(item => item.url);

			if (!imageUrlList) {
				return;
			}

			return imageUrlList;
		};

		useBackButton(10, () => {
			closeModal();
		});

		return {
			showModal,
			modalImage,
			openModal,
			closeModal,
			getImages
		};
	}
});

