
import {defineComponent} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import useTranslation from "@/composable/translation/useTranslation";
import HeaderLogo from "@/components/branding/HeaderLogo.vue";


export default defineComponent({
  name: 'ImprintPage',
  components: {
    HeaderLogo,
    FooterBar, IonContent, IonPage},
  setup() {
    const {t} = useTranslation();
    return {t,}
  }
});

