import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { "aria-labelledby": "contact-info-heading" }
const _hoisted_2 = {
  id: "contact-info-heading",
  class: "text-lg font-semibold text-gray-900 dark:text-white"
}
const _hoisted_3 = { class: "mt-6" }
const _hoisted_4 = { class: "pt-6" }
const _hoisted_5 = { class: "-mt-3 p-3" }
const _hoisted_6 = { class: "relative flex items-start" }
const _hoisted_7 = { class: "flex h-6 items-center" }
const _hoisted_8 = { class: "ml-3 text-sm leading-6" }
const _hoisted_9 = {
  for: "saveAddress",
  class: "font-medium text-gray-900 dark:text-gray-200"
}
const _hoisted_10 = {
  id: "saveAddress-description",
  class: "text-gray-500"
}
const _hoisted_11 = { class: "sr-only" }
const _hoisted_12 = {
  key: 0,
  class: "py-2"
}
const _hoisted_13 = {
  key: 1,
  class: "py-2"
}
const _hoisted_14 = { class: "mt-2 text-center text-sm text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_line_animation = _resolveComponent("loading-line-animation")!
  const _component_stripe_address = _resolveComponent("stripe-address")!
  const _component_default_button = _resolveComponent("default-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('contactInformation')), 1),
      _withDirectives(_createVNode(_component_loading_line_animation, { "position-css": "top-0 absolute w-full" }, null, 512), [
        [_vShow, _ctx.isLoading]
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_stripe_address, {
          onOnChangeAddress: _ctx.updateContactAddress,
          onOnInitialized: _ctx.stopLoading
        }, null, 8, ["onOnChangeAddress", "onOnInitialized"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _withDirectives(_createElementVNode("input", {
              id: "saveAddress",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.saveAddress) = $event)),
              "aria-describedby": "saveAddress-description",
              name: "saveAddress",
              type: "checkbox",
              class: "h-4 w-4 rounded border-gray-300 text-primary focus:ring-gr-primary"
            }, null, 512), [
              [_vModelCheckbox, _ctx.saveAddress]
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('saveAddress')), 1),
            _createTextVNode(" " + _toDisplayString(' ') + " "),
            _createElementVNode("span", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('saveAddress')), 1),
              _createTextVNode(_toDisplayString(_ctx.$t('forFutureUse')), 1)
            ])
          ])
        ])
      ]),
      (_ctx.isAddressValid)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode(_component_default_button, {
              "button-color": "bg-gr-primary",
              "button-text": _ctx.$t('shop.checkout.order_information.step'),
              "button-type": "button",
              onClickButton: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSuccess()))
            }, null, 8, ["button-text"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createVNode(_component_default_button, {
              "button-color": "bg-gr-primary",
              "button-disabled": true,
              "button-text": _ctx.$t('shop.checkout.contact_information.fill_data'),
              "button-type": "button"
            }, null, 8, ["button-text"])
          ])),
      _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t('youWontBeChargedUntilTheLastStep')), 1)
    ])
  ], 64))
}