import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingAnimation = _resolveComponent("LoadingAnimation")!
  const _component_NavbarPro = _resolveComponent("NavbarPro")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_HeaderInfo = _resolveComponent("HeaderInfo")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_CookiesDialog = _resolveComponent("CookiesDialog")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isGlobalLoading)
      ? (_openBlock(), _createBlock(_component_LoadingAnimation, {
          key: 0,
          "loading-text": _ctx.$t('loading.DefaultSpinner.loadingText')
        }, null, 8, ["loading-text"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ion_app, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_NavbarPro)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content, {
          class: "ion-padding",
          "scroll-events": true,
          fullscreen: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_HeaderInfo, {
              id: "promo-header",
              "promo-code": "GREEVEBETA20",
              "always-visible": false
            }),
            _createVNode(_component_ion_router_outlet, { class: "h-full w-full" })
          ]),
          _: 1
        }),
        _createVNode(_component_CookiesDialog),
        _createVNode(_component_ion_footer)
      ]),
      _: 1
    })
  ], 64))
}