
import {defineComponent} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import useTranslation from "@/composable/translation/useTranslation";
import ErrorComponent from "@/components/errors/ErrorComponent.vue";
import {useHeaderHeight} from "@/composable/global/useHeaderHeight";


export default defineComponent({
	name: 'ErrorPage',
	components: {
		ErrorComponent, FooterBar, IonContent, IonPage
	},
	setup() {
		const { headerHeight } = useHeaderHeight();

		const {t} = useTranslation();
		return {
      t, headerHeight
		}
	}
});

