import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f6ddc72"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", {
      innerHTML: _ctx.displayedContent,
      class: "rounded-3xl whitespace-pre-wrap selectable-text"
    }, null, 8, _hoisted_1),
    (_ctx.isTruncated)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCollapse && _ctx.toggleCollapse(...args))),
          class: "text-gr-primary3 pl-2"
        }, _toDisplayString(_ctx.collapsed ? _ctx.$t('enterprise.stats.models.show_more') : _ctx.$t('enterprise.stats.models.show_less')), 1))
      : _createCommentVNode("", true)
  ]))
}