import { Store, createLogger } from 'vuex';
import { createStore } from 'vuex-extensions';
import alert from "@/store/modules/alert";
import auth from "@/store/modules/auth";
import custom from "@/store/modules/custom";
import user from "@/store/modules/user";
import shop from "@/store/modules/shop";
import order from "@/store/modules/order";
import system from "@/store/modules/system";
import country from "@/store/modules/country";
import faq from "@/store/modules/faq";
import flow from "@/store/modules/flow";
import assistant from "@/store/modules/assistant";
import VuexPersistence, { PersistOptions } from 'vuex-persist';
import useSystem from '@/composable/core/useSystem';
// import lzstring from 'lz-string';

const debug = process.env.NODE_ENV !== 'production';

interface CustomPersistOptions<S> extends PersistOptions<S> {
	transformer?: {
		in: (state: S) => any;
		out: (state: any) => S;
	};
}

const vuexLocal = new VuexPersistence({
	// storage: window.sessionStorage,
	storage: window.localStorage,
	reducer: (state: any) => {
		// Specify which parts of the state to persist
		// Modify as needed to only persist essential parts
		if (useSystem().isMobileDevice() || useSystem().isNativePlatform()) {
			return {
				auth: state.auth,
				custom: state.custom,
				user: state.user,
				shop: state.shop,
			}
		}
		return {
			auth: state.auth,
			custom: state.custom,
			user: state.user,
			shop: state.shop,
			// assistant: state.assistant,
		}
		// alert: state.alert,
		// order,
		// system,
		// country,
		// faq,
		// flow,
		// Add other modules as needed
	},
	// restoreState: (key, storage) => {
	// 	const compressedState = storage?.getItem(key);
	// 	if (compressedState) {
	// 		try {
	// 			return JSON.parse(lzstring.decompressFromUTF16(compressedState));
	// 		} catch (e) {
	// 			console.error(e);
	// 			return compressedState;
	// 		}
	// 	}
	// 	return undefined;
	// },
	// saveState: (key, state, storage) => {
	// 	try {
	// 		const compressedState = lzstring.compressToUTF16(JSON.stringify(state));
	// 		storage?.setItem(key, compressedState);
	// 	} catch (e) {
	// 		console.error(e);
	// 		storage?.setItem(key, JSON.stringify(state));
	// 	}
	// }
} as CustomPersistOptions<any>); // Cast to CustomPersistOptions

export default createStore(Store, {
	state: {},
	mutations: {},
	actions: {},
	modules: {
		alert,
		auth,
		custom,
		user,
		shop,
		order,
		system,
		country,
		faq,
		flow,
		assistant,
	},
	strict: debug,
	plugins: debug ? [createLogger(), vuexLocal.plugin] : [vuexLocal.plugin],
});
