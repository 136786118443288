
import {defineComponent} from 'vue';
import {Share} from '@capacitor/share';
import useToastMessage from '@/composable/core/useToastMessage';
import useSystem from '@/composable/core/useSystem';
import {ShareOptions} from '@capacitor/share/dist/esm/definitions';
import {ActionSheetButton, actionSheetController} from '@ionic/vue';
import * as icons from 'ionicons/icons';
import useCustomStore from '@/composable/custom/useCustomStore';
import useTranslation from '@/composable/translation/useTranslation';
import {ChatType} from '@/views/assistant/Chat/ChatPage.vue';
import {AbstractSearchItem} from '@/greeve/search/item/abstract_search_item.type';

export default defineComponent({
	name: 'ShareAction',
	props: {
		reference: {
			type: String,
			default: "",
		},
		description: {
			type: String,
			default: "",
		},
		dialogTitle: {
			type: String,
			//TODO add Translation
			default: "Look at my greeve search result 😉",
		},
		shareLink: {
			type: String,
			default: '',
		},
		shareMode: {
			type: String as () => ChatType,
			default: () => ChatType.CHAT,
		},
		shareImage: {
			type: String,
			default: '',
		},
		sharedParam: {
			type: String,
			default: 'shared', //if you want to use a group reference change the prop to groupShared
		},
		searchItem: {
			type: Object as () => AbstractSearchItem,
			default: null,
		},
	},
	components: {},
	emits: [],
	setup(props) {
		const {t} = useTranslation();
		const {openToast} = useToastMessage();
		const {isNativePlatform, isIosOrAndroid, copyContent, copyImageToClipboard, extractImageUrl_by_HtmlImage, downloadUsingFetch, downloadPdfByHtml, downloadPdfBySearchItem} = useSystem();
		const {createAndAppendSocialMetaProperty, resetSocialMetaProperties} = useCustomStore();

		function getShareDataContent(): ShareOptions | any {
			if (!props.reference) {
				openToast(t("toast.errorShare"), 'danger', 'top', true, 7000, undefined, true);
				return;
			}

			return {
				title: t("share.default") + props.description,
				text: props.description,
				url: encodeURI(props.shareLink),
				dialogTitle: props.dialogTitle,
			}
		}

		async function shareCardNative() {
			await Share.share(getShareDataContent());
		}

		async function shareCardWebMobile() {
			try {
				await navigator.share(getShareDataContent());
				await openToast(t("toast.default"), 'success', 'top', true, 5000, undefined, true);
			} catch (err) {
				//
			}
		}

		async function shareCardWeb() {
			try {
				const sharedData = getShareDataContent();

				let title = '';
				let shortTitle = '';
				if (props.searchItem.search_group?.getName()) {
					title = props.searchItem.search_group?.getName();
					shortTitle = props.searchItem.search_group?.getName(50);
				}
				let rawContent = sharedData.text;
				if (props.searchItem && props.searchItem.getContent()) {
					rawContent = props.searchItem.getContent();
				}
				const actionSheetButtonList: Array<ActionSheetButton> = [
					{
						text: t("share.copy"),
						icon: icons.copyOutline,
						handler: () => {
							if (props.shareMode === ChatType.IMAGE) {
								let imageUrl: string|any = extractImageUrl_by_HtmlImage(sharedData.text);
								if (!imageUrl) {
									imageUrl = sharedData.text;
								}
								copyImageToClipboard(imageUrl);
							} else {
								copyContent(sharedData.url);
							}
						},
					},
					{
						text: 'Facebook',
						icon: icons.logoFacebook,
						handler: () => {
							let shareImage: string|any = props.shareImage;
							if (!shareImage.length && props.shareMode === ChatType.IMAGE) {
								shareImage = extractImageUrl_by_HtmlImage(sharedData.text);
								if (!shareImage) {
									shareImage = sharedData.text;
								}
							}
							const shareUrl = 'http://www.facebook.com/sharer.php?s=100&p[title]=' + sharedData.title + '&p[summary]=' + sharedData.text + '&p[url]=' + sharedData.url + '&p[images][0]=' + shareImage;
							// const shareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + sharedData.url;
							window.open(shareUrl, '_blank');
						},
					},
					{
						text: 'Twitter',
						icon: icons.logoTwitter,
						handler: () => {
							const shareUrl = 'https://twitter.com/intent/tweet?text=' + sharedData.url;
							window.open(shareUrl, '_blank');
						},
					},
				];
				if (props.shareImage && props.shareMode === ChatType.IMAGE) {
					const downloadAction: ActionSheetButton = {
						text: 'Download',
						icon: icons.downloadOutline,
						handler: () => {
							downloadUsingFetch(props.shareImage, sharedData.text);
						},
					};
					actionSheetButtonList.push(downloadAction);
				}

				if ([ChatType.CHAT, ChatType.TRANSCRIBE].includes(props.shareMode)) {
					const downloadPdf: ActionSheetButton = {
						text: t("share.pdf"),
						icon: icons.downloadOutline,
						handler: () => {
							if (props.shareMode === ChatType.IMAGE) {
								let imageUrl: string | any = extractImageUrl_by_HtmlImage(sharedData.text);
								if (!imageUrl) {
									imageUrl = sharedData.text;
								}
								const imageHtml = `<img src="${imageUrl}"/>`;
								downloadPdfByHtml(shortTitle, imageHtml, title);
							} else {
								downloadPdfBySearchItem(shortTitle, props.searchItem.uuid, title);
							}
						}
					};
					actionSheetButtonList.push(downloadPdf);
				}

				const actionSheet = await actionSheetController
					.create({
						header: t("share.default"),
						cssClass: '',
						buttons: actionSheetButtonList,
					});
				await actionSheet.present();
				await actionSheet.onDidDismiss();
			} catch (err) {
				await openToast( t("share.error") + err, 'danger', 'top', true, 7000, undefined, true);
			}
		}

		async function shareCard() {
			resetSocialMetaProperties();
			createAndAppendSocialMetaProperty(window.location.href, 'website', t("share.defaultShareResult"), props.description);

			if (isNativePlatform()) {
				await shareCardNative();
			} else if (!isNativePlatform() && isIosOrAndroid()) {
				await shareCardWebMobile();
			} else {
				await shareCardWeb();
			}
		}

		return {t, shareCard}
	}
})
