class MarkdownParser {

	private _cssNamespace = '';
	constructor(cssNameSpace = '') {
		this._cssNamespace = cssNameSpace;
	}

	get cssNamespace(): string {
		return this._cssNamespace;
	}

	set cssNamespace(value: string) {
		this._cssNamespace = value;
	}

	public markdownToHtml(markdownText: string): string {
		// Convert headers
		markdownText = markdownText.replace(/(?:\n|^)#{1} (.+)/g,`<h1 class="${this._cssNamespace}">$1</h1>`);
		markdownText = markdownText.replace(/(?:\n|^)#{2} (.+)/g,`<h2 class="${this._cssNamespace}">$1</h2>`);
		markdownText = markdownText.replace(/(?:\n|^)#{3} (.+)/g,`<h3 class="${this._cssNamespace}">$1</h3>`);
		markdownText = markdownText.replace(/(?:\n|^)#{4} (.+)/g,`<h4 class="${this._cssNamespace}">$1</h4>`);
		markdownText = markdownText.replace(/(?:\n|^)#{5} (.+)/g,`<h5 class="${this._cssNamespace}">$1</h5>`);
		markdownText = markdownText.replace(/(?:\n|^)#{6} (.+)/g,`<h6 class="${this._cssNamespace}">$1</h6>`);
		// Convert bold text
		markdownText = markdownText.replace(/\*\*(.+?)\*\*/g, `<strong class="${this._cssNamespace}">$1</strong>`);
		markdownText = markdownText.replace(/__(.+?)__/g, `<strong class="${this._cssNamespace}">$1</strong>`);
		// Convert italic text
		markdownText = markdownText.replace(/\*(.+?)\*/g, `<em class="${this._cssNamespace}">$1</em>`);
		markdownText = markdownText.replace(/_(.+?)_/g, `<em class="${this._cssNamespace}">$1</em>`);
		// Convert links
		markdownText = markdownText.replace(/\[(.*?)\]\((.*?)\)/g, `<a href="$2" target="_blank" class="${this._cssNamespace}">$1</a>`);

		// Convert images
		markdownText = markdownText.replace(/!\[(.*?)\]\((.*?)\)/g, `<img src="$2" alt="$1" class="${this._cssNamespace}"/>`);

		// Convert code blocks
		markdownText = markdownText.replace(
				/```(\w+)\n([\s\S]*?)```/g,
				(match, language, codeBlock) => {
					// Escape backticks within code content
					const escapedCodeBlock = codeBlock.replace(/`/g, '&#96;');
					return `<pre class="${this._cssNamespace} language-${language}"><code class="${this._cssNamespace} language-${language}">${escapedCodeBlock}</code></pre>`;
				}
		);

		// Convert inline code
		markdownText = markdownText.replace(/`([^`]*)`/g, `<code class="${this._cssNamespace}">$1</code>`);

		// Convert blockquotes
		markdownText = markdownText.replace(/(?:\n|^)>(.+)/g, `<blockquote class="${this._cssNamespace}">$1</blockquote>`);

		// Convert unordered lists
		markdownText = markdownText.replace(/(?:\n|^)- (.+)/g, `<ul class="${this._cssNamespace}"><li class="${this._cssNamespace}">$1</li></ul>`);

		// Convert ordered lists
		markdownText = markdownText.replace(/(?:\n|^)\d+\. (.+)/g, `<ol class="${this._cssNamespace}"><li class="${this._cssNamespace}">$1</li></ol>`);

		// Convert tables
		const tableRegex = /\|(.+?)\|(.+?)\|(.+?)\|(.+?)\|\s*(?:\n|\r\n?)((?: *\|.+\|\n)+)(?:\n|\r\n?)?/g;

		markdownText = markdownText.replace(tableRegex, (match: string, ...tableParts: string[]) => {
			const headers: string[] = tableParts.slice(0, 4).map(header => header.trim());
			const rows: string[] = tableParts[4].trim().split(/\n|\r\n/);

			let tableHtml = `<div class="${this._cssNamespace} overflow-hidden rounded-lg bg-white shadow"><div class="px-4 py-5 sm:p-6"><table class="min-w-full divide-y divide-gray-300 chat-result">`;
			tableHtml += `<thead><tr>`;
			headers.forEach((header: string) => {
				tableHtml += `<th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">${header}</th>`;
			});
			tableHtml += `</tr></thead><tbody class="divide-y divide-gray-200 bg-white">`;
			rows.forEach((row: string, index: number) => {
				row = row.replace(/\|[-: ]+\|[-: ]+\|[-: ]+\|[-: ]+\|/g, '');
				const cells: string[] = row.trim().split('|').slice(1, -1).map(cell => cell.trim());
				if (cells.length > 0) {
					tableHtml += `<tr>`;
					cells.forEach((cell: string) => {
						tableHtml += `<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">${cell}</td>`;
					});
					tableHtml += `</tr>`;
				}
			});
			tableHtml += `</tbody></table></div></div>`;

			return tableHtml;
		});

		// Convert custom color syntax
		markdownText = markdownText.replace(/\{color:(.+?)\}(.+?)\{\/color\}/g, `<span style="color:$1" class="${this._cssNamespace}">$2</span>`);

		return markdownText;
	}


	public markdownToText(markdownText: string): string {
		// Remove Headers
		markdownText = markdownText.replace(/(?:\n|^)#{1,6} (.+)/g,'$1');
		// Remove bold text
		markdownText = markdownText.replace(/\*\*(.+?)\*\*/g, '$1');
		markdownText = markdownText.replace(/__(.+?)__/g, '$1');
		// Remove italic text
		markdownText = markdownText.replace(/\*(.+?)\*/g, '$1');
		markdownText = markdownText.replace(/_(.+?)_/g, '$1');
		// Remove links
		markdownText = markdownText.replace(/\[(.*?)\]\((.*?)\)/g,'$1');
		// Remove code blocks
		markdownText = markdownText.replace(/```([^`]*)```/g, '$1');
		// Remove inline code
		markdownText = markdownText.replace(/`([^`]*)`/g, '$1');
		// Remove ordered lists
		markdownText = markdownText.replace(/(?:\n|^)\d\. (.+)/g,'$1');

		return markdownText;
	}
}

export default MarkdownParser;