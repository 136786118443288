import {AddressInterface, ProfileInterface} from "@/greeve/user/user.interface";

export class UserProfile implements ProfileInterface {
	id: number;
	user_id: number;
	country_code?: string;
	language?: string;
	address?: AddressInterface;
	first_name?: string;
	last_name?: string;
	username: string;
	degree?: string;
	gender?: string;
	birthday?: Date;
	job?: string;
	profile_picture?: string;
	hide_profile: boolean;
	hide_name: boolean;
	beta: boolean;
	data?: any;
	created_at?: Date;
	updated_at?: Date;


	constructor(id: number, userId: number, countryCode: string, language: string, username: string, beta = false, hideProfile = false, hideName = false, firstName?: string, lastName?: string, address?: AddressInterface, degree?: string, gender?: string, birthday?: Date, profilePicture?: string|undefined, job?: string|undefined, data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.user_id = userId;
		this.country_code = countryCode;
		this.language = language;
		this.address = address;
		this.first_name = firstName;
		this.last_name = lastName;
		this.username = username;
		this.degree = degree;
		this.gender = gender;
		this.birthday = birthday;
		this.job = job;
		this.profile_picture = profilePicture;
		this.hide_profile = hideProfile;
		this.hide_name = hideName;
		this.beta = beta;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}
}