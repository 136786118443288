import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-99f9ac44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sm:overflow-hidden rounded-xl nav backdrop-filter backdrop-blur-3xl" }
const _hoisted_2 = { class: "nav rounded-t-xl space-y-6 py-6 px-4 sm:p-6" }
const _hoisted_3 = { class: "space-y-2 sm:space-y-4" }
const _hoisted_4 = { "aria-labelledby": "payment-details-heading" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "flex md:space-x-32 flex-wrap" }
const _hoisted_7 = { class: "flex-1 sm:flex-auto" }
const _hoisted_8 = {
  role: "tablist",
  class: "tabs tabs-boxed"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "text-base font-semibold leading-6 text-gray-900 dark:text-gray-500 pt-2" }
const _hoisted_12 = { class: "my-5 grid grid-cols-1 gap-5 sm:grid-cols-1" }
const _hoisted_13 = { class: "overflow-hidden rounded-xl bg-gray-100 dark:bg-gr-darker px-4 py-5 sm:p-6" }
const _hoisted_14 = { class: "truncate text-sm font-medium text-gray-500" }
const _hoisted_15 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900 dark:text-gray-500" }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "flex-1 sm:flex-auto" }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "text-lg font-medium leading-6 text-gray-900 dark:text-gray-300" }
const _hoisted_20 = { class: "mt-1 text-sm text-gray-500 mb-4" }
const _hoisted_21 = {
  "aria-describedby": "tier-scale",
  class: "inline-flex justify-center rounded-xl bg-gr-primary hover:bg-gr-primary2 py-1.5 px-10 text-sm font-semibold text-white shadow-sm focus:outline-none"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_greeve_one_time_products = _resolveComponent("greeve-one-time-products")!
  const _component_List = _resolveComponent("List")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("section", _hoisted_4, [
          (_ctx.tabs)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
                        return (_openBlock(), _createElementBlock("a", {
                          key: tab.name,
                          onClick: ($event: any) => (_ctx.changeSelectedTab(tab)),
                          class: _normalizeClass([tab.current ? 'tab tab-active' : 'tab']),
                          role: "tab"
                        }, _toDisplayString(tab.name), 11, _hoisted_9))
                      }), 128))
                    ]),
                    (_ctx.selectedQuota)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.selectedQuota.getQuotaName()), 1),
                          _createElementVNode("dl", _hoisted_12, [
                            _createElementVNode("div", _hoisted_13, [
                              _createElementVNode("dt", _hoisted_14, _toDisplayString(_ctx.$t('availableCredits')), 1),
                              _createElementVNode("dd", _hoisted_15, _toDisplayString(_ctx.selectedQuota.available_quota), 1)
                            ])
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.$t('shop.quota.empty_credits')), 1))
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_greeve_one_time_products, { "hide-bottom-padding": true })
                  ])
                ]),
                _createElementVNode("div", null, [
                  (_ctx.selectedQuota?.quota_transactions)
                    ? (_openBlock(), _createBlock(_component_List, {
                        key: 0,
                        onPaginatePage: _ctx.fetchMoreTransactions,
                        "list-data": _ctx.selectedQuota.quota_transactions?.getQuotaTransaction_ListItems(),
                        "pagination-data": _ctx.selectedQuota.quota_transactions?.paginationMetaData
                      }, null, 8, ["onPaginatePage", "list-data", "pagination-data"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createElementVNode("h1", _hoisted_19, _toDisplayString(_ctx.$t('shop.quota.empty_credits')), 1),
                        _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t('addPlan')), 1),
                        _createVNode(_component_router_link, { to: "/pricing" }, {
                          default: _withCtx(() => [
                            _createElementVNode("button", _hoisted_21, _toDisplayString(_ctx.$t('viewPlans')), 1)
                          ]),
                          _: 1
                        })
                      ]))
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}