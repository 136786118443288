
import {defineComponent, onMounted} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import useSystem from "@/composable/core/useSystem";
import GreeveShopCheckout from "@/components/shop/checkout/GreeveShopCheckout.vue";
import {useHeaderHeight} from "@/composable/global/useHeaderHeight";

export default defineComponent({
	name: 'ShopPage',
	components: {
		GreeveShopCheckout,
		FooterBar, IonContent, IonPage
	},
	setup() {
		const { headerHeight } = useHeaderHeight();
		const {scrollToTop} = useSystem();

		onMounted(() => {
			scrollToTop();
		})

		return { headerHeight
		}
	}
});

