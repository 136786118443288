import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full relative z-10 sticky -mt-2 md:mt-0 my-4 mb-2 mb-2 sm:mb-4 md:mb-4 p-0 rounded-xl nav backdrop-filter backdrop-blur-3xl"
}
const _hoisted_2 = { class: "align-middle nav rounded-xl rounded-2xl p-2 sm:p-4 pl-3 sm:pl-5 text-md text-gray-900 dark:text-gray-400" }
const _hoisted_3 = {
  key: 0,
  class: "float-right ml-2 mt-0.5"
}
const _hoisted_4 = { class: "flex select-none" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "mr-2" }
const _hoisted_8 = { class: "text-lg font-extrabold leading-6" }
const _hoisted_9 = { class: "mt-1 text-sm md:text-base line-clamp-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!

  return (_ctx.isOpen)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.allowToClose)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_XMarkIcon, {
                  class: "w-5 cursor-pointer",
                  onClick: _ctx.closeModal
                }, null, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                loading: "lazy",
                src: _ctx.img,
                class: "ml-0 mr-2 h-20 w-20 lg:h-16 lg:w-16 xl:h-15 xl:w-15",
                alt: _ctx.title
              }, null, 8, _hoisted_6),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.title), 1),
                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.info), 1)
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}