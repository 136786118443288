
import {defineComponent, onMounted, onUnmounted, ref} from 'vue';
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/vue/20/solid';
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue';
import {EllipsisVerticalIcon} from '@heroicons/vue/24/outline';
import {CheckCircleIcon} from '@heroicons/vue/20/solid';
import DefaultPagination from '@/components/core/DefaultPagination.vue';

export interface ListItemInterface {
	id: string;
	title: string;
	detail: string;
	additionalInfo?: string;
	info?: string;
	labels?: Array<{ key: string; value: string; color?: string }>;
	link: string;
	items?: any;
	status?: string;
	type?: string;
	date?: string;
	//eslint-disable-next-line
	icon?: any | undefined,
	iconColor?: string | undefined,
	data?: any | undefined;
}

export default defineComponent({
	name: 'List',
	components: {
		DefaultPagination,
		ChevronLeftIcon,
		ChevronRightIcon,
		Menu, MenuButton, MenuItem, MenuItems,
		EllipsisVerticalIcon,
		CheckCircleIcon,
	},
	props: {
		headers: {
			type: Array as () => Array<string>,
			default: () => [],
		},
		listData: {
			type: Array as () => Array<ListItemInterface>,
			default: () => [],
		},
		paginationData: {},
	},
	emits: ['paginatePage'],
	setup(props, {emit}) {
		const actualPage = ref(1);

		const handlePageChange = (page: number) => {
			emit('paginatePage', page);
		};

		function paginatePage(page: number) {
			emit('paginatePage', page);
		}

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		function clickItem(item: ListItemInterface) {
			//TODO
		}

		function getStatusCss(status: string) {
			let result;
			switch (status) {
				case 'alert':
					result = 'text-red-700 bg-red-50 ring-red-600/10';
					break;
				case 'success':
					result = 'text-green-700 bg-green-50 ring-green-600/20';
					break;
				case 'default':
					result = 'text-gray-600 bg-gray-50 ring-gray-500/10';
					break;
			}

			return result;
		}

		function getLabelClassByColor(color: string) {
			const ringColor = color === 'green' ? '20' : '10';
			return 'bg' + color + '-50 text-' + color + '-700 ring-' + color + '-600/' + ringColor;
		}

		onUnmounted(() => {
			//
		});

		onMounted(() => {
			//
		});

		return {paginatePage, getStatusCss, getLabelClassByColor, actualPage, handlePageChange};
	},
});
