
import {defineComponent, ref, onMounted, onBeforeMount} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import useSystem from "@/composable/core/useSystem";
import ThankYouProcess from "@/components/shop/checkout/process/ThankYouProcess.vue";
import LoadingLineAnimation from "@/components/animations/LoadingLineAnimation.vue";
import DefaultButton from "@/components/core/DefaultButton.vue";
import router from "@/router";
import LoadingAnimation from "@/components/animations/LoadingAnimation.vue";
import {useRoute} from "vue-router";
import useShop from '@/composable/greeve/useShop';
import useOrder from '@/composable/greeve/useOrder';
import useAuth from "@/composable/auth/useAuth";
import {GreeveApiOrder} from "@/greeve/api/order";
import useAlert from "@/composable/core/useAlert";
import {useHeaderHeight} from "@/composable/global/useHeaderHeight";

export default defineComponent({
	name: 'CheckoutSuccessPage',
	components: {
		// HeaderLogo,
		ThankYouProcess,
		LoadingAnimation,
		DefaultButton,
		LoadingLineAnimation,
		FooterBar, IonContent, IonPage
	},
	setup() {
		const { headerHeight } = useHeaderHeight();
		const {scrollToTop} = useSystem();
		const {hasValidCart} = useShop();
		const {lastOrder, setLastOrder} = useOrder();
		const {isAuthenticated} = useAuth();
		const {clearErrorAlert, addError} = useAlert();
		const isLoading = ref(true);
		const isOrderSuccessfully = ref(false);
		const currentRoute = ref();

		function redirectToCheckout() {
			router.push('/shop');
		}

		function initShopParams() {
			currentRoute.value = useRoute();
			const currentRouteParams = currentRoute.value.query || {};
			const currentRoutePath = currentRoute.value.path || null;
			const successRoute: boolean = currentRoutePath === '/shop/success';
			const sessionId: string|any = currentRouteParams.session_id ?? null;
			const orderKey: string|any = currentRouteParams.order_key ?? null;
			if (sessionId) {
				if (successRoute && sessionId) {
					//TODO useOrder
					GreeveApiOrder.getOrderByExternalReference(sessionId, orderKey).then((response) =>
						{
							if (response) {
								setLastOrder(response);
								if (response?.isOrderSuccessfully()) {
									isOrderSuccessfully.value = true;
								}
							}
							isLoading.value = false;
						},
						error => {
							clearErrorAlert();
							isOrderSuccessfully.value = false;
							isLoading.value = false;
							console.error(error);
							const errorCreateOrderLink = {
								id: "",
								title: "Error",
								message: "There was a technical error! Please try again",
								active: true,
							};
							addError(errorCreateOrderLink);
						}
					);
				}
			} else {
				//TODO error
			}
		}

		onBeforeMount(() => {
			if (!isAuthenticated.value) {
				//TODO define not authorized page
				router.push('/error/not-authorized');
			} else {
				initShopParams();
			}
		});

		onMounted(() => {
			setTimeout(() => {
				isLoading.value = false;
			}, 3000)
			scrollToTop();
		})

		return {
			isOrderSuccessfully,
			isLoading,
			redirectToCheckout,
			hasValidCart,
			lastOrder, headerHeight
		}
	}
});

