
import {ref, onMounted, defineComponent, watch} from 'vue';
import useTranslation from '@/composable/translation/useTranslation';
import LoginComponent from "@/components/auth/LoginComponent.vue";
import RegisterComponent from "@/components/auth/RegisterComponent.vue";
import {TabInterface} from "@/components/core/SwitchComponent.vue";
import useAlert from "@/composable/core/useAlert";
import useShop from "@/composable/greeve/useShop";
import useAuth from "@/composable/auth/useAuth";
import DefaultButton from "@/components/core/DefaultButton.vue";
import useUser from "@/composable/greeve/useUser";
import {IonSegment, IonSegmentButton, IonLabel} from '@ionic/vue';
import useCustomStore from '@/composable/custom/useCustomStore';

const LOGIN_SEGMENT = 'login';
const REGISTER_SEGMENT = 'register';

export default defineComponent({
	name: "LoginRegisterProcess",
	components: {DefaultButton, RegisterComponent, LoginComponent, IonSegment, IonSegmentButton, IonLabel},
	props: {
		hideNextStepButton: {
			type: Boolean,
			default: false,
		},
		hardLogout: {
			type: Boolean,
			default: false,
		},
		segment: {
			type: String,
			default: LOGIN_SEGMENT,
		}
	},
	emits: ['onSuccess', 'onError'],
	setup(props, {emit}) {
		const {t} = useTranslation();
		const {clearErrorAlert} = useAlert();
		const {initCustomerByUser} = useShop();
		const {isAuthenticated, logoutWithoutLoosingCart, logout} = useAuth();
		const {user} = useUser();
		const defaultSegment = ref();
		const segments = ref([
			{name: t("auth.LoginComponent.loginTitle"), value: LOGIN_SEGMENT, link: '/login', current: true},
			{name: t("auth.RegisterComponent.registerTitle"), value: REGISTER_SEGMENT, link: '/register', current: false}
		]);
		const selectedTab = ref(props.segment);
		const isLoginProcess = ref(true);
		const waitForLoginEvent = ref(false);
		const {setIsGlobalLoading} = useCustomStore();


		function switchSegment(segment: TabInterface) {
			if (segment.value) {
				selectedTab.value = segment.value;
			} else {
				selectedTab.value = segment.name;
			}
		}

		function goToNextStep() {
			emit('onSuccess');
		}

		function onSuccess() {
			clearErrorAlert();
			initCustomerByUser();
			goToNextStep();
			waitForLoginEvent.value = false;
			isLoginProcess.value = false;
		}

		function onError(message = '', inlineError = false) {
			waitForLoginEvent.value = false;
			isLoginProcess.value = false;
			emit('onError', message, null, false, inlineError);
		}

		async function logoutUser(withoutLoosingCart = false)
		{
			try {
				setIsGlobalLoading(true);
				if (withoutLoosingCart) {
					logoutWithoutLoosingCart(true)?.then(() => {
						setIsGlobalLoading(false);
					});
				} else {
					logout(true, true)?.then(() => {
						setIsGlobalLoading(false);
					})
				}
			} catch {
				setIsGlobalLoading(false);
			}
		}

		function setCurrentSegment(value: string) {
			segments.value.forEach(segment => {
				segment.current = segment.value === value;
			});
		}

		watch(() => props.segment, (newSegment, oldSegment) => {
			if (newSegment !== oldSegment) {
				selectedTab.value = newSegment;
				defaultSegment.value = newSegment;
				setCurrentSegment(newSegment);
			}
		});

		watch(() => isAuthenticated.value, (newValue) => {
			isLoginProcess.value = !newValue;
		})

		onMounted(() => {
			if (isAuthenticated.value) {
				isLoginProcess.value = false;
				waitForLoginEvent.value = false;
			} else {
				waitForLoginEvent.value = true;
			}
			defaultSegment.value = props.segment;
		})

		return {
			defaultSegment, segments, selectedTab, onSuccess, onError, switchSegment, isAuthenticated, logoutUser, user, goToNextStep, isLoginProcess, waitForLoginEvent
		};
	}
})
