
import {defineComponent, ref, toRef, watch} from 'vue';
import useTranslation from '@/composable/translation/useTranslation';
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {XMarkIcon} from '@heroicons/vue/24/outline';

export default defineComponent({
	name: 'InfoQuickViewDialog',
	components: {
		XMarkIcon,
		Dialog, DialogPanel, TransitionChild, TransitionRoot,
	},
	props: {
		title: {type: String},
		subTitle: {type: String},
		description: {type: String},
		visible: {type: Boolean, default: false},
		image: {type: String, default: ''},
		allowBodyDismiss: {
			type: Boolean,
			default: true,
		},
		showDismissButton: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['close'],
	setup(props, {emit}) {
		const dialogTitle = toRef(props, 'title');
		const dialogSubTitle = toRef(props, 'subTitle');
		const dialogDescription = toRef(props, 'description');
		const visible = toRef(props, 'visible');
		const open = ref(visible.value);
		const {t} = useTranslation();

		function toggleDialog() {
			open.value = !open.value;
		}

		const cancelDialogOutsideClick = () => {
			if (props.allowBodyDismiss) {
				open.value = false;
				emit('close');
			}
		};

		const cancelDialog = () => {
			open.value = false;
			emit('close');
		};

		watch(() => props.visible, (newValue: boolean) => {
			open.value = newValue;
		});

		return {
			dialogTitle,
			dialogSubTitle,
			dialogDescription,
			open,
			toggleDialog,
			cancelDialog,
			cancelDialogOutsideClick,
			t,
		};
	},
});
