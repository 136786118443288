
import {ref, onMounted, defineComponent} from 'vue';
import useShop from "@/composable/greeve/useShop";
import LoadingAnimation from "@/components/animations/LoadingAnimation.vue";
import LoadingLineAnimation from "@/components/animations/LoadingLineAnimation.vue";
import DefaultButton from "@/components/core/DefaultButton.vue";

export default defineComponent({
	name: "CheckoutPayButton",
	components: {DefaultButton, LoadingLineAnimation, LoadingAnimation},
	props: {},
	emits: [],
	setup() {
		const {cancelCheckout, cart, removeCartItem_by_Id, changeCartItemCount, payOrder} = useShop();
		const isLoading = ref(false);
		const timeoutId = ref();

		function payOrder_via_Button() {
			isLoading.value = true;
			timeoutId.value = setTimeout(() => {
				isLoading.value = false;
			}, 13000)
			try {
				payOrder().then(() => {
					clearTimeout(timeoutId.value);
					setTimeout(() => {
						isLoading.value = false;
					}, 500);
				});
			} catch (e) {
				clearTimeout(timeoutId.value);
				isLoading.value = false;
				console.error(e);
			}
		}

		onMounted(() => {
			//
		})

		return {
			payOrder_via_Button,
			isLoading,
			cancelCheckout,
			cart,
			removeCartItem_by_Id,
			changeCartItemCount
		};
	}
})
