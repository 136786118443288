
import {defineComponent, onMounted, ref, watch} from 'vue';
import {
	RadioGroup,
	RadioGroupLabel,
	RadioGroupOption,
} from '@headlessui/vue';
import {IonContent, IonPage} from '@ionic/vue';
import FooterBar from '@/components/positions/FooterBar.vue';
import {CheckIcon, XMarkIcon} from '@heroicons/vue/20/solid';
import useShop from '@/composable/greeve/useShop';
import {GreeveProductPriceSubType, GreeveProductPriceType} from '../../greeve/shop/product/price.interface';
import {GreeveProductReference} from '@/greeve/shop/product/product.interface';
import {ProductList} from '@/greeve/shop/product/product_list.type';
import {Product} from '@/greeve/shop/product/product.type';
import {useRouter} from 'vue-router';
import useTranslation from '@/composable/core/useTranslation';
import {useHeaderHeight} from '@/composable/global/useHeaderHeight';

export default defineComponent({
	name: 'ShopPlans',
	components: {
		RadioGroup, RadioGroupLabel, RadioGroupOption,
		FooterBar, IonContent, IonPage, CheckIcon, XMarkIcon,
	},
	setup() {
		const {headerHeight} = useHeaderHeight();
		const router = useRouter();
		const {
			products,
			isLastProductRefreshOlderThan,
			initProducts,
			addProductToCart,
			addRecurringProductToCart,
		} = useShop();
		const {t} = useTranslation();
		const oneTimeProductList: ProductList | any = ref();
		const recurringProductList: ProductList | any = ref();
		const isLoading = ref(false);

		const selectedFrequency: GreeveProductPriceSubType | any = ref(GreeveProductPriceSubType.RECURRING_MONTHLY);
		const frequencies = [
			{
				value: GreeveProductPriceSubType.RECURRING_MONTHLY,
				label: t('shop.product.' + GreeveProductPriceSubType.RECURRING_MONTHLY),
			},
			{
				value: GreeveProductPriceSubType.RECURRING_YEARLY,
				label: t('shop.product.' + GreeveProductPriceSubType.RECURRING_YEARLY),
			},
		];
		const frequency = ref(frequencies[0]);

		const sections = [
			{
				name: t('shop.product.feature_name'),
				features: [
					{
						reference: 'credits',
						name: t('shop.product.features.credits'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: '30',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: '500',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: '200',
						},
					},
					{
						reference: 'flows',
						name: t('shop.product.features.flows'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: true,
						},
					},
					{
						reference: 'extendable_credits',
						name: t('shop.product.features.extendable_credits'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: true,
						},
					},
					{
						reference: 'multi_accounts',
						name: t('shop.product.features.multi_accounts'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: false,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: 'up to 7 accounts',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: '3 accounts',
						},
					},
					{
						reference: 'api_access',
						name: t('shop.product.features.api_access'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: false,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: true,
						},
					},
					{
						reference: 'api_calls',
						name: t('shop.product.features.api_calls'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: '0',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: 'unlimited',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: '20.000',
						},
					},
					{
						reference: 'multi_access_token',
						name: t('shop.product.features.multi_access_token'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: false,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: 'up to 2 Tokens',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: false,
						},
					},
					{
						reference: 'support_standard',
						name: t('shop.product.features.support_standard'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: true,
						},
					},
					{
						reference: 'support_premium',
						name: t('shop.product.features.support_premium'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: false,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: false,
						},
					},
					{
						reference: 'employee_training',
						name: t('shop.product.features.employee_training'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: false,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: 'up to 10 Employees',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: false,
						},
					},
					{
						reference: 'secure_plus',
						name: t('shop.product.features.secure_plus'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: false,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: false,
						},
					},
				],
			},
		];

		const selected: Product | any = ref();

		function addSelectedOneTimeToCart() {
			if (selected.value) {
				addProductToCart(selected.value.product_id);
			}
			router.push('/shop');
		}

		function isProductFeatured(product: Product): boolean {
			return product.product_reference === GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN;
		}

		function buyThisPlan(product: Product) {
			addRecurringProductToCart(product.product_id, product.price.sub_type);
			router.push('/shop');
		}

		function getOneTimeProducts(force = false) {
			if (!oneTimeProductList.value || force) {
				oneTimeProductList.value = products.value.getOneTimeProductList();
				selected.value = oneTimeProductList.value[1];
			}
			return oneTimeProductList.value;
		}

		watch(frequency, (frequence, oldFrequence) => {
			if (frequence.value !== oldFrequence.value) {
				getRecurringProducts(true);
			}
		});

		function sortRecurringProductList() {
			recurringProductList.value = recurringProductList.value.sort((a: Product, b: Product) => {
				if (a.product_reference === GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN) return -1;
				if (b.product_reference === GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN) return 1;
				if (a.product_reference === GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN) return -1;
				if (b.product_reference === GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN) return 1;

				return 0;
			});
		}

		function getRecurringProducts(force = false): ProductList {
			if ((!recurringProductList.value || force) && !isLoading.value) {
				isLoading.value = true;
				initProducts(true).then(() => {
					if (!recurringProductList.value || force) {
						const subType = frequency.value.value;
						recurringProductList.value = products.value.getProductListByPriceTypeReferenceList(
								GreeveProductPriceType.RECURRING_PRICE, subType, [
									GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN,
									GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN,
									GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]);
						sortRecurringProductList();
					}
					isLoading.value = false;
				}).catch((e) => {
					console.error(e);
					isLoading.value = false;
				});
			} else if (!recurringProductList.value) {
				const subType = frequency.value.value;
				recurringProductList.value = products.value.getProductListByPriceTypeReferenceList(
						GreeveProductPriceType.RECURRING_PRICE, subType, [
							GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN,
							GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN,
							GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]);
				sortRecurringProductList();
			}

			return recurringProductList.value;
		}

		async function initProductsForView() {
			getOneTimeProducts();
			getRecurringProducts();
		}

		onMounted(() => {
			try {
				isLoading.value = true;
				if (!products.value || products.value.length === 0 || isLastProductRefreshOlderThan(12)) {
					initProducts(true).then(() => {
						initProductsForView().then(() => {
							isLoading.value = false;
						});
					});
				} else {
					initProductsForView().then(() => {
						isLoading.value = false;
					});
				}
			} catch (e) {
				console.error(e);
			}
		});

		return {
			selected,
			frequency,
			frequencies,
			sections,
			products,
			getRecurringProducts,
			getOneTimeProducts,
			selectedFrequency,
			addSelectedOneTimeToCart,
			isProductFeatured,
			buyThisPlan,
			recurringProductList,
			oneTimeProductList, headerHeight,
		};
	},
});

