
import {defineComponent, onMounted, ref, watch} from 'vue';
import {ChevronRightIcon, CreditCardIcon} from '@heroicons/vue/20/solid'
import {StepInterface} from "@/greeve/shop/checkout/steps/step.interface";
import CheckoutNavCart from "@/components/shop/checkout/nav/CheckoutNavCart.vue";
import CheckoutNavCountry from "@/components/shop/checkout/nav/CheckoutNavCountry.vue";
import * as icons from 'ionicons/icons';
import useTranslation from "@/composable/translation/useTranslation";

export default defineComponent({
	name: "CheckoutNavBar",
	components: {CheckoutNavCountry, CreditCardIcon, CheckoutNavCart, ChevronRightIcon},
	props: {
		steps: {
			type: Array as () => Array<StepInterface>,
			default: () => [],
		},
		currentStep: {
			type: Number,
			default: 1
		},
	},
	emits: ['updateStep'],
	setup(props, {emit}) {
		const {t} = useTranslation();
		const activeStep = ref();

		function updateStep(step: any, upcomingEvent = false) {
			if (activeStep.value.id != step.id && ((activeStep.value.allowedToGoBack && !upcomingEvent) || (activeStep.value.allowedToGoForward && upcomingEvent))) {
				activeStep.value = step;
				emit('updateStep', step.id);
			}
		}

		function setActiveStepByActiveStep(step: number) {
			if (props.steps) {
				activeStep.value = props.steps.find((stepItem) => stepItem.id === step);
			}
		}

		watch(() => props.currentStep, (newValue) => activeStep.value = props.steps.find((stepItem) => stepItem.id === newValue));

		onMounted(() => {
			setActiveStepByActiveStep(props.currentStep);
		})

		return {
			t, activeStep, updateStep, icons
		};
	}
})
