import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Transition as _Transition, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex sm:mt-2 md:mt-0 flex-col h-full pb-2 sm:pb-0" }
const _hoisted_2 = { class: "pt-2 pb-2" }
const _hoisted_3 = { class: "flex flex-col flex-grow nav rounded-3xl overflow-hidden" }
const _hoisted_4 = {
  key: 0,
  class: "block sm:hidden"
}
const _hoisted_5 = { class: "flex items-center justify-between px-6 py-1.5 sm:px-3.5 bg-gray-100 dark:bg-gray-800 bg-opacity-55 dark:bg-opacity-60" }
const _hoisted_6 = { class: "flex items-center gap-2.5" }
const _hoisted_7 = { class: "block lg:hidden rounded-2xl backdrop-filter backdrop-blur-xl" }
const _hoisted_8 = { class: "sticky top-0 z-40 flex items-center text-gr-dark dark:text-white" }
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = { class: "flex-1 text-sm text-center leading-6 text-gr-dark dark:text-white" }
const _hoisted_11 = { href: "#" }
const _hoisted_12 = { class: "font-semibold" }
const _hoisted_13 = { class: "flex gap-2.5 items-center" }
const _hoisted_14 = { class: "relative" }
const _hoisted_15 = {
  key: 0,
  class: "flex items-center justify-center absolute w-full -mt-1 px-14 z-10"
}
const _hoisted_16 = { class: "inline-block bg-gray-500 text-white px-3 py-1 text-sm font-semibold rounded-full truncate" }
const _hoisted_17 = { class: "hidden sm:block flex text-gray-400 justify-between mx-4 mt-2 py-2 sm:py-4 px-2" }
const _hoisted_18 = { class: "flex gap-2.5 lg:hidden block" }
const _hoisted_19 = { class: "block lg:hidden rounded-2xl backdrop-filter backdrop-blur-xl" }
const _hoisted_20 = { class: "sticky top-0 z-40 flex items-center justify-between lg:hidden" }
const _hoisted_21 = { class: "flex" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { class: "flex text-gray-400 gap-2.5" }
const _hoisted_24 = {
  key: 0,
  class: "justify-between"
}
const _hoisted_25 = {
  key: 2,
  class: "overflow-auto h-full"
}
const _hoisted_26 = { class: "pt-2 bg-gray-100 bg-opacity-55 dark:bg-gray-800 dark:bg-opacity-20" }
const _hoisted_27 = { class: "z-10 sticky bottom-0" }
const _hoisted_28 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoCard = _resolveComponent("InfoCard")!
  const _component_ActionBanner = _resolveComponent("ActionBanner")!
  const _component_loading_line_animation = _resolveComponent("loading-line-animation")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_loading_line_blur_animation = _resolveComponent("loading-line-blur-animation")!
  const _component_Bars3Icon = _resolveComponent("Bars3Icon")!
  const _component_BookmarkIcon = _resolveComponent("BookmarkIcon")!
  const _component_PaywallOverlay = _resolveComponent("PaywallOverlay")!
  const _component_output_container = _resolveComponent("output-container")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_OutputTag = _resolveComponent("OutputTag")!
  const _component_TextInput = _resolveComponent("TextInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InfoCard, {
      "info-key": "chat-info",
      "allow-to-close": true,
      class: "py-3 md:py-0 mt-1 sm:mt-0",
      title: _ctx.$t('chat2'),
      info: _ctx.$t('chatDescription'),
      img: "/assets/img/3d/chat.png"
    }, null, 8, ["title", "info"]),
    (_ctx.hasLowCredits)
      ? (_openBlock(), _createBlock(_component_ActionBanner, {
          key: "lowCreditsBanner",
          title: _ctx.$t('shop.quota.low_credits.banner.title'),
          description: _ctx.$t('shop.quota.low_credits.banner.description'),
          "action-title": _ctx.$t('shop.quota.low_credits.banner.action.title'),
          "redirect-link": _ctx.lowCreditsRedirectLink
        }, null, 8, ["title", "description", "action-title", "redirect-link"]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_loading_line_animation, null, null, 512), [
      [_vShow, _ctx.isLoading]
    ]),
    _createVNode(_component_ion_refresher, {
      slot: "fixed",
      onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleRefresh($event)))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_refresher_content)
      ]),
      _: 1
    }),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _withDirectives(_createVNode(_component_loading_line_blur_animation, { class: "fixed" }, null, 512), [
        [_vShow, _ctx.isLoading]
      ])
    ], 512), [
      [_vShow, _ctx.isLoading]
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.isAuthenticated)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "flex -m-2.5 p-2.5 text-gray-400 lg:hidden",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setMobileMenuSidebar(true)))
                    }, [
                      _createElementVNode("span", _hoisted_9, [
                        _createVNode(_component_Bars3Icon, {
                          class: "h-6 w-6",
                          "aria-hidden": "true"
                        })
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("a", _hoisted_11, [
                  _createElementVNode("strong", _hoisted_12, _toDisplayString(_ctx.$t('chat.modes.'+_ctx.selectedChatType)) + " - Mode", 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("button", {
                  type: "button",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.filterOnlyPinnedItems = !_ctx.filterOnlyPinnedItems))
                }, [
                  _createVNode(_component_BookmarkIcon, {
                    class: _normalizeClass(['w-6 h-6 mx-auto text-gray-400', _ctx.filterOnlyPinnedItems ? 'bookmarked' : ''])
                  }, null, 8, ["class"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_Transition, { name: "fade" }, {
                default: _withCtx(() => [
                  (_ctx.selectedGroup && _ctx.showNameOnNativeDevice)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                        _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.selectedGroup.getName()), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("button", {
                type: "button",
                class: "flex -m-2.5 p-2.5 text-gray-400 lg:hidden",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setMobileMenuSidebar(true)))
              }, [
                _createElementVNode("span", _hoisted_21, [
                  _createVNode(_component_Bars3Icon, {
                    class: "h-6 w-6",
                    "aria-hidden": "true"
                  })
                ])
              ])
            ])
          ]),
          (_ctx.selectedGroup)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_ctx.selectedGroup.getName()), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.filterOnlyPinnedItems = !_ctx.filterOnlyPinnedItems))
          }, [
            _createVNode(_component_BookmarkIcon, {
              class: _normalizeClass([{'bookmarked': _ctx.filterOnlyPinnedItems}, "w-6 h-6 mx-auto"])
            }, null, 8, ["class"])
          ]),
          (_ctx.selectedGroup)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, _toDisplayString(_ctx.selectedGroup.getName()), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      (!_ctx.isAuthenticated)
        ? (_openBlock(), _createBlock(_component_PaywallOverlay, {
            key: 1,
            visible: true,
            "redirect-link": "/assistant"
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_25, [
            (_ctx.selectedGroup)
              ? (_openBlock(), _createBlock(_component_ion_content, {
                  key: 0,
                  id: "assistantContent",
                  "scroll-events": true,
                  onIonScroll: _ctx.handleScroll
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.filterOnlyPinnedItems ? _ctx.selectedGroup.search_items?.filterPinnedList(_ctx.filterOnlyPinnedItems) : _ctx.selectedGroup.search_items), (searchItem) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: searchItem.uuid
                      }, [
                        _createVNode(_component_output_container, {
                          "search-item": searchItem,
                          onRetryErrorItem: _ctx.regenerateItem,
                          onDisableTextInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isTextInputDisabled = true)),
                          onEnableTextInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isTextInputDisabled = false)),
                          onCancelUploadPrompt: _ctx.cancelNewUploadPrompt,
                          onUploadFinished: _ctx.uploadFinished,
                          onUpdateUploadInteractionType: _ctx.updateInteractionType
                        }, null, 8, ["search-item", "onRetryErrorItem", "onCancelUploadPrompt", "onUploadFinished", "onUpdateUploadInteractionType"])
                      ]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["onIonScroll"]))
              : _createCommentVNode("", true)
          ])),
      _createElementVNode("div", _hoisted_26, [
        _createElementVNode("div", _hoisted_27, [
          (_ctx.inputMode === 'default' && !_ctx.isOnlySendButtonVisible)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.inputMode === 'default')
                  ? (_openBlock(), _createBlock(_component_OutputTag, {
                      key: 0,
                      onUpdateSelectedType: _ctx.setSelectedInputType,
                      isDisabled: !_ctx.isAuthenticated
                    }, null, 8, ["onUpdateSelectedType", "isDisabled"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_TextInput, {
                  "auto-grow": _ctx.searchValue.length<=80,
                  "max-rows": _ctx.searchValue.length > 700 ? 18 : (_ctx.searchValue.length > 500 ? 12 : 1),
                  "search-default-value": _ctx.searchValue,
                  "onUpdate:searchDefaultValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.searchValue) = $event)),
                  isDisabled: _ctx.isLoading || !_ctx.isAuthenticated || _ctx.isTextInputDisabled,
                  mode: _ctx.inputMode,
                  onOnEnter: _ctx.searchPrompt,
                  onOnClear: _ctx.clearTextInput,
                  onCreateUploadPrompt: _ctx.addNewUploadPrompt
                }, null, 8, ["auto-grow", "max-rows", "search-default-value", "isDisabled", "mode", "onOnEnter", "onOnClear", "onCreateUploadPrompt"])
              ], 64))
            : (!_ctx.isOnlySendButtonVisible)
              ? (_openBlock(), _createBlock(_component_TextInput, {
                  key: 1,
                  "auto-grow": _ctx.searchValue.length<=80,
                  "max-rows": _ctx.searchValue.length > 700 ? 18 : (_ctx.searchValue.length > 500 ? 12 : 1),
                  "search-default-value": _ctx.searchValue,
                  "onUpdate:searchDefaultValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.searchValue) = $event)),
                  isDisabled: _ctx.isLoading || !_ctx.isAuthenticated || _ctx.isTextInputDisabled,
                  mode: _ctx.inputMode,
                  onOnEnter: _ctx.searchPrompt,
                  onOnClear: _ctx.clearTextInput,
                  onCreateUploadPrompt: _ctx.addNewUploadPrompt
                }, null, 8, ["auto-grow", "max-rows", "search-default-value", "isDisabled", "mode", "onOnEnter", "onOnClear", "onCreateUploadPrompt"]))
              : _createCommentVNode("", true),
          (_ctx.inputMode === 'upload' && _ctx.isOnlySendButtonVisible)
            ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.searchPrompt('', true))),
                disabled: !_ctx.hasUploadInteractionValidFile || _ctx.isLoading,
                class: _normalizeClass([(!_ctx.hasUploadInteractionValidFile || _ctx.isLoading) ? 'disabled:opacity-50' : '', 'inline-flex h-12 items-center w-full justify-center rounded-xl bg-gr-primary hover:bg-gr-primary2 py-1.5 px-10 text-sm font-semibold text-white shadow-sm focus:outline-none'])
              }, _toDisplayString(_ctx.$t('chat.interaction.'+ _ctx.getUploadInteractionSubTypeForTranslation +'.submit')), 11, _hoisted_28))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}