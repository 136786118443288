
import {ref, onMounted, watch, defineComponent} from 'vue';
import {ShoppingBagIcon} from '@heroicons/vue/24/outline'
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue'
import useShop from "@/composable/greeve/useShop";
import * as icons from "ionicons/icons";
import {IonIcon, IonRippleEffect} from '@ionic/vue';
import {CartInterface} from "@/greeve/shop/cart/cart.interface";
import useAuth from "@/composable/auth/useAuth";

export default defineComponent({
	name: "CheckoutNavCart",
	components: {ShoppingBagIcon, Popover, PopoverButton, PopoverPanel, IonIcon, IonRippleEffect},
	props: {
		checkoutLink: {
			type: String,
			default: ''
		},
		viewShoppingBagLink: {
			type: String,
			default: ''
		},
		popoverPosition: {
			type: String,
			default: 'absolute inset-x-0 left-auto top-14 lg:top-10 right-12 lg:right-1 -mr-1.5'
		},
		popoverSize: {
			type: String,
			default: 'w-80'
		},
	},
	emits: [],
	setup() {
		const {cart, removeCartItem_by_Id, addProductToCart} = useShop();
		const {logout} = useAuth();
		const showCartItemCountChangedAnimation = ref(false);

		watch(() => cart.value, (newValue: CartInterface, oldValue: CartInterface|undefined) => {
			if (!oldValue || newValue.items?.length !== oldValue.items?.length) {
				showCartItemCountChangedAnimation.value = true;
				setTimeout( () => {
					showCartItemCountChangedAnimation.value = false;
				}, 1500)
			}
		})

		onMounted(() => {
			//
		})

		return {
			cart,
			removeCartItem_by_Id,addProductToCart,
			icons,
			showCartItemCountChangedAnimation,
			logout
		};
	}
})
