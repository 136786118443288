import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseAlertComponent = _resolveComponent("BaseAlertComponent")!

  return (_openBlock(), _createBlock(_component_BaseAlertComponent, {
    "show-close-button": _ctx.showCloseButton,
    "alert-padding": _ctx.alertPadding,
    message: _ctx.errorMessage,
    "show-title": _ctx.showTitle,
    title: _ctx.errorTitle,
    "alert-type": "error",
    onCloseAlert: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeAlert()))
  }, null, 8, ["show-close-button", "alert-padding", "message", "show-title", "title"]))
}