
import {
	IonApp,
	IonFooter, IonHeader, IonRouterOutlet,IonContent,
} from '@ionic/vue';
import {defineComponent, onBeforeMount} from 'vue';
import CookiesDialog from "@/components/modal/CookiesDialog.vue";
import { useI18n } from "vue-i18n";
import useCustomStore from "@/composable/custom/useCustomStore";
import { useRoute } from "vue-router";
import useSystem from "@/composable/core/useSystem";
import AOS from 'aos';
import 'aos/dist/aos.css';
import NavbarPro from "@/components/navigations/NavbarPro.vue";
import LoadingAnimation from '@/components/animations/LoadingAnimation.vue';
import {useHeaderHeight} from "@/composable/global/useHeaderHeight";
import HeaderInfo from "@/components/modal/HeaderInfo.vue";


AOS.init();
AOS.refreshHard();

export default defineComponent({
	name: 'App',
	components: {
		HeaderInfo,
		LoadingAnimation,
		NavbarPro,
		CookiesDialog,
		IonApp,
		IonHeader,
		IonFooter,
		IonContent,
		IonRouterOutlet,
	},
	setup() {
		const { headerHeight } = useHeaderHeight();
		const { getLanguageLocale, setLanguageLocale, getDefaultBrowserLanguageCode, isPageInitialized, setIsPageInitialized } = useCustomStore();
		const i18n = useI18n();
		const route = useRoute();
		const { initVersion } = useSystem();
		const { isGlobalLoading, setIsGlobalLoading } = useCustomStore();

		onBeforeMount(() => {
			try {
				initVersion();
				if (getLanguageLocale.value.length === 0 || !isPageInitialized.value) {
					setLanguageLocale(getDefaultBrowserLanguageCode());
					setIsPageInitialized(true);
				}
				i18n.locale.value = getLanguageLocale.value;
				setIsGlobalLoading(false);
			} catch (e) {
				console.log(e);
			}
		});

		return {
			headerHeight,
			route,
			isGlobalLoading,
		}
	}
});
