
import {defineComponent} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import useTranslation from "@/composable/translation/useTranslation";
import HeaderLogo from "@/components/branding/HeaderLogo.vue";
import useFaq from "@/composable/faq/useFaq";
import FaqSection from "@/components/sections/FaqSection.vue";

export default defineComponent({
	name: 'FaqPage',
	components: {
		FaqSection,
		HeaderLogo,
		FooterBar, IonContent, IonPage,
	},
	setup() {
		const {t} = useTranslation();
		const {faqs} = useFaq();
		return {t, faqs}
	}
});

