
import {defineComponent, onMounted, onUnmounted, ref} from 'vue';
import {XMarkIcon} from '@heroicons/vue/24/outline';
import {
	TransitionRoot,
	TransitionChild,
} from '@headlessui/vue';
import useCustomStore from '@/composable/custom/useCustomStore';
import {nanoid} from 'nanoid';
import useShop from '@/composable/greeve/useShop';
import router from '@/router';

export default defineComponent({
	name: 'HeaderInfo',
	components: {
		XMarkIcon,
		TransitionRoot,
		TransitionChild,
	},
	props: {
		id: {
			type: String,
			default: nanoid(),
		},
		promoCode: {
			type: String,
			default: 'GREEVEBETA20',
		},
		visible: {
			type: Boolean,
			default: true,
		},
		alwaysVisible: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const {getInfoBanner_by_Id, addInfoBanner, updateInfoBannerState} = useCustomStore();
		const {addDiscountToCart} = useShop();
		const isOpen = ref(false);
		const opacity = ref(1);

		function handleAction() {
			addDiscountToCart(props.promoCode);
			router.push('shop');
			isOpen.value = false;
			//TODO HIDE BANNER
		}

		function closeModal() {
			updateInfoBannerState(props.id, false);
			isOpen.value = false;
		}

		function openModal() {
			updateInfoBannerState(props.id, true);
			isOpen.value = true;
		}

		onMounted(() => {
			addInfoBanner(props.id, props.visible);
			const infoBanner = getInfoBanner_by_Id(props.id);

			if (props.alwaysVisible) {
				isOpen.value = true;
			} else {
				if (infoBanner) {
					isOpen.value = infoBanner.active;
				} else {
					isOpen.value = true;
				}
			}
		});


		onUnmounted(() => {
			// window.removeEventListener('scroll', handleScroll);
		});

		//TODO
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const header = document.getElementById('header');

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		function fadeOutOnScroll(element: any) {
			if (!element) {
				return;
			}

			const distanceToTop = window.pageYOffset + element.getBoundingClientRect().top;
			const elementHeight = element.offsetHeight;
			const scrollTop = document.documentElement.scrollTop;

			let opacity = 1;

			if (scrollTop > distanceToTop) {
				opacity = 1 - (scrollTop - distanceToTop) / elementHeight;
			}

			if (opacity >= 0) {
				element.style.opacity = opacity;
			}
		}


		return {
			openModal, closeModal, isOpen, opacity, handleAction,
		};
	},
});

