
import {defineComponent} from 'vue';
import {IonContent, IonPage} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import HeaderLogo from "@/components/branding/HeaderLogo.vue";
import useTranslation from "@/composable/translation/useTranslation";

export default defineComponent({
	name: 'TermsPage',
	components: {
		HeaderLogo,
		FooterBar, IonContent, IonPage
	},
	setup() {
		const {t} = useTranslation();
		return {t,}
	}
});

