
import {defineComponent, onMounted, ref, toRef, watch} from 'vue';
import useTranslation from '@/composable/translation/useTranslation';
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {XMarkIcon, ExclamationTriangleIcon} from '@heroicons/vue/24/outline';

export interface ScriptInterface {
	src: string;
	type?: string;
	arguments?: any;
	content?: string;
}

export default defineComponent({
	name: 'ConfirmDialog',
	components: {
		XMarkIcon, ExclamationTriangleIcon,
		Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
	},
	props: {
		title: {type: String},
		description: {type: String},
		confirmButtonName: {type: String, default: 'Confirm'},
		icon: {type: String, default: ExclamationTriangleIcon},
		cancelButtonName: {type: String, default: 'Cancel'},
		visible: {type: Boolean, default: false},
		type: {type: String, default: 'alert'},
		allowBodyDismiss: {
			type: Boolean,
			default: true,
		},
		showDismissButton: {
			type: Boolean,
			default: true,
		},
		showLoadingAnimationOnConfirm: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['confirm', 'cancelConfirm'],
	setup(props, {emit}) {
		const dialogTitle = toRef(props, 'title');
		const dialogDescription = toRef(props, 'description');
		const visible = toRef(props, 'visible');
		const open = ref(visible.value);
		const {t} = useTranslation();

		function toggleDialog() {
			open.value = !open.value;
		}

		const cancelDialogOutsideClick = () => {
			if (props.allowBodyDismiss) {
				open.value = false;
				emit('cancelConfirm');
			}
		};

		const cancelDialog = () => {
			open.value = false;
			emit('cancelConfirm');
		};

		function confirm() {
			emit('confirm');
		}

		watch(() => props.visible, (newValue: boolean) => {
			open.value = newValue;
		});

		onMounted(() => {
			//
		});

		return {
			dialogTitle,
			dialogDescription,
			open,
			toggleDialog,
			confirm,
			cancelDialog,
			cancelDialogOutsideClick,
			t,
		};
	},
});
