
import {computed, defineComponent, ref} from 'vue';
import useAssistant from '@/composable/greeve/useAssistant';
import LoadingLineBlurAnimation from '@/components/animations/LoadingLineBlurAnimation.vue';
import Heic2any from 'heic2any';
import useToastMessage from '@/composable/core/useToastMessage';
import useTranslation from '@/composable/translation/useTranslation';

export default defineComponent({
	name: 'UploadInteraction',
	components: {LoadingLineBlurAnimation},
	props: {
		allowedTypes: {
			type: Array,
			default: () => ['image/jpeg', 'image/png', 'image/gif', 'application/pdf', 'audio/mp3']
		},
		showUploadButton: {
			type: Boolean,
			default: false
		}
	},
	emits: ['onUpload', 'onUploadEnd', 'onUploadError'],
	setup(props, {emit}) {
		const {t} = useTranslation();
		const {uploadFile} = useAssistant();
		const {openToast} = useToastMessage();
		const previewUrl = ref<any>(null);
		const selectedFile = ref<any>(null);
		const isLoading = ref(false);
		const isDragging = ref(false);
		const loadingTimeoutId = ref();

		const uploadInputAcceptList = computed(() => {
				const imageTypes = props.allowedTypes.filter((type: any) => type.startsWith('image/')).join(',');
				const otherTypes = props.allowedTypes.filter((type: any) => !type.startsWith('image/')).join(',');
				return `${imageTypes},${otherTypes}`;
		});

		const handleDragOver = (event: any) => {
			event.preventDefault();
			isDragging.value = true;
		};

		const handleDragEnter = () => {
			isDragging.value = true;
		};

		const handleDragLeave = () => {
			isDragging.value = false;
		};

		const handleDrop = (event: any) => {
			event.preventDefault();
			isDragging.value = false;

			const file = event.dataTransfer.files[0];
			if (!file) return;

			// Handle the dropped file
			handleDroppedFile(file);
			uploadFileToServer(); // Automatically trigger upload after dropping the file
		};

		const handleDroppedFile = (file: Blob) => {
			// Handle the dropped file here
			selectedFile.value = file;
			renderPreview(file);
		};

		const renderPreview = (file: Blob) => {
			const reader = new FileReader();
			reader.onload = (e: any) => {
				previewUrl.value = e.target.result;
			};
			reader.readAsDataURL(file);
		};

		const handleFileChange = async (event: any) => {
			const file = event.target.files[0];
			if (!file) return;

			if (!props.allowedTypes.includes(file.type)) {
				alert('Unsupported file type!');
				return;
			}

			isLoading.value = true;
			loadingTimeoutId.value = setTimeout(() => {
				isLoading.value = false
			}, 500000);

			if (file.type === 'image/heic') {
				try {
					// Convert HEIC to JPEG
					const jpegBlob: any = await Heic2any({
						blob: file,
						toType: 'image/jpeg'
					});
					// Create a new File object from the converted JPEG Blob
					const jpegFile = new File([jpegBlob], `${file.name}.jpg`, { type: 'image/jpeg' });
					// Proceed with the converted JPEG file
					handleConvertedFile(jpegFile);
				} catch (error) {
					isLoading.value = false;
					console.error('Error converting HEIC to JPEG:', error);
					await openToast(t("assistant.output.upload.file.image.error.description"), 'danger', 'top', true, 8000, undefined, true);
				}
			} else {
				// Proceed with the selected file as is
				handleConvertedFile(file);
			}
		};

		const handleConvertedFile = (file: any) => {
			selectedFile.value = file;
			const reader = new FileReader();
			reader.onload = (e: any) => {
				previewUrl.value = e.target.result;
			};
			reader.readAsDataURL(file);

			uploadFileToServer(); // Automatically trigger upload after selecting the file
		};

		const removeFile = (event: any) => {
			event.preventDefault();
			event.stopPropagation();
			previewUrl.value = null;
			selectedFile.value = null;
		};

		const uploadFileToServer = async () => {
			if (!selectedFile.value) return;

			try {
				emit('onUpload', selectedFile.value);
				isLoading.value = true;
				const fileInfo = await uploadFile(selectedFile.value);
				emit('onUploadEnd', fileInfo)
				if (loadingTimeoutId.value) {
					clearTimeout(loadingTimeoutId.value);
				}
				isLoading.value = false;
			} catch (e) {
				console.error(e);
				await openToast(t("assistant.output.upload.file.error.description"), 'danger', 'top', true, 8000, undefined, true);
				if (loadingTimeoutId.value) {
					clearTimeout(loadingTimeoutId.value);
				}
				previewUrl.value = null;
				selectedFile.value = null;
				isLoading.value = false;
				emit('onUploadError', e);
			}
		};

		const isImage = (type: string) => type.startsWith('image');
		const isDocument = (type: string) => type === 'application/pdf';
		const isAudio = (type: string) => type.startsWith('audio');

		return {
			previewUrl,
			handleFileChange,
			removeFile,
			uploadFileToServer,
			isImage,
			isDocument,
			isAudio,
			selectedFile,
			handleDrop,
			isDragging,
			handleDragOver,
			handleDragLeave,
			handleDragEnter,
			isLoading,
			uploadInputAcceptList,
		};
	}
});
