
import {defineComponent, reactive} from 'vue';
import InputNickMail from '@/components/inputs/InputNickMail.vue';
import InputPassword from '@/components/inputs/InputPassword.vue';
import DefaultButton from '@/components/core/DefaultButton.vue';
import LinkButton from '@/components/core/LinkButton.vue';
import useTranslation from '@/composable/translation/useTranslation';
import useAlert from '@/composable/core/useAlert';
import useUser from '@/composable/greeve/useUser';
import useAuth from '@/composable/auth/useAuth';
import {GreeveApiAuth} from '@/greeve/api/auth';
import SwitchComponent from '@/components/core/SwitchComponent.vue';
import DangerAlert from '@/components/alert/Base/DangerAlert.vue';
import useAssistant from '@/composable/greeve/useAssistant';
import useCustomStore from '@/composable/custom/useCustomStore';
import router from '@/router';

export default defineComponent({
	name: 'LoginComponent',
	components: {
		DangerAlert,
		SwitchComponent,
		LinkButton,
		DefaultButton,
		InputNickMail,
		InputPassword,
	},
	props: {
		redirectAfterSubmit: {
			type: Boolean,
			default: false,
		},
		showSwitch: {
			type: Boolean,
			default: true,
		},
		showAlerts: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['onLoginSuccess', 'onLoginError'],
	setup(props, {emit}) {
		const {clearErrorAlert} = useAlert();
		const {initUserByUserType, initQuotas, initSubscriptions, initLastUserAddress} = useUser();
		const {initSearchGroups} = useAssistant();
		const { setIsGlobalLoading } = useCustomStore();

		const {
			errorMessage,
			errorTitle,
			clearErrorMessage,
			getDeviceHash,
		} = useAuth();

		const user = reactive({
			email: '',
			password: '',
			emailError: '',
			passwordError: '',
		});

		const {t} = useTranslation();

		function sendLoginSuccessEvent() {
			setIsGlobalLoading(false);
			emit('onLoginSuccess');
		}

		function sendLoginErrorEvent() {
			setIsGlobalLoading(false);
			emit('onLoginError');
		}

		function hideErrorMessage()
		{
			clearErrorAlert();
			errorMessage.value = '';
			errorTitle.value = '';
		}

		async function onSubmit() {
			const loginData = {
				email: user.email,
				password: user.password,
				device_name: getDeviceHash(),
			};
			try {
				setIsGlobalLoading(true);
				clearErrorAlert();
				const token = await GreeveApiAuth.signIn(loginData);
				if (token) {
					const userResponse = await GreeveApiAuth.getUser();
					if (userResponse && userResponse.id) {
						initUserByUserType(userResponse);
						clearErrorAlert();
						try {
							initSearchGroups(true, false);
							initQuotas(true);
							initSubscriptions(true);
							initLastUserAddress();
						} catch (e) {
							console.error(e);
						}
						if (props.redirectAfterSubmit) {
							router.push('/');
						}
						sendLoginSuccessEvent();
					}
				}
			} catch (e) {
				console.error(e);
				errorTitle.value = t('auth.auth_error_failed_title');
				errorMessage.value = t('auth.failed');
				sendLoginErrorEvent();
				setIsGlobalLoading(false);
			}

			// 	return GreeveApiAuth.signIn(loginData).then(async (token) => {
			// 		if (!token) {
			// 			return;
			// 		}
			// 		GreeveApiAuth.getUser().then(async (userResponse) => {
			// 			if (userResponse && userResponse.id) {
			// 				initUserByUserType(userResponse);
			// 				clearErrorAlert();
			// 				try {
			// 					initQuotas(true);
			// 					initSubscriptions(true);
			// 					initLastUserAddress();
			// 					initSearchGroups(true, false).then(() => {
			// 						if (props.redirectAfterSubmit) {
			// 							router.push('/');
			// 						}
			// 						sendLoginSuccessEvent();
			// 					});
			// 				} catch (e) {
			// 					console.error(e);
			// 				}
			// 			}
			// 		});
			// 	})
			// } catch (e) {
			// 	console.error(e);
			// 	errorTitle.value = t('auth.auth_error_failed_title');
			// 	errorMessage.value = t('auth.failed');
			// 	sendLoginErrorEvent();
			// 	setIsGlobalLoading(false);
			// }
		}

		return {
			tabs: [
				{name: t('auth.LoginComponent.loginTitle'), link: '/login', current: true},
				{name: t('auth.RegisterComponent.registerTitle'), link: '/register', current: false},
			],
			user,
			onSubmit,
			errorMessage,
			errorTitle,
			clearErrorMessage,
			hideErrorMessage,
		};

	},
});
