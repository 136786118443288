
import useCustomStore from '@/composable/custom/useCustomStore';
import {defineComponent} from 'vue';
import {useI18n} from "vue-i18n";

export default defineComponent({
	name: 'LanguageSelector',
	components: {},
	props: {
		showFullName: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['onChangeLanguage'],
	setup(props, {emit}) {
		const {setLanguageLocale} = useCustomStore();
		const arrLanguageMap = {"de": "DE", "en": "EN"};
		const i18n = useI18n();

		function changeLanguage() {
			setLanguageLocale(i18n.locale.value);
			emit('onChangeLanguage', i18n.locale.value);
		}

		return {arrLanguageMap, changeLanguage};
	}
})
