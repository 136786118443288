import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4be3ddf5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(['items-center shadow-2xl justify-center h-12 lg:h-10 ', _ctx.centerButton, _ctx.flexButton + 'cursor-pointer rounded-xl' +' rounded-xl' + 'focus:outline-none focus:shadow-outline ' + ' ' + _ctx.buttonXMargin + ' ' + _ctx.buttonXPadding + ' ' + _ctx.buttonYPadding + ' ' + _ctx.buttonHover + ' ' + _ctx.buttonColor + ' ' + _ctx.textColor + ' ' + _ctx.buttonWidth + ' ' + _ctx.customCss, _ctx.buttonDisabled ? 'disabled:opacity-50' : '']),
    type: _ctx.buttonType,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickButton && _ctx.clickButton(...args))),
    disabled: _ctx.buttonDisabled
  }, _toDisplayString(_ctx.buttonText), 11, _hoisted_1))
}