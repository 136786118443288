
import {defineComponent, onMounted, ref, Transition, watch} from 'vue';
import ShareAction from '@/components/actions/ShareAction.vue';
import RatingAction from '@/components/actions/RatingAction.vue';
import useToastMessage from '@/composable/core/useToastMessage';
import useTranslation from '@/composable/translation/useTranslation';
import CategoryInput from '@/components/inputs/CategoryInput.vue';
import {createGesture, IonIcon} from '@ionic/vue';
import * as icons from 'ionicons/icons';
import InteractionAction from '@/components/actions/InteractionAction.vue';
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue';
import {GreeveApiAssistant} from '@/greeve/api/assistant';
import useAssistant from '@/composable/greeve/useAssistant';
import {ArrowPathIcon, UserCircleIcon} from '@heroicons/vue/20/solid';
import {ChatType} from '@/views/assistant/Chat/ChatPage.vue';
import {AbstractSearchItem} from '@/greeve/search/item/abstract_search_item.type';

export default defineComponent({
	name: 'OutputCard',
	components: {
		UserCircleIcon,
		MenuItems,
		MenuItem,
		MenuButton,
		Menu, InteractionAction, CategoryInput, IonIcon, RatingAction, ShareAction, Transition, ArrowPathIcon},
	props: {
		reverseSide: {
			type: Boolean,
			default: false
		},
		cardDate: {
			type: String,
			default: '00.00.00'
		},
		searchItem: {
			type: Object as () => AbstractSearchItem,
			default: null,
		},
		cardTitle: {
			type: String,
			default: 'Search Input'
		},
		cardContent: {
			type: String,
			default: ''
		},
		cardReference: {
			type: String,
			default: ''
		},
		shareLink: {
			type: String,
			default: '',
		},
		shareMode: {
			type: String as () => ChatType,
			default: () => ChatType.CHAT,
		},
		itemUuid: {
			type: String,
			default: ''
		},
		isPinned: {
			type: Boolean,
			default: false
		},
		cardState: {
			type: String,
			default: ''
		},
		cardInfo: {
			type: Boolean,
			default: true
		},
		cardInteraction: {
			type: Boolean,
			default: true
		},
	},
	emits: ['searchGreeveByType', 'pinItem', 'retryErrorItem'],
	setup(props, {emit}) {
		const {t} = useTranslation();
		const searchResultRated = ref(false);
		const resultRated = ref(false);
		const categoryVisibleState = ref(false);
		const {openToast} = useToastMessage();
		const cardContentNew = ref<string>(props.cardContent);
		const {pinItem} = useAssistant();

		function toggleCategories() {
			categoryVisibleState.value = !categoryVisibleState.value;
		}

		function searchGreeveByType(type: string) {
			emit('searchGreeveByType', type);
		}

		function retryErrorItem() {
			emit('retryErrorItem', props.itemUuid, props.searchItem.subType);
		}

		async function pinSearchItem(pinned: boolean) {
			try {
				pinItem(props.itemUuid, pinned);
			} catch (error) {
				let errorMessage = t("toast,errorRating");
				await openToast(errorMessage, 'danger', 'top', true, 12000, undefined, true);
				console.error(error);
			}
		}

		async function rateSearchResult(rate: boolean) {
			try {
				let rateType = 'good';
				if (!rate) {
					rateType = 'bad';
				}
				searchResultRated.value = rateType === 'good';
				resultRated.value = true;
				GreeveApiAssistant.voteSearchResult(props.itemUuid, rateType).then((result) => {
					if (result) {
						searchResultRated.value = rateType === 'good';
						resultRated.value = true;
					} else {
						resultRated.value = false;
					}
				});
			} catch (error: Error | any) {
				let errorMessage = t("toast,errorRating");
				await openToast(errorMessage, 'danger', 'top', true, 12000, undefined, true);
				console.error(error);
			}
		}

		const contentDiv = ref<HTMLElement|any>(null);
		let pressTimer: any | null = null;
		let touchMoved = false;

		const onStartPress = () => {
			pressTimer = setTimeout(() => {
				if (!touchMoved) {
					const range = document.createRange();
					range.selectNodeContents(contentDiv.value);
					const selection: any = window.getSelection();
					selection.removeAllRanges();
					selection.addRange(range);
				}
			}, 1300);
		};

		const onEndPress = () => {
			if (pressTimer !== null) {
				clearTimeout(pressTimer);
				pressTimer = null;
			}
			touchMoved = false;
		};

		const getInProgressMessage = () => {
			if (!props.searchItem.isInProgress()) {
				return '';
			}
			return props.searchItem.getInProgressMessage();
		}

		watch(() => props.cardContent, (newContent) => {
			if (newContent && newContent.length > 0) {
				cardContentNew.value = newContent;
			}
		});

		onMounted(() => {
			const gesture = createGesture({
				el: contentDiv.value,
				threshold: 0,
				gestureName: 'press',
				onStart: () => onStartPress(),
				onEnd: () => onEndPress(),
				disableScroll: true,
				onMove: () => {
					touchMoved = true;
					if (pressTimer !== null) {
						clearTimeout(pressTimer);
						pressTimer = null;
					}
				},
			});

			gesture.enable();
		});

		return {
			icons,
			t,
			searchResultRated,
			rateSearchResult,
			toggleCategories,
			categoryVisibleState,
			searchGreeveByType,
			cardContentNew,
			pinSearchItem,
			resultRated,
			contentDiv,
			retryErrorItem,
			getInProgressMessage
		}
	}
});

