
import {defineComponent, onMounted, ref} from 'vue';
import useUser from '@/composable/greeve/useUser';
import List from '@/components/core/List.vue';
import GreeveOneTimeProducts from '@/components/shop/product/GreeveOneTimeProducts.vue';
import {AbstractQuota} from '@/greeve/user/quota/abstract_quota.type';
import {useRoute} from 'vue-router';
import useCustomStore from '@/composable/custom/useCustomStore';

export default defineComponent({
	name: 'CreditManagement',
	components: {
		GreeveOneTimeProducts,
		List,
	},
	props: {},
	emits: [],
	setup() {
		const { setIsGlobalLoading } = useCustomStore();
		const {getQuotas, initQuotas, getQuotaTransactions} = useUser();
		const selectedQuota = ref<AbstractQuota>();
		const tabs = ref<any[]>([]);
		const route = useRoute();

		async function changeSelectedTab(selectedTab: any) {
			tabs.value.forEach((tab) => {
				if (tab.type === selectedTab.type) {
					tab.current = true;
					const searchURL = new URL(window.location.href);
					searchURL.searchParams.set('tab', selectedTab.type);
					window.history.pushState({}, '', searchURL);
				} else {
					tab.current = false;
				}
			});
			selectedQuota.value = getQuotas.value.getAvailableQuotaByType(selectedTab.type);
			if (selectedQuota.value?.uuid) {
				getQuotaTransactions(selectedQuota.value?.uuid)
			}
		}

		function initTabs() {
			let activeTabInitialized = false;
			let current = false;
			const tabParam = route?.query.tab ?? null;
			getQuotas.value.forEach((quota) => {
				if ((tabParam === null && !activeTabInitialized) || (tabParam === quota.type && !activeTabInitialized)) {
					current = true;
					activeTabInitialized = true;
					selectedQuota.value = quota;
				}
				const tab = {type: quota.type, name: quota.getQuotaName(), current: current};
				current = false;
				tabs.value.push(tab);
			});
		}

		async function fetchMoreTransactions(page: number) {
			if (selectedQuota.value?.uuid) {
				setIsGlobalLoading(true);
				setTimeout(() => {
					setIsGlobalLoading(false);
				}, 10000)
				getQuotaTransactions(selectedQuota.value?.uuid, page).then(() => {
					setIsGlobalLoading(false);
				})
			}
		}

		onMounted(() => {
			setIsGlobalLoading(true);
			setTimeout(() => {
				setIsGlobalLoading(false);
			}, 10000)
			initQuotas(true).then(() => {
				initTabs();
				if (selectedQuota.value?.uuid) {
					getQuotaTransactions(selectedQuota.value?.uuid).then(() => {
						setIsGlobalLoading(false);
					})
				} else {
					setIsGlobalLoading(false);
				}
			});
		});

		return {
			getQuotas,
			fetchMoreTransactions,
			tabs,
			changeSelectedTab,
			selectedQuota,
		};
	},
});
