
import {ref, onMounted, watch, defineComponent} from 'vue';
import useShop from '@/composable/greeve/useShop';
import * as icons from 'ionicons/icons';
import {CartInterface} from '@/greeve/shop/cart/cart.interface';
import useAuth from '@/composable/auth/useAuth';
import {ProductList} from '@/greeve/shop/product/product_list.type';
import {Product} from '@/greeve/shop/product/product.type';
import {GreeveProductReference} from '@/greeve/shop/product/product.interface';
import {useRouter} from 'vue-router';
import useUser from '@/composable/greeve/useUser';

export default defineComponent({
	name: 'CreditsNavCart',
	components: {},
	props: {
		checkoutLink: {
			type: String,
			default: '',
		},
		viewShoppingBagLink: {
			type: String,
			default: '',
		},
	},
	emits: [],
	setup() {
		const {isAuthenticated} = useAuth();
		const router = useRouter();
		const {
			products,
			cart,
			removeCartItem_by_Id,
			addProductToCart,
			getRecurringYearlySubType,
			getRecurringMonthlySubType,
		} = useShop();
		const {initQuotas, getQuotas, getSubscriptions, initSubscriptions} = useUser();
		const {logout} = useAuth();
		const oneTimeProductList: ProductList | any = ref();
		const showCartItemCountChangedAnimation = ref(false);

		watch(() => cart.value, (newValue: CartInterface, oldValue: CartInterface | undefined) => {
			if (!oldValue || newValue.items?.length !== oldValue.items?.length) {
				showCartItemCountChangedAnimation.value = true;
				setTimeout(() => {
					showCartItemCountChangedAnimation.value = false;
				}, 1500);
			}
		});

		onMounted(() => {
			//
		});

		const tiers = [
			{
				name: 'Growth',
				id: 'tier-growth',
				href: '/shop',
				featured: false,
				description: 'Convenient features to take your business to the next level.',
				price: {monthly: '€49', annually: '€470'},
				mainFeatures: [
					'200 Credits',
					'Access to prebuild Flows',
					'extendable Credits',
					'Mutli-accounts',
					'API Access',
					'20.000 API calls'],
			},
		];

		//TODO API REQUEST - User Profile/Account
		//TODO getActiveSubscription or none
		//TODO getAvaillableCredits

		const selected: Product | any = ref();

		function getDefaultSelectedOneTimeProduct(): string {
			if (selected.value) {
				return selected.value.product_reference;
			}
			return GreeveProductReference.STRIPE_PRODUCT_ONE_TIME_CREDIT_10;
		}

		function addSelectedOneTimeToCart(close: any) {
			if (selected.value) {
				addProductToCart(selected.value.product_id);
			}
			router.push('shop').then(() => {
				close();
			});
		}

		function getOneTimeProducts(force = false) {
			if (!oneTimeProductList.value || force) {
				oneTimeProductList.value = products.value.getOneTimeProductList();
				selected.value = oneTimeProductList.value[1];
			}
			return oneTimeProductList.value;
		}

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		function updateSelectedItem(selectedProduct: Product, key: any, value: any) {
			selected.value = products.value.getProduct_by_Reference(key);
		}

		async function initProductsForView() {
			getOneTimeProducts();
		}

		onMounted(() => {
			try {
				initProductsForView();
				initQuotas(true); //TODO remove true if interval exists
				initSubscriptions();
			} catch (e) {
				console.error(e);
			}
		});

		return {
			isAuthenticated,
			tiers,
			selected,
			cart,
			removeCartItem_by_Id, addProductToCart,
			icons,
			showCartItemCountChangedAnimation,
			logout,
			getOneTimeProducts,
			addSelectedOneTimeToCart,
			updateSelectedItem,
			getDefaultSelectedOneTimeProduct,
			getQuotas,
			getSubscriptions,
			getRecurringYearlySubType, getRecurringMonthlySubType,
		};
	},
});
