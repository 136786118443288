
import {defineComponent} from 'vue';
import InfoCard from '@/components/modal/InfoCard.vue';
import TeamManagement from "@/components/shop/profile/TeamManagement.vue";

export default defineComponent({
	name: 'TemplatePage',
	components: {
		InfoCard,
		TeamManagement,
	},
	setup() {
		return {}
	},
});

