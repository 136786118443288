
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'RatingAction',
	props: {
		rated: {
			type: Boolean,
			default: false,
		},
		ratedResult: {
			type: Boolean,
			default: false,
		},
		show: {
			type: Boolean,
			default: false,
		},
	},
	components: {},
	emits: ['rate'],
	setup(props, {emit}) {
		function goodResult() {
			emit('rate', true);
		}

		function badResult() {
			emit('rate', false);
		}

		return {goodResult, badResult}
	}
})
