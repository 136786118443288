
import {computed, defineComponent, onMounted, ref} from 'vue';
import {IonContent, IonPage} from '@ionic/vue';
import FooterBar from '@/components/positions/FooterBar.vue';
import {useHeaderHeight} from '@/composable/global/useHeaderHeight';
import InquiryComponent from '@/components/contact/InquiryComponent.vue';
import useSystem from '@/composable/core/useSystem';
import useTranslation from '@/composable/core/useTranslation';

export default defineComponent({
	name: 'Enterprise',
	components: {
		InquiryComponent,
		FooterBar, IonContent, IonPage,
	},
	setup() {
		const {headerHeight} = useHeaderHeight();
		const {scrollToElement} = useSystem();
		const {t} = useTranslation();

		const modes = ref([
			t('enterprise.stats.models.modes.social_media_content'),
			t('enterprise.stats.models.modes.chat'),
			t('enterprise.stats.models.modes.image_generation'),
			t('enterprise.stats.models.modes.texting'),
			t('enterprise.stats.models.modes.image_editing'),
			t('enterprise.stats.models.modes.image_vision'),
			t('enterprise.stats.models.modes.text_to_speech'),
			t('enterprise.stats.models.modes.speech_to_text'),
			t('enterprise.stats.models.modes.video_to_text'),
			t('enterprise.stats.models.modes.text_to_pdf'),
		]);

		const collapsed = ref(true);

		const displayedModes = computed(() => {
			return collapsed.value ? modes.value.slice(0, 3) : modes.value;
		});

		const shouldShowMore = computed(() => modes.value.length > 3);

		const toggleCollapse = () => {
			collapsed.value = !collapsed.value;
		};

		onMounted(() => {
			//
		});

		return {headerHeight, scrollToElement, displayedModes, shouldShowMore, toggleCollapse, collapsed};
	},
});

