
import {ChevronRightIcon, HomeIcon} from '@heroicons/vue/20/solid';

export default {
	name: 'BreadcrumbsHeader',
	components: {
		ChevronRightIcon,
		HomeIcon,
	},
	props: {
		from: {
			type: Number,
			default: 0,
		},
		till: {
			type: Number,
			default: 2,
		},
	},

	setup() {
		const pages = [
			{name: 'Services', href: '/services', current: false},
			{name: 'Detail', href: 'detail', current: false},
			{name: 'Workflow', href: '/workflow', current: false},
			{name: 'Demo', href: 'demo', current: false},
			{name: 'Pricing', href: '/shop', current: false},
			{name: 'Plans', href: 'plans', current: false},
			{name: 'Blog', href: '/blog', current: false},
			{name: 'Article', href: 'article', current: false},
		];

		return {
			pages,
		};
	},
};
