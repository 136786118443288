import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-569b7d55"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center rounded-full shadow min-w-[17em] sm:min-w-[26em]" }
const _hoisted_2 = {
  key: 0,
  controls: "",
  class: "w-full"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.src || _ctx.searchItem?.getSourceAudioFile())
      ? (_openBlock(), _createElementBlock("audio", _hoisted_2, [
          _createElementVNode("source", {
            src: _ctx.src && _ctx.src.length > 0 ? _ctx.src : _ctx.searchItem?.getSourceAudioFile(),
            type: "audio/mpeg"
          }, null, 8, _hoisted_3),
          _createTextVNode(" Your browser does not support the audio element. ")
        ]))
      : _createCommentVNode("", true)
  ]))
}