
import {defineComponent} from 'vue';
import {EllipsisVerticalIcon} from '@heroicons/vue/24/outline';
import {
	Popover,
	PopoverButton,
	PopoverPanel,
} from '@headlessui/vue';
import ListSelectDropdown from '@/components/core/ListSelectDropdown.vue';

export default defineComponent({
	name: 'SettingsNodePopover',
	components: {
		ListSelectDropdown,
		EllipsisVerticalIcon,
		Popover, PopoverButton, PopoverPanel,
	},
	props: {
		SettingsAvailable:  {
			default: false,
			type: Boolean,
		},
	},
	emits: [],
	setup() {
		return {
		};
	},
});
