
import {defineComponent, onMounted, ref, computed, reactive, watch} from 'vue';
import {
	ChatBubbleLeftIcon, PlusCircleIcon, ClockIcon, ArrowSmallDownIcon, ArrowSmallUpIcon, TrashIcon, PencilIcon, ArrowPathIcon
} from '@heroicons/vue/20/solid';
import useTranslation from '@/composable/translation/useTranslation';
import router from '@/router';
import useAssistant from '@/composable/greeve/useAssistant';
import {SearchGroup} from '@/greeve/search/group/search_group.type';
import useAuth from '@/composable/auth/useAuth';
import {SearchGroupList} from '@/greeve/search/group/search_group_list.type';
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {PlusIcon, XMarkIcon,
} from '@heroicons/vue/24/outline';
import useCustomStore from '@/composable/custom/useCustomStore';
import {useRoute} from 'vue-router';
import DynamicMenu from '@/components/core/DynamicMenu.vue';
import { MenuItemType } from '@/greeve/core/menu_item_type';
import ConfirmWithInputFieldDialog from '@/components/modal/ConfirmWithInputFieldDialog.vue';
import ConfirmDialog from '@/components/modal/ConfirmDialog.vue';

export default defineComponent({
	name: 'GroupList',
	props: {
		group_uuid: {
			type: String,
			default: '',
		},
		group_reference: {
			type: String,
			default: '',
		},
		updateTimestamp: {
			type: Number,
			default: undefined,
		},
		hasEmptyGroup: {
			type: Boolean,
			default: false,
		},
		isMenuOpen: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		ConfirmDialog,
		ConfirmWithInputFieldDialog,
		DynamicMenu,
		PlusIcon,
		ChatBubbleLeftIcon,
		PlusCircleIcon,
		ClockIcon,
		Dialog,
		DialogPanel,
		TransitionChild,
		TransitionRoot,
		XMarkIcon,
		ArrowSmallDownIcon,
		ArrowSmallUpIcon,
		ArrowPathIcon,
	},
	emits: ['groupSelected'],
	setup(props, {emit}) {
		const {t} = useTranslation();
		const {isAuthenticated} = useAuth();
		const {initSearchGroups, getGroupByUuid, searchGroups, addNewGroup, editGroup, deleteGroupByUuid, lastGroupUuid} = useAssistant();
		const selectedGroup = ref<SearchGroup | any>();
		const isGroupLoading = ref(false);
		const isNewChatLoading = ref(false);
		const isHistoryGroupsCollapsed = ref(false);
		const isEditable: any = reactive([]);
		const isSelectedEditable = ref(false);
		const {mobileMenuSidebar, setMobileMenuSidebar, setIsGlobalLoading} = useCustomStore();
		const route = useRoute();
		const groupedHistory = ref<{ date: string; groups: SearchGroup[]|SearchGroupList, collapsed: boolean }[]>([]);
		const hasMoreGroupsToLoad = ref(false);
		const showEditGroupDialog = ref(false);
		const showConfirmDeleteGroupDialog = ref(false);
		const actionGroupUuid = ref();

		const menuClass = computed(() => (props.isMenuOpen ? 'menu-open' : 'menu-close'));
		const editGroupValue = computed(() => {
			if (actionGroupUuid.value) {
				const groupToEdit = getGroupByUuid(actionGroupUuid.value);
				if (groupToEdit) {
					return groupToEdit.getName();
				}
			}
			return '';
		});

		const groupMenuItems = ref<MenuItemType[]>([
			{ label: t('group.change.button'), action: handleShowEditConfirmDialog, icon: PencilIcon },
			{ label: t('group.delete.button'), action: handleShowDeleteGroupConfirmDialog, icon: TrashIcon },
		])

		function handleGroupMenuAction(item: MenuItemType, reference?: string) {
			item.action(reference); // Execute the action associated with the clicked menu item
		}

		function handleShowEditConfirmDialog(reference: string) {
			actionGroupUuid.value = reference;
			showEditGroupDialog.value = true;
		}

		function handleShowDeleteGroupConfirmDialog(reference: string) {
			actionGroupUuid.value = reference;
			showConfirmDeleteGroupDialog.value = true;
		}

		function handleGroupDelete() {
			if (actionGroupUuid.value) {
				try {
					setIsGlobalLoading(true);
					const loadingTimeoutId = setTimeout(() => {
						setIsGlobalLoading(false);
					}, 10000);
					showConfirmDeleteGroupDialog.value = false;
					deleteGroupByUuid(actionGroupUuid.value).then(() => {
						if (searchGroups.value) {
							const activeGroup = searchGroups.value?.getNewestGroup();
							if (activeGroup) {
								updateGroup(activeGroup);
							}
						}
						setIsGlobalLoading(false);
						clearTimeout(loadingTimeoutId);
					});
				} catch (e) {
					setIsGlobalLoading(false);
				}
			}
		}

		function handleGroupEdit(inputValue: string) {
			if (actionGroupUuid.value) {
				try {
					setIsGlobalLoading(true);
					const loadingTimeoutId = setTimeout(() => {
						setIsGlobalLoading(false);
					}, 10000);
					showEditGroupDialog.value = false;
					editGroup(actionGroupUuid.value, inputValue, false).then(() => {
						const group = getGroupByUuid(actionGroupUuid.value);
						if (group) {
							updateGroup(group);
						}
						setIsGlobalLoading(false);
						clearTimeout(loadingTimeoutId);
					});
				} catch (e) {
					setIsGlobalLoading(false);
				}
			}
		}

		function selectGroup(searchGroup: SearchGroup) {
			selectedGroup.value = searchGroup;
			setMobileMenuSidebar(false);
			document.title = 'greeve - ' + searchGroup.getName(50);
			router.push('/assistant/chat/' + searchGroup.getUrlName() + '/' + searchGroup.getUuidEncoded() + '/' + searchGroup.reference);
			emit('groupSelected');
			isGroupLoading.value = false;
			isNewChatLoading.value = false;
		}

		function addNewGroupAndMarkAsActive() {
			if (!isAuthenticated.value) {
				return;
			}
			isNewChatLoading.value = true;
			const firstNewGroup = searchGroups.value?.getFirstNewActiveGroupWithoutItems();
			if (firstNewGroup) {
				selectedGroup.value = firstNewGroup;
				selectGroup(selectedGroup.value);
			} else {
				addNewGroup().then((group) => {
					selectedGroup.value = group;
					selectGroup(selectedGroup.value);
				});
			}
		}

		//TODO remove
		const makeGroupEditable = (groupUuid: string) => {
			isEditable[groupUuid] = false;
		};

		async function updateGroupName(event: FocusEvent, groupUuid: string) {
			const updateGroup = getGroupByUuid(groupUuid);
			if (!updateGroup) {
				isEditable[groupUuid] = false;
				return;
			}
			try {
				isEditable[groupUuid] = false;
				const target = event.target as HTMLDivElement;
				if (target.textContent === updateGroup.getName()) {
					return;
				}
				await editGroup(updateGroup.uuid, target.textContent || '');
			} catch (e) {
				console.error(e);
			} finally {
				isEditable[groupUuid] = false;
			}
		}

		function updateGroup(searchGroupNew: SearchGroup) {
			if (searchGroupNew.uuid && searchGroupNew.reference) {
				selectedGroup.value = getGroupByUuid(searchGroupNew.uuid);
				if (selectedGroup.value) {
					isSelectedEditable.value = false;
					selectGroup(selectedGroup.value);
				}
			}
		}

		//TODO load groups without items

		async function initGroups(loadAll = false) {
			if (isGroupLoading.value) {
				return;
			}
			const loadItems = false;
			isGroupLoading.value = true;
			const pageCount = loadAll ? 0 : 8;
			//TODO check if group selected and load first selected group
			if (useAuth().isAuthenticated.value && (!props.group_uuid || props.group_uuid.length === 0)) {
				initSearchGroups(true, loadItems, pageCount).then(() => {
					if (lastGroupUuid.value) {
						selectedGroup.value = getGroupByUuid(lastGroupUuid.value);
						if (selectedGroup.value) {
							selectGroup(selectedGroup.value);
						}
					} else {
						addNewGroupAndMarkAsActive();
					}
					groupHistoryByDate();
					isGroupLoading.value = false;
				});
			} else if (props.group_uuid && props.group_uuid.length > 0) {
				if (useAuth().isAuthenticated.value) {
					initSearchGroups(true, loadItems, pageCount).then(() => {
						selectedGroup.value = getGroupByUuid(props.group_uuid);
						if (selectedGroup.value) {
							selectGroup(selectedGroup.value);
						} else {
							addNewGroupAndMarkAsActive();
						}
						groupHistoryByDate();
						isGroupLoading.value = false;
					});
				} else {
					selectedGroup.value = getGroupByUuid(props.group_uuid);
					if (selectedGroup.value) {
						selectGroup(selectedGroup.value);
					} else {
						addNewGroupAndMarkAsActive();
					}
					groupHistoryByDate();
				}
			} else if (lastGroupUuid.value) {
				selectedGroup.value = getGroupByUuid(lastGroupUuid.value);
				if (selectedGroup.value) {
					selectGroup(selectedGroup.value);
				}
				groupHistoryByDate();
			} else {
				addNewGroupAndMarkAsActive();
				groupHistoryByDate();
			}
		}

		function getAllGroups(): SearchGroupList | undefined {
			if (searchGroups.value) {
				const ignoreList = [];
				if (selectedGroup.value && selectedGroup.value?.uuid) {
					ignoreList.push(selectedGroup.value.uuid);
				}
				return searchGroups.value;
			}
			return;
		}

		function getAllGroupsWithoutSelected(): SearchGroup[] | SearchGroupList | undefined {
			if (searchGroups.value) {
				const ignoreList = [];
				if (selectedGroup.value && selectedGroup.value?.uuid) {
					ignoreList.push(selectedGroup.value.uuid);
				}
				return searchGroups.value?.filterList(ignoreList);
			}
			return;
		}

		// Method to group history items by date
		const groupHistoryByDate = () => {
			const groups = getAllGroups();
			const grouped: { [key: string]: SearchGroup[] } = {};

			// Grouping items by date
			groups?.forEach(group => {
				if (group?.updated_at) {
					const dateKey = getDateKey(group?.updated_at);
					if (!grouped[dateKey]) {
						grouped[dateKey] = [];
					}
					grouped[dateKey].push(group);
				}
			});

			// Creating an array of grouped items with date
			const groupedArray = Object.keys(grouped).map(date => ({
				date,
				groups: grouped[date],
				collapsed: false
			}));

			// Sorting by date (you can implement custom sorting logic here)
			groupedArray.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

			groupedHistory.value = groupedArray;
			hasMoreGroupsToLoad.value = !!(groups && groups?.hasMoreItemsToLoad());
		};

		// Helper method to get a date key (Today, Yesterday, etc.)
		const getDateKey = (parsedDate: Date): string => {
			const today = new Date();
			const yesterday = new Date(today);
			yesterday.setDate(today.getDate() - 1);

			if (isSameDay(parsedDate, today)) {
				return t('historyGroup.today');
			} else if (isSameDay(parsedDate, yesterday)) {
				return t('historyGroup.yesterday');
			} else {
				return getMonthYear(parsedDate);
			}
		};

		// Helper method to check if two dates are the same day
		const isSameDay = (date1: Date, date2: Date): boolean => {
			return date1.getFullYear() === date2.getFullYear() &&
					date1.getMonth() === date2.getMonth() &&
					date1.getDate() === date2.getDate();
		};

		// Helper method to get month and year in format "Month Year"
		const getMonthYear = (date: Date): string => {
			const monthNumber = date.getMonth() + 1;
			const yearSuffix = (date.getFullYear() !== (new Date).getFullYear()) ? ` ${date.getFullYear()}` : '';
			return t(`historyGroup.${monthNumber}`)+yearSuffix;
		};

		// Method to toggle collapse state of a group
		const toggleCollapse = (index: number) => {
			groupedHistory.value[index].collapsed = !groupedHistory.value[index].collapsed;
		};

		function loadAllGroups() {
			if (!isGroupLoading.value && isAuthenticated) {
				initGroups(true);
			}
		}

		function refreshGroup() {
			if (!isGroupLoading.value && isAuthenticated) {
				initGroups(false);
			}
		}

		watch(() => searchGroups.value,
				(newGroups: SearchGroupList | undefined, oldGroups: SearchGroupList | undefined) => {
					if ((newGroups && newGroups !== oldGroups) && selectedGroup.value && selectedGroup.value.uuid) {
						const newSelectedGroup = newGroups.getItemByUuId(selectedGroup.value.uuid);
						if (newSelectedGroup && newSelectedGroup.name && newSelectedGroup.name?.length > 0 && newSelectedGroup?.name !== selectedGroup.value?.name) {
							updateGroup(newSelectedGroup);
						}
					}
				});


		watch(() => props.updateTimestamp, (updateTimestamp) => {
			if (updateTimestamp && route.path.includes('/assistant') && !isGroupLoading.value && isAuthenticated) {
				initGroups(false);
			}
		});

		//TODO check and remove assistant/New if not logged in -- check shared link
		onMounted(() => {
			if (!isGroupLoading.value && isAuthenticated) {
				initGroups(false);
			}
		});

		return {
			t,
			menuClass,
			addNewGroupAndMarkAsActive,
			searchGroups,
			selectedGroup,
			selectGroup,
			getAllGroupsWithoutSelected,
			isHistoryGroupsCollapsed,
			mobileMenuSidebar,
			setMobileMenuSidebar,
			makeGroupEditable,
			updateGroupName,
			isEditable,
			isSelectedEditable,
			updateGroup,
			isAuthenticated,
			groupedHistory,
			toggleCollapse,
			isGroupLoading,
			isNewChatLoading,
			hasMoreGroupsToLoad,
			loadAllGroups,
			groupMenuItems,
			handleGroupMenuAction,
			PencilIcon,
			showConfirmDeleteGroupDialog,
			showEditGroupDialog,
			handleGroupEdit,
			handleGroupDelete,
			editGroupValue,
			refreshGroup
		};
	},
});
