
import {ref, defineComponent, onBeforeMount} from 'vue';
import useTranslation from "@/composable/translation/useTranslation";
import useUser from "@/composable/greeve/useUser";
import useAlert from "@/composable/core/useAlert";
import {GreeveApiProfile, UpdateUserPassword} from "@/greeve/api/profile";

export default defineComponent({
	name: "SecurityManagement",
	components: {},
	props: {},
	emits: [],
	setup() {
		const {t} = useTranslation();
		const {getProfile} = useUser();
		const {addSuccess, addError, resetAllAlerts} = useAlert();

		const userPassword = ref({
			current_password: '',
			password: '',
			password_confirmation: '',
		});

		function isPasswordValid(): boolean
		{
			return userPassword.value.password.length > 0 && userPassword.value.password_confirmation.length > 0 && userPassword.value.password === userPassword.value.password_confirmation && userPassword.value.current_password.length > 0;
		}

		async function updateUserPassword() {
			const userPasswordData: UpdateUserPassword = {
				current_password: String(userPassword.value.current_password),
				password: String(userPassword.value.password),
				password_confirmation: String(userPassword.value.password_confirmation),
			};
			try {
				resetAllAlerts();
				await GreeveApiProfile.updatePassword(userPasswordData);
				const success = {
					id: "",
					title: "Personal Information updated!",
					message: "Your Password successfully updated",
					active: true,
				}
				addSuccess(success);
			} catch (e) {
				console.log(e);
				addError({title: t('auth.auth_error_failed_title'), message: t('auth.failed'), active: true});
			}
		}

		onBeforeMount(() => {
			//resetAllAlerts();
		});

		return {
			userPassword,
			getProfile,
			updateUserPassword,
		isPasswordValid,
		};
	}
})
