
import {IonPage, IonContent, IonRouterOutlet} from '@ionic/vue';
import {defineComponent, onMounted, ref, computed, Transition, watch} from 'vue';
import FooterBar from '@/components/positions/FooterBar.vue';
import {
	Bars3BottomRightIcon, Bars3Icon,
	CalendarIcon,
	ChartPieIcon,
	DocumentDuplicateIcon,
	FolderIcon,
	HomeIcon, ChatBubbleLeftIcon,
	UsersIcon, CheckIcon, ChevronDownIcon, ClockIcon
} from '@heroicons/vue/20/solid';
import useSystem from '@/composable/core/useSystem';
import useTranslation from '@/composable/translation/useTranslation';
import {useRoute} from 'vue-router';
import {useHeaderHeight} from '@/composable/global/useHeaderHeight';
import useCustomStore from '@/composable/custom/useCustomStore';
import GroupList from "@/components/assistant/Group/GroupList.vue";
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import {FlowSubType} from '@/greeve/flow/flow.interface';
import useFlowFactory from '@/composable/greeve/useFlowFactory';
import WizzardComponent from '@/components/wizzard/WizzardComponent.vue';

export default defineComponent({
	name: 'AssistantPageNew',
	components: {
		WizzardComponent,
		Listbox,
		ListboxButton,
		ListboxLabel,
		ListboxOption,
		ListboxOptions,
		CheckIcon,
		ChevronDownIcon,
		GroupList,
		IonRouterOutlet,
		Bars3Icon,
		Bars3BottomRightIcon,
		IonPage,
		IonContent,
		Transition,
		FooterBar,
		CalendarIcon,
		ChartPieIcon,
		HomeIcon, ChatBubbleLeftIcon,
		DocumentDuplicateIcon,
		FolderIcon,
		UsersIcon,
		ClockIcon
	},
	setup() {
		const {getFlowWizzardFactory} = useFlowFactory();
		const currentWizzard = ref();

		const navigation = [
			{ name: 'Whatsapp', href: '#', icon: CalendarIcon, color:'bg-[#25D366]', logo:'/assets/img/services/whatsapp.png', count: '5', current: true, description: 'This job posting can be viewed by anyone who has the link.' },
			{ name: 'Instagram', href: '#', icon: UsersIcon, color:'bg-[]', logo:'/assets/img/services/instagram.png', current: false, description: 'This job posting can be viewed by anyone who has the link.'  },
			{ name: 'Messenger', href: '#', icon: FolderIcon, color:'bg-[]', logo:'/assets/img/services/messenger.png', count: '12', current: false, description: 'This job posting can be viewed by anyone who has the link.'  },
			{ name: 'Telegram', href: '#', icon: HomeIcon, color:'bg-[]', logo:'/assets/img/services/telegram.png', count: '20+', current: false, description: 'This job posting can be viewed by anyone who has the link.'  },
		]

		const selected = ref(navigation[0])

		const actions = [
			{ id: 1, name: 'Calendar', href: '#', initial: 'H', current: false, icon: CalendarIcon, },
			{ id: 2, name: 'Abos', href: '#', initial: 'T', current: true, icon: UsersIcon, },
			{ id: 3, name: 'Analytics', href: '#', initial: 'W', current: false, icon: ChartPieIcon, },
			{ id: 4, name: 'History', href: '#', initial: 'W', current: false, icon: ClockIcon, },
		]
		const workflows = [
			{ id: 1, name: 'Workflow 1', href: '#', initial: 'H', current: false, icon: ChatBubbleLeftIcon, },
			{ id: 2, name: 'Workflow 2', href: '#', initial: 'T', current: false, icon: ChatBubbleLeftIcon, },
		]


		const {headerHeight} = useHeaderHeight();
		const {isFooterVisible, isAssistantGroupMenuOpen, setIsAssistantGroupMenuOpen,} = useCustomStore();
		const route = useRoute();
		const groupUuidByUrl = ref('');
		const groupReferenceByUrl = ref('');
		const loadingFinished = ref(false);
		const hasEmptyUrl = ref(false);
		const updateTimeStamp = ref<number|null>(null);
		const groupChanged = ref<number|undefined>();
		const groupMenuToggleState = ref(false);

		const {t} = useTranslation();

		const toggleMenu = async () => {
			groupMenuToggleState.value = !groupMenuToggleState.value;
			setTimeout(() => {
				setIsAssistantGroupMenuOpen(groupMenuToggleState.value);
			}, 10)
		};

		const {scrollToTop} = useSystem();

		function focusInputField() {
			groupChanged.value = new Date().getTime();
		}

		watch(route, (route) => {
			if (route.path.includes('/assistant')) {
				loadingFinished.value = false;
				// selectGroupByRoute(route);
			}
		});

		onMounted(() => {
			currentWizzard.value = getFlowWizzardFactory().createWizzardByType(FlowSubType.WHATSAPP_DEFAULT_NEWSLETTER);
			groupMenuToggleState.value = isAssistantGroupMenuOpen.value;
			scrollToTop();
		});

		const asideClass = computed(() => (groupMenuToggleState.value
				? 'z-50 pb-0 lg:pb-4 lg:col-span-auto lg:sticky lg:top-0'
				: 'z-50 pb-0 lg:pb-4 lg:col-span-auto lg:sticky lg:top-0'));

		const toggleButtonClass = computed(() => (groupMenuToggleState.value ? '' : ''));

		const menuClass = computed(() => (groupMenuToggleState.value ? 'menu-open' : 'menu-close'));

		return {
			selected,
			navigation,
			actions,
			workflows,
			t,
			toggleMenu,
			asideClass,
			toggleButtonClass,
			menuClass,
			headerHeight,
			groupUuidByUrl,
			groupReferenceByUrl,
			loadingFinished,
			hasEmptyUrl,
			updateTimeStamp,
			isFooterVisible,
			focusInputField,
			groupChanged,
			groupMenuToggleState,
			currentWizzard
		};
	},
});
