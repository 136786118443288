
import {defineComponent, onMounted, ref} from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import useCustomStore from '@/composable/custom/useCustomStore';

export default defineComponent({
	name: 'InfoCard',
	props: {
		img: {
			type: String,
			default: '',
		},
		title: {
			type: String,
			default: 'title',
		},
		info: {
			type: String,
			default: 'info',
		},
		allowToClose: {
			type: Boolean,
			default: true,
		},
		infoKey: {
			type: String,
			required: true,
		}
	},
	components: {
		XMarkIcon,
	},
	setup(props) {
		const {getInfoBanner_by_Id, addInfoBanner, updateInfoBannerState} = useCustomStore();

		const isOpen = ref(false);

		function closeModal() {
			updateInfoBannerState(props.infoKey, false)
			isOpen.value = false;
		}

		function openModal() {
			isOpen.value = true;
			updateInfoBannerState(props.infoKey)
		}

		onMounted(() => {
			const infoBanner = getInfoBanner_by_Id(props.infoKey);
			if (!infoBanner) {
				addInfoBanner(props.infoKey);
				isOpen.value = true;
			} else {
				isOpen.value = infoBanner.active ?? true;
			}
		});

		return {
			openModal,
			closeModal,
			isOpen,
		};
	},
});
