
import {defineComponent} from 'vue';
import useTranslation from "@/composable/translation/useTranslation";
import {Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue';
import {MinusSmallIcon, PlusSmallIcon} from '@heroicons/vue/24/outline';
import useFaq from "@/composable/faq/useFaq";

export default defineComponent({
	name: 'FaqSection',
	components: {
		Disclosure, DisclosureButton, DisclosurePanel,
		MinusSmallIcon, PlusSmallIcon
	},
	setup() {
		const {t} = useTranslation();
		const {faqs} = useFaq();
		return {t, faqs}
	}
});

