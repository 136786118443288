
import {defineComponent, ref, onMounted, onBeforeMount} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import useSystem from "@/composable/core/useSystem";
import LoadingLineAnimation from "@/components/animations/LoadingLineAnimation.vue";
import DefaultButton from "@/components/core/DefaultButton.vue";
import router from "@/router";
import LoadingAnimation from "@/components/animations/LoadingAnimation.vue";
import {useRoute} from "vue-router";
import useAlert from "@/composable/core/useAlert";
import useTranslation from "@/composable/translation/useTranslation";
import {useHeaderHeight} from "@/composable/global/useHeaderHeight";

export default defineComponent({
	name: 'CheckoutCancelPage',
	components: {
		LoadingAnimation,
		DefaultButton,
		LoadingLineAnimation,
		FooterBar, IonContent, IonPage
	},
	setup() {
		const { headerHeight } = useHeaderHeight();
		const {scrollToTop} = useSystem();
		const {clearErrorAlert, addError} = useAlert();
		const isLoading = ref(true);
		const isOrderSuccessfully = ref(false);
		const isCancelPage = ref(false);
		const currentRoute = ref();
		const {t} = useTranslation();

		function redirectToCheckout() {
			router.push('/shop');
		}

		function initShopParams() {
			isCancelPage.value = false;
			currentRoute.value = useRoute();
			const currentRoutePath = currentRoute.value.path || null;
			const cancelRoute: boolean = currentRoutePath === '/shop/cancel';
			if (cancelRoute) {
				clearErrorAlert();

				const errorCancel = {
					id: "",
					title: t('shop.checkout.order_cancel.title'),
					message: t('shop.checkout.order_cancel.general_error'),
					active: true,
				};

				addError(errorCancel);
				isOrderSuccessfully.value = false;
				router.push('/shop');
			}
		}

		onBeforeMount(() => {
			initShopParams();
		});

		onMounted(() => {
			setTimeout(() => {
				isLoading.value = false;
			}, 5000)
			scrollToTop();
		})

		return {
			isOrderSuccessfully,
			isLoading,
			redirectToCheckout, headerHeight
		}
	}
});

