
import {defineComponent} from 'vue';
import {SearchItemTranscribeAudio} from '@/greeve/search/item/type/subtype/search_item_transcribe_audio.type';

export default defineComponent({
	name: 'AudioOutputElement',
	components: {},
	props: {
		searchItem: {
			type: Object as () => SearchItemTranscribeAudio,
		},
		src: {
			type: String,
		},
	},
	emits: [],
	setup() {
		return {};
	},
});

