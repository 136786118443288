

export default {
	name: "ErrorComponent",
	components: {},
	props: {
		errorImage: {
			type: String,
			default: 'assets/mascot/heidee-oh.webp'
		},
		errorId: {
			type: String,
			default: ''
		},
		errorNumber: {
			type: String,
			default: ''
		},
		errorPadding: {
			type: String,
			default: 'py-16'
		},
		errorTitle: {
			type: String,
			default: ''
		},
		errorMessage: {
			type: String,
			default: ''
		}
	},
	setup() {
		return {};
	}
}
