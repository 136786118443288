
import {defineComponent, onMounted, ref} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import DefaultButton from '@/components/core/DefaultButton.vue';
import useAuth from '@/composable/auth/useAuth';
import useToastMessage from '@/composable/core/useToastMessage';
import i18n from '@/i18n';
import {useHeaderHeight} from "@/composable/global/useHeaderHeight";

export default defineComponent({
	name: 'LogoutPage',
	components: {
		DefaultButton,
		FooterBar,
		IonPage,
		IonContent
	},
	setup() {
		const { headerHeight } = useHeaderHeight();
		const {logout} = useAuth();
		const {openToast} = useToastMessage();

		const isLogoutInProcess = ref(true);

		onMounted(() => {
			isLogoutInProcess.value = true;
			logout(true, true);
			openToast(i18n.global.t('shop.checkout.logout.successfully'), 'success', 'bottom', true, 5000, undefined, true);
			isLogoutInProcess.value = false;
		})


		return {logout, isLogoutInProcess,
			headerHeight}
	}
});

