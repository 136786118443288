
import {defineComponent} from 'vue';
import useTranslation from "@/composable/translation/useTranslation";

export default defineComponent({
	name: 'HeaderLogo',
	props: {
		name: String,
		smallLogo: {
			type: Boolean,
			default: false
		},
		scrollToTop: {
			type: Boolean,
			default: false
		},
		customClass: {
			type: String,
			default: 'header p-4',
		},
		customHeight: {
			type: String,
			default: 'max-img-height',
		},
		pro: {
			type: Boolean,
			default: false,
		},
		beta: {
			type: Boolean,
			default: true,
		},
		redirectLink: {
			type: String,
			default: 'search',
		},
		shortLogo: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		const {t} = useTranslation();
		return {t,};
	}
});
