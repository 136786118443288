
import { defineComponent, ref } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import router from '@/router';
import useSystem from '@/composable/core/useSystem';

export default defineComponent({
	name: 'ActionBanner',
	props: {
		title: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		actionTitle: {
			type: String,
			default: '',
		},
		redirectLink: {
			type: String,
			default: '',
		},
	},
	components: {
		XMarkIcon,
	},
	setup(props) {
		const isOpen = ref(true);
		const {addLeadingSlash} = useSystem();

		function closeModal() {
			isOpen.value = false;
		}

		function openModal() {
			isOpen.value = true;
		}

		function redirect() {
			router.push(addLeadingSlash(props.redirectLink));
		}

		return {
			openModal,
			closeModal,
			isOpen,
			redirect,
		};
	},
});
