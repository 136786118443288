
import {defineComponent, reactive} from 'vue';
import InputNickMail from "@/components/inputs/InputNickMail.vue";
import InputPassword from "@/components/inputs/InputPassword.vue";
import useUserRepository from "@/composable/greeve/useUser";
import DefaultButton from "@/components/core/DefaultButton.vue";
import router from '@/router';
import useTranslation from "@/composable/translation/useTranslation";
import DangerAlert from "@/components/alert/Base/DangerAlert.vue";
import useAuth from "@/composable/auth/useAuth";
import i18n from "@/i18n";
import {GreeveApiAuth} from "@/greeve/api/auth";

export default defineComponent({
	name: 'RegisterComponent',
	components: {
		DefaultButton,
		InputNickMail,
		InputPassword,
		DangerAlert,
	},
	props: {
		redirectAfterSubmit: {
			type: Boolean,
			default: false
		},
		showSwitch: {
			type: Boolean,
			default: true
		},
	},
	emits: ['onRegisterSuccess', 'onRegisterError'],
	setup(props, {emit}) {
		const {initUserByUserType} = useUserRepository();
		const {
			errorMessage,
			errorTitle,
			clearErrorMessage,
			setErrorTitle,
			setErrorMessage,
			getDeviceHash
		} = useAuth();

		const user = reactive({
			email: "",
			emailError: "",
			password: "",
			passwordError: "",
			terms: false,
		});

		const {t,} = useTranslation();

		function clearUserErrors() {
			user.emailError = "";
			user.passwordError = "";
		}

		async function onSubmit() {
			clearErrorMessage();
			clearUserErrors();

			const registerData = {
				name: user.email,
				email: user.email,
				password: user.password,
				password_confirmation: user.password,
				terms: user.terms,
				device_name: getDeviceHash(),
			};

			try {
				const token = await GreeveApiAuth.registerUser(registerData);
				if (token) {
					const userResponse = await GreeveApiAuth.getUser();
					if (userResponse && userResponse.id) {
						initUserByUserType(userResponse);
						if (props.redirectAfterSubmit) {
							router.push('/');
						}
						emit('onRegisterSuccess');
					} else {
						setErrorTitle(t('auth.auth_error_failed_title'));
						setErrorMessage(t('auth.failed'));
						emit('onRegisterError', t('auth.failed'));
					}
				}
			} catch (error:any) {
				console.log(error);
				setErrorTitle(t('auth.auth_error_failed_title'));
				setErrorMessage(t('auth.failed'));
				if (error.message.email) {
					user.emailError = error.message.email.join("<br>");
				}
				if (error.message.password) {
					user.passwordError = error.message.password.join("<br>");
				}
				emit('onRegisterError', t('auth.failed'));
			}
		}

		return {
      tabs: [
        {name: i18n.global.t("auth.LoginComponent.loginTitle"), link: '/login', current: false},
        {name: i18n.global.t("auth.RegisterComponent.registerTitle"), link: '/register', current: true}
      ],
			t, user,
			onSubmit,
			errorMessage,
			errorTitle,
			clearErrorMessage,
		}

	},
})
