
import {defineComponent} from 'vue';

export default defineComponent({
	name: "TeamManagement",
	components: {
		// TrashIcon, PlusIcon
	},
	props: {},
	emits: [],
	setup() {
		return {

		};
	}
})
