// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError} from "axios";
import {GreeveApiGetResponse, ResponseError} from "@/greeve/api/api.interfaces";
import GreeveApiClient from "@/greeve/api/client";
import {CountryFactory} from "@/greeve/core/country/country_factory.type";
import {CountryList} from "@/greeve/core/country/country_list.type";

interface GreeveGetResponse extends GreeveApiGetResponse {
    data: {type: string, result: any};
    status: any;
}

const GreeveApiSystem = {
    getCountries: async function (): Promise<CountryList|undefined> {
        try {
            const response = await GreeveApiClient.get('/country');
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const countryFactory = new CountryFactory();
                    return countryFactory.createCountryListByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    downloadPdfByContent: async function (pdfFileName: string, content: string, title = ''): Promise<undefined> {
        try {
            const params:any = {
                'content': content,
                'title': title,
                // 'options': null,
            };
            const response = await GreeveApiClient.post('/user/pdf/generate', params, {responseType: 'blob'});
            if (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', pdfFileName+'.pdf')
                document.body.appendChild(link)
                link.click()
                return;
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    downloadPdfByHtml: async function (pdfFileName: string, content: string, title = ''): Promise<undefined> {
        try {
            const params:any = {
                'content': content,
                'title': title
                // 'options': null,
            };
            const response = await GreeveApiClient.post('/user/pdf/html/generate', params, {responseType: 'blob'});
            if (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', pdfFileName+'.pdf')
                document.body.appendChild(link)
                link.click()
                return;
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    downloadPdfBySearchItem: async function (pdfFileName: string, searchItemUuid: string, title = ''): Promise<undefined> {
        try {
            const params:any = {
                'search_item_uuid': searchItemUuid,
                'title': title
                // 'options': null,
            };
            const response = await GreeveApiClient.post('/user/pdf/item/generate', params, {responseType: 'blob'});
            if (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', pdfFileName+'.pdf')
                document.body.appendChild(link)
                link.click()
                return;
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },
}

export {GreeveApiSystem, GreeveGetResponse};
