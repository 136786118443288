
import {defineComponent, onMounted, ref} from 'vue';
import Dropzone, {DropzoneFile} from 'dropzone';
import * as icons from 'ionicons/icons';
import useTranslation from '@/composable/core/useTranslation';
import SettingsNodePopover from '@/components/workflow/nodes/SettingsNodePopover.vue';
import {nanoid} from 'nanoid';
import {AbstractFlowProcess} from '@/greeve/flow/process/abstract_flow_process.type';
import {FlowProcessGreeveWhisper} from '@/greeve/flow/process/type/greeve_whisper/flow_process_greeve_whisper.type';
import useFlow from '@/composable/greeve/useFlow';
import {FlowMedia} from '@/greeve/flow/media/flow_media.type';
import LoadingAnimation from '@/components/animations/LoadingAnimation.vue';
import ConfirmDialog from '@/components/modal/ConfirmDialog.vue';

export default defineComponent({
	name: 'UploadNode',
	components: {ConfirmDialog, LoadingAnimation, SettingsNodePopover},
	props: {
		flowProcess: {
			type: Object as () => AbstractFlowProcess | FlowProcessGreeveWhisper,
			required: false,//TODO FIX and remove
		},
		flowProcessUuid: {type: String, required: false}, //TODO fix add required true
		componentId: {type: String, default: nanoid()},
		maxFileSize: { //IN MB
			type: Number,
			default: 50,
		},
		timeout: { //upload timeout
			type: Number,
			default: null,
		},
		uploadMultipleFiles: {
			type: Boolean,
			default: false,
		},
		fileClickable: {
			type: Boolean,
			default: true,
		},
		createImageThumbnails: {
			type: Boolean,
			default: true,
		},
		thumbnailWidth: {
			type: Number,
			default: 120,
		},
		thumbnailHeight: {
			type: Number,
			default: 120,
		},
		maxFiles: {
			type: Number,
			default: undefined,
		},
		acceptedFiles: {
			type: String,
			default: 'image/*,application/pdf,audio/*,video/*',
		},
		customCss: {
			type: String,
			default: '',
		},
		uploadUrl: {
			type: String,
			default: null,
		},
	},
	emits: [
		'fileAdded',
		'removedFile',
		'error',
		'uploadProgress',
		'success',
		'canceled',
		'complete',
		'submitForm',
		'nodeChanged'],
	setup(props, {emit}) {
		const {t} = useTranslation();
		const {flows, initFlows, uploadAudioFileToFlowProcess} = useFlow();
		const isLoading = ref(false);
		const selectedFlowProcess = ref<AbstractFlowProcess | undefined>();
		const dropRef = ref<HTMLDivElement | null>(null);
		const dropZoneElement = ref<Dropzone | null>(null);
		const fileToDelete = ref<DropzoneFile | null>(null);
		const showConfirmDeleteFileDialog = ref(false);
		const customPreview = `
      <div class="dz-preview dz-processing dz-file-preview dz-complete">
<!--        <div class="dz-image">-->
<!--			<img data-dz-thumbnail>-->
<!--		</div>-->
		<div class="dz-file-preview">
			<div data-dz-thumbnail></div>
		</div>
        <div class="dz-details">

          <div class="dz-size"><span data-dz-size></span></div>
            <div class="dz-filename"><span data-dz-name></span></div>
          </div>
          <div class="dz-progress">
            <span class="dz-upload" data-dz-uploadprogress></span>
          </div>
          <div class="dz-error-message"><span data-dz-errormessage></span></div>
        </div>
      </div>
    `;

		function addRemoveButtonToFile(file: DropzoneFile, dropzone: Dropzone) {
			let removeButton;
			if (file.size > 1048576) {
				removeButton = Dropzone.createElement(
						'<div class=\'column\'><span class=\'btn btn-danger btn-xs pull-right cancel\'><i class=\'fa fa-times\'></i> '+ t('flow.button_delete_file') +'</span></div>');
			} else {
				removeButton = Dropzone.createElement(
						'<div class=\'column\'><span data-dz-remove class=\'btn btn-danger btn-xs pull-right delete\'><i class=\'fa fa-trash-o\'></i> '+ t('flow.button_delete_file') +'</span></div>');
			}
			file.previewElement.appendChild(removeButton);

			// Listen to the remove button click event
			removeButton.addEventListener('click', function() {
				removedFileEvent(file, dropzone);
			});
		}

		function previewFile(file: DropzoneFile, mediaFile?: FlowMedia) {
			const previewElement: any = file.previewElement.querySelector('[data-dz-thumbnail]');
			if (previewElement) {
				let newPreviewElement: any;
				// CUSTOM THUMBNAIL FOR FLES OTHER THAN IMAGE TYPE
				if (file.type == 'application/pdf' || file.type == 'pdf') {
					previewElement.src = 'images/pdf-icon.png';
				} else if (file.type == 'text/plain' || file.type == 'txt') {
					previewElement.src = 'images/txt-icon.png';
				} else if (file.type == 'application/msword' || file.type == 'docx' || file.type ==
						'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
					newPreviewElement = document.createElement('img');
					newPreviewElement.src = 'images/docx-icon.png';
					newPreviewElement.height = 240;
					newPreviewElement.width = 320;
				} else if (file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
						file.type == 'xlsx') {
					newPreviewElement = document.createElement('img');
					newPreviewElement.src = 'images/xlsx-icon.png';
					newPreviewElement.height = 240;
					newPreviewElement.width = 320;
				} else if (file.type == 'audio/x-m4a' ||
						file.type == 'mp3' || file.type.includes('audio')) {
					newPreviewElement = document.createElement('audio');
					newPreviewElement.src = file.dataURL;
					newPreviewElement.addEventListener('loadedmetadata', () => {
						newPreviewElement.after('Dauer: ' + newPreviewElement.duration.toFixed(0) + ' Sekunden');
					});
				} else if (file.type == 'video/mp4' || file.type.includes('video')) {
					const previewVideo = mediaFile ? mediaFile.getTemporaryUrl() : null;
					newPreviewElement = document.createElement('video');
					newPreviewElement.src = previewVideo;
					newPreviewElement.controls = true;
					newPreviewElement.height = 240;
					newPreviewElement.width = 320;
					newPreviewElement.addEventListener('loadedmetadata', () => {
						newPreviewElement.after('Dauer: ' + newPreviewElement.duration.toFixed(0) + ' Sekunden');
					});
				}
				if (newPreviewElement) {
					previewElement.replaceWith(newPreviewElement);
				}
			}
		}

		function fileAddedEvent(file: DropzoneFile, dropzone: Dropzone) {
			console.log(`File added: ${file.name}`, file);
			if (!selectedFlowProcess.value) {
				console.error('Process not selected!');
				return;
			}

			//TODO show loading
		  	isLoading.value = true;
			const timeoutId = setTimeout(() => {
				isLoading.value = false;
			}, 20000);
			uploadAudioFileToFlowProcess(selectedFlowProcess.value, [file]).then((flowProcess: AbstractFlowProcess) => {
				//TODO get media file and set previewElement by media item
				let mediaFile: FlowMedia | undefined;
				if (flowProcess.media_list) {
					mediaFile = flowProcess.media_list.getItemByFileName(file.name);
				}
				previewFile(file, mediaFile);
				emit('fileAdded', file);
				const viewButton = Dropzone.createElement(
						'<div class=\'flex\'><span class=\'btn btn-primary btn-xs pull-right view\'>view</span></div>');
				file.previewElement.appendChild(viewButton);
				viewButton.addEventListener('click', function() {
					if (mediaFile) {
						window.open(mediaFile.getTemporaryUrl(), '_blank');
					}
				});
				isLoading.value = false;
				clearTimeout(timeoutId);
				//END LOADING
			});
			// flowProcessMediaFileMap.value.push([{'file': file, 'mediaFileId': result}]);
			// console.log(flowProcessMediaFileMap.value);

			addRemoveButtonToFile(file, dropzone);

			// Listen to the view button click event

		}

		function deleteFile() {
			if (!fileToDelete.value) {
				console.error('No file selected!');
				return;
			}
			console.log(`File removed: ${fileToDelete.value.name}`);
			// dropzone.removeFile(file);
		  try {
			  if (dropZoneElement.value && dropZoneElement.value.options && dropZoneElement.value.options.removedfile) {
				  dropZoneElement.value.options.removedfile(fileToDelete.value);
			  }
		  } catch (e) {
			//
		  }

	// 		GreeveApiFlow.deleteFlowMediaFile(props.flowProcessId);
			showConfirmDeleteFileDialog.value = false;
			fileToDelete.value = null;
			emit('removedFile', fileToDelete.value);
		}

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		function removedFileEvent(file: DropzoneFile, dropzone: Dropzone) {
			fileToDelete.value = file;
			showConfirmDeleteFileDialog.value = true;
		}

		async function initSelectedFlowProcess() {
			if (flows.value && props.flowProcess?.flow_id && flows.value.getItemById(props.flowProcess?.flow_id)) {
				const flow = flows.value.getItemById(props.flowProcess.flow_id);
				if (flow && flow.flow_processes && props.flowProcessUuid) {
					selectedFlowProcess.value = flow.flow_processes.getItemByUuId(props.flowProcessUuid);
				}
			} else {
				initFlows(true).then(() => {
					if (flows.value && props.flowProcess?.flow_id && flows.value.getItemById(props.flowProcess?.flow_id)) {
						const flow = flows.value.getItemById(props.flowProcess.flow_id);
						if (flow && flow.flow_processes && props.flowProcessUuid) {
							selectedFlowProcess.value = flow.flow_processes.getItemByUuId(props.flowProcessUuid);
						}
					} else {
						//TODO ERROR MESSAGE!
						console.log('ERROR');
					}
				});
			}
		}


		onMounted(() => {
			if (dropRef.value !== null) {
				let previewContainer: boolean | string | HTMLElement | undefined | any;
				if (dropRef.value.parentElement?.querySelector('.preview-container')) {
					previewContainer = dropRef.value.parentElement?.querySelector('.preview-container');
				}
				dropZoneElement.value = new Dropzone(dropRef.value, {
					previewTemplate: customPreview,
					url: '#',
					autoProcessQueue: false,
					maxFiles: props.maxFiles, // Allow multiple files
					acceptedFiles: props.acceptedFiles,
					maxFilesize: props.maxFileSize,
					timeout: props.timeout,
					uploadMultiple: props.uploadMultipleFiles,
					createImageThumbnails: props.createImageThumbnails,
					thumbnailWidth: props.thumbnailWidth,
					thumbnailHeight: props.thumbnailHeight,
					clickable: props.fileClickable,
					dictDefaultMessage: '',
					previewsContainer: previewContainer,
					init: function() {
						if (this.options) {
							initSelectedFlowProcess().then(() => {
								if (selectedFlowProcess.value?.media_list) {
									selectedFlowProcess.value.media_list.forEach((media: FlowMedia) => {
										if (media.isUrlExpired() || !media.getTemporaryUrl()) {
											//TODO check
											// initFlows(true).then(() => {
											// 	const mockFile: any = {name: media.name, size: media.size, type: media.mime_type};
											// 	this.options.addedfile?.call(this, mockFile);
											// 	this.options.thumbnail?.call(this, mockFile, 'https://placehold.co/600x400');
											// 	mockFile.previewElement.classList.add('dz-success');
											// 	mockFile.previewElement.classList.add('dz-complete');
											// 	addRemoveButtonToFile(mockFile, this);
											// })
										} else {
											const mockFile: DropzoneFile | any = {
												name: media.name,
												size: media.size,
												type: media.mime_type,
												dataURL: media.getTemporaryUrl() ?? '',
											};
											this.options.addedfile?.call(this, mockFile);
											// this.options.thumbnail?.call(this, mockFile, media.getTemporaryUrl() ?? '');
											mockFile.previewElement.classList.add('dz-success');
											mockFile.previewElement.classList.add('dz-complete');
											addRemoveButtonToFile(mockFile, this);
										}
									});
								}
								//TODO use media files from selectedFlowProcess

							});
						}

						this.on('addedfile', file => {
							console.log('A file has been added');
							fileAddedEvent(file, this);
						});
					},
				});

				const dropRefInnerHtml: any = dropRef.value.querySelector('.dz-default');
				if (dropRef.value && dropRefInnerHtml) {
					//TODO add overlay on drag and drop
					dropRefInnerHtml.innerHTML = `
            <div style="display: flex; justify-content: center;">
              <svg xmlns="http://www.w3.org/2000/svg"  class="w-[3em] md:w-[7em] mt-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
</svg>

            </div>
            <p style="text-align: center; margin: 0;"><strong>${useTranslation().t('nodeDragDrop')}</strong></p>
            <p style="text-align: center; margin-top: 0;"><small style="color: #999;">${useTranslation().
					t('nodeDragDropDetail')}</small></p>
 <div class="center my-4 mb-6" style="display: flex; justify-content: center;">
<button class="rounded-xl bg-gray-200 dark:bg-gr-darker px-4 py-2 text-gray-500 dark:text-gray-200 font-bold" type="button" >${useTranslation().
					t('nodeDragDropButton')}</button>
                      </div>
`;
				}
			}
		});

		return {
			icons,
			t,
			dropRef,
			isLoading,
		  	fileToDelete,
			showConfirmDeleteFileDialog,
			deleteFile,
			selectedFlowProcess,
		};
	},
});
