
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'InstagramPage',
	components: {
	},
	setup() {
		return {
		};
	},
});
