
import {defineComponent} from 'vue';
import InfoCard from '@/components/modal/InfoCard.vue';
import SecurityManagement from "@/components/shop/profile/SecurityManagement.vue";

export default defineComponent({
	name: 'SecurityPage',
	components: {
		InfoCard,
		SecurityManagement,
	},
	setup() {
		return {};
	},
});

