
import {defineComponent, onMounted, reactive, ref, toRef, watch} from 'vue';
import useTranslation from '@/composable/translation/useTranslation';
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {XMarkIcon, ExclamationTriangleIcon} from '@heroicons/vue/24/outline';
import InputField from '@/components/inputs/InputField.vue';

export interface ScriptInterface {
	src: string;
	type?: string;
	arguments?: any;
	content?: string;
}

export default defineComponent({
	name: 'ConfirmWithInputFieldDialog',
	components: {
		InputField,
		XMarkIcon, ExclamationTriangleIcon,
		Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
	},
	props: {
		title: {type: String},
		description: {type: String},
		inputValue: {
			type: String,
			required: true
		},
		inputPlaceholder: {
			type: String,
		},
		confirmButtonName: {type: String, default: 'Confirm'},
		icon: {type: Object, default: ExclamationTriangleIcon},
		submitOnEnter: {type: Boolean, default: true},
		showIcon: {type: Boolean, default: false},
		cancelButtonName: {type: String, default: 'Cancel'},
		visible: {type: Boolean, default: false},
		type: {type: String, default: 'alert'},
		allowBodyDismiss: {
			type: Boolean,
			default: true,
		},
		showDismissButton: {
			type: Boolean,
			default: true,
		},
		showLoadingAnimationOnConfirm: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['confirm', 'cancelConfirm'],
	setup(props, {emit}) {
		const dialogTitle = toRef(props, 'title');
		const dialogDescription = toRef(props, 'description');
		const visible = toRef(props, 'visible');
		const open = ref(visible.value);
		const {t} = useTranslation();
		const newValue = reactive({
			value: '',
		});

		function toggleDialog() {
			open.value = !open.value;
		}

		const cancelDialogOutsideClick = () => {
			if (props.allowBodyDismiss) {
				open.value = false;
				emit('cancelConfirm');
			}
		};

		const cancelDialog = () => {
			open.value = false;
			emit('cancelConfirm');
		};

		function confirm() {
			emit('confirm', newValue.value);
		}

		watch(() => props.visible, (newValue: boolean) => {
			open.value = newValue;
		});

		watch(() => props.inputValue, (newInputValue: string) => {
			newValue.value = newInputValue;
		});

		onMounted(() => {
			//
		});

		return {
			dialogTitle,
			dialogDescription,
			open,
			toggleDialog,
			confirm,
			cancelDialog,
			cancelDialogOutsideClick,
			newValue,
			t,
		};
	},
});
