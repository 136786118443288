
import {ref, onMounted, defineComponent, onBeforeMount} from 'vue';
import useShop from '@/composable/greeve/useShop';
import {Cart} from '@/greeve/shop/cart/cart.type';
import useCountry from '@/composable/core/useCountry';
import DefaultButton from '@/components/core/DefaultButton.vue';
import router from '@/router';
import {Order} from '@/greeve/shop/order/order.type';

export default defineComponent({
	name: 'ThankYouProcess',
	components: {DefaultButton},
	props: {
		order: {
			type: Order,
			default: undefined,
		},
	},
	emits: ['onSuccess', 'onError'],
	setup(props, {emit}) {
		const {cart, resetCart} = useShop();
		const {getCountry_by_Code} = useCountry();
		const successCart: Cart | any = ref();

		function redirectToFlow() {
			router.push('/workflow');
		}

		function onSuccess() {
			//TODO save to cart customer address
			emit('onSuccess');
		}

		function onError(message = '', inlineError = false) {
			emit('onError', message, null, false, inlineError);
		}

		onBeforeMount(() => {
			successCart.value = Object.create(cart.value);
			if (!cart.value || (successCart.value && !cart.value.checkoutSession)) {
				router.push('/workflow');
			} else {
				resetCart();
			}
		});

		onMounted(() => {
			//todo load setup and check total amount etc...
		});

		return {
			onSuccess, onError, successCart, getCountry_by_Code, redirectToFlow,
		};
	},
});
