
import {defineComponent, ref, computed} from 'vue';

export default defineComponent({
	name: 'DefaultOutputElement',
	props: {
		content: {
			type: String,
			default: '',
		},
	},
	setup(props) {
		const maxContentLength = 4000;
		const isTruncated = computed(() => props.content.length > maxContentLength);
		const truncatedContent = computed(() => props.content.slice(0, maxContentLength));
		// const fullContent = ref(props.content);
		const collapsed = ref(true);

		const displayedContent = computed(() => (collapsed.value ? truncatedContent.value : props.content));
		// const displayedContent = computed(() => (collapsed.value ? truncatedContent.value : fullContent.value));

		const toggleCollapse = () => {
			collapsed.value = !collapsed.value;
		};

		// watch(() => props.content, (newContent, oldValue) => {
		// 	if (newContent !== oldValue) {
		// 		fullContent.value = newContent;
		// 	}
		// });

		return {
			isTruncated,
			collapsed,
			displayedContent,
			toggleCollapse,
		};
	},
});
