
import {defineComponent, ref} from 'vue';
import {IonIcon} from '@ionic/vue';
import * as icons from 'ionicons/icons';
import useSearch from "@/composable/greeve/useSearch";
import useTranslation from "@/composable/translation/useTranslation";

export default defineComponent({
	name: 'CategoryInput',
	props: {},
	components: {IonIcon},
	emits: ['clickCategory'],
	setup(props, {emit}) {
		const {t} = useTranslation();
		const selectedType = ref('');
		const {getGreeveSearchType} = useSearch();

		function clickCategory(category: string)
		//TODO select catetgory
		{
			emit('clickCategory', category);
		}

		return {t, icons, selectedType, clickCategory, getGreeveSearchType}
	}
})
