
import {defineComponent} from 'vue';
import InfoCard from '@/components/modal/InfoCard.vue';
import SubscriptionManagement from "@/components/shop/profile/SubscriptionManagement.vue";

export default defineComponent({
	name: 'PlanPage',
	components: {
		InfoCard,
		SubscriptionManagement,
	},
	setup() {
		return {}
	},
});

