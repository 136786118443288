
import {defineComponent, onMounted, ref} from 'vue'
import useUser from "@/composable/greeve/useUser";
import useToastMessage from "@/composable/core/useToastMessage";
import {loadingController} from "@ionic/vue";
import useSystem from "@/composable/core/useSystem";
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth";
import {AuthenticationError} from "@/greeve/api/auth";
import useTranslation from "@/composable/translation/useTranslation";
import {ApiOAuthInterface} from '@/greeve/user/oauth/api_oauth.interface';
import useAlert from '@/composable/core/useAlert';
import {OAuthType} from '@/greeve/user/oauth/oauth.interface';

export default defineComponent({
	name: 'GoogleLoginComponent',
	props: {
		provider: {
			type: String,
			default: "google"
		},
		redirectAfterSubmit: {
			type: Boolean,
			default: false
		},
		isLoginButton: {
			type: Boolean,
			default: false
		}
	},
	emits: ['loginSuccess', 'loginError'],
	setup(props, {emit}) {
		const {t} = useTranslation();
		const {addOAuth} = useUser();
		const {openToast} = useToastMessage();
		const {isNativePlatform} = useSystem();
		const loadingTimeout = ref(50000);
		const {addSuccess} = useAlert();
		const loading = ref();

		async function presentLoading() {
			loading.value = await loadingController
				.create({
					cssClass: '',
					//TODO add Translations
					message: 'Bitte warten... du wirst eingeloggt!',
					duration: loadingTimeout.value,
				});

			await loading.value.present();

			setTimeout(function () {
				loading.value.dismiss()
			}, loadingTimeout.value);
		}

		function getImageUrlByType(): string
		{
			return 'https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/socialmedia/google.png';
		}

		function getNameByType(): string
		{
			return 'Google';
		}

		function getScopes() {
			return [
				'email',
				'https://www.googleapis.com/auth/spreadsheets',
				'https://www.googleapis.com/auth/drive',
				'https://www.googleapis.com/auth/calendar',
				'https://mail.google.com'];
		}

		async function initializeGoogle() {
			if (!isNativePlatform()) {
				GoogleAuth.initialize({
					clientId: '69235563995-ueslt7oft0bdarilpluehh0l9f7198l4.apps.googleusercontent.com',
					scopes: getScopes(),
					grantOfflineAccess: true,
				});
			}
		}

		onMounted(() => {
			initializeGoogle();
		})

		async function handleGoogleLoginError(error: any) {
			try {
				if (error instanceof AuthenticationError) {
					if (error.message.includes('Provider Data Error')) {
						await openToast(t("login.UserNotAllowed"), 'danger', 'top', true, 10000, undefined, true);
					} else {
						await openToast(t("login.error"), 'danger', 'top', true, 5000, undefined, true);
					}
				} else {
					if (error.error == "popup_closed_by_user") {
						await openToast(t("login.error"), 'warning', 'top', true, 5000, undefined, true);
					} else {
						await openToast(t("login.error"), 'danger', 'top', true, 5000, undefined, true);
					}
				}
			} catch (error) {
				await openToast(t("login.errorLogin"), 'danger', 'top', true, 5000, undefined, true);
			} finally {
				emit('loginError');
			}
		}

		async function signIn(): Promise<void> {
			try {
				const response = await GoogleAuth.signIn();
				await presentLoading();
				console.log("RESS", response)
				if (response) {
					const data = {
						isBusiness: false,
						authResponse: response,
						scopes: getScopes(),
					};

					const oauthData: ApiOAuthInterface = {
						data: data,
						name: response.givenName + ' ' + response.familyName,
						reference: response.id,
						refresh_token: response.authentication.refreshToken?.length === 0 && response.serverAuthCode ? response.serverAuthCode : response.authentication.refreshToken,
						token: response.authentication.accessToken,
						type: OAuthType.OAUTH_TYPE_GOOGLE,
					};

					addNewOAuthToProfile(oauthData).then(() => {
						setTimeout(() => {
							loading.value.dismiss();
							clearTimeout(loadingTimeout.value);
						}, 1000)
					})

					// const authToken = await GreeveApiAuth.signIn(result);
					// if (authToken) {
					// 	setName(result.name);
					// 	setGivenName(result.givenName);
					// 	setEmail(result.email);
					// 	setProfileImage(result.imageUrl);
					// 	if (props.redirectAfterSubmit) {
					// 		await router.push('/profile');
					// 	}
					// 	emit('loginSuccess');
					// }
				} else {
					await openToast(t("login.errorLogin"), 'danger', 'top', true, 5000);
				}
			} catch (error) {
				console.log(error);
				await handleGoogleLoginError(error);
			}
		}

		async function addNewOAuthToProfile(data: ApiOAuthInterface)
		{
			addOAuth(data).then(() => {
				const success = {
					id: "",
					title: "New OAuth Account successfully connected!",
					message: "",
					active: true,
				}
				addSuccess(success);
			});
		}

		return {t, signIn, getImageUrlByType, getNameByType}
	},
})
