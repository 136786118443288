
import {defineComponent, ref} from 'vue';
import 'aos/dist/aos.css';
import useTranslation from '@/composable/translation/useTranslation';
import {CheckCircleIcon, XMarkIcon, XCircleIcon} from '@heroicons/vue/20/solid';
import useCustomStore from '@/composable/custom/useCustomStore';
import useContact from '@/composable/greeve/useContact';

export const enum FeatureTypes {
	WORKFLOW_FEATURE = 'feature_workflow',
}

export interface FEATURE_NOTIFY_FORM_INTERFACE {
	type: FeatureTypes;
	email: string;
	language: string;
}

export default defineComponent({
	name: 'NotifyFeatureComponent',
	components: {CheckCircleIcon, XMarkIcon, XCircleIcon},
	props: {
		featureType: {
			type: Object as () => FeatureTypes,
			default: FeatureTypes.WORKFLOW_FEATURE,
		},
		title: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		showEmailNotifyButton: {
			type: Boolean,
			default: true,
		},
	},
	methods: {},
	setup(props) {
		const {t} = useTranslation();
		const {getLanguageLocale} = useCustomStore();
		const {sentNotifyFeature} = useContact();

		const notifyFormData = ref<FEATURE_NOTIFY_FORM_INTERFACE>({
			'type': props.featureType,
			'email': '',
			'language': '',
		});
		const successMessage = ref('');
		const errorMessage = ref('');

		function scrollToAlertMessage() {
			const id = 'contact-container';
			const yOffset = -200;
			const element = document.getElementById(id);
			if (element) {
				const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
				window.scrollTo({top: y, behavior: 'smooth'});
			}
		}

		function resetNotifyFormData() {
			notifyFormData.value = {
				'type': props.featureType,
				'email': '',
				'language': '',
			};
		}

		function sendNotifyForm() {
			successMessage.value = '';
			errorMessage.value = '';
			notifyFormData.value.language = getLanguageLocale.value;
			sentNotifyFeature(notifyFormData.value).then(() => {
						successMessage.value = t("contact.FormSuccess");
						resetNotifyFormData();
						scrollToAlertMessage();
					},
					error => {
						console.log(error);
						errorMessage.value = t("contact.FormError");
						scrollToAlertMessage();
					});
		}

		return {t, notifyFormData, successMessage, errorMessage, sendNotifyForm};
	},
});

