
import {onMounted, defineComponent} from 'vue';
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue'
import useCurrency from "@/composable/core/useCurrency";
import useShop from "@/composable/greeve/useShop";
import {Currency} from "@/greeve/core/country/currency.type";
import {toUpperCase} from "uri-js/dist/esnext/util";

export default defineComponent({
	name: "CheckoutNavCountry",
	methods: {toUpperCase},
	components: {Popover, PopoverButton, PopoverPanel},
	props: {
		checkoutLink: {
			type: String,
			default: ''
		},
		viewShoppingBagLink: {
			type: String,
			default: ''
		},
		popoverPosition: {
			type: String,
			default: 'absolute inset-x-0 left-auto top-14 lg:top-10 right-28 lg:right-1 -mr-1.5'
		},
		popoverSize: {
			type: String,
			default: ' w-80'
		},
	},
	emits: [],
	setup() {
		const {cart, setCurrency} = useShop();
		const {activeCurrency} = useCurrency();

		function selectCurrency(currency: Currency) {
			console.log("test currency", currency);
			setCurrency(currency.code);
		}

		onMounted(() => {
			// const faqList: FaqList|any[] = [...this];
			// return faqList.filter((faq) => {
			// 	return faq.language === language
			// });
			// setActiveStepByActiveStep(props.currentStep);
		})

		return {
			cart,
			activeCurrency,
			selectCurrency
		};
	}
})
