
import {defineComponent, onMounted, ref} from 'vue';
import {IonContent, IonPage} from '@ionic/vue';
import FooterBar from '@/components/positions/FooterBar.vue';
import FaqSection from '@/components/sections/FaqSection.vue';
import { onBeforeRouteLeave } from 'vue-router'
import BackgroundCarousel from "@/components/animations/BackgroundCarousel.vue";
import {useHeaderHeight} from "@/composable/global/useHeaderHeight";
import TemplateGrid from "@/components/workflow/template/TemplateGrid.vue";

export default defineComponent({
	name: 'ShopProducts',
	components: {
		TemplateGrid,
		BackgroundCarousel,
		FaqSection, FooterBar, IonContent, IonPage,
	},
	setup() {
		const { headerHeight } = useHeaderHeight();
		const videoRefGreeveAd2: HTMLVideoElement | any = ref(null)
		const videoRefTalk: HTMLVideoElement | any = ref(null)
		const videoRefPresentation: HTMLVideoElement | any = ref(null)
		const videoRefSphere: HTMLVideoElement | any = ref(null)
		const videoRefWoman: HTMLVideoElement | any = ref(null)

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const playVideos = () => {
			videoRefGreeveAd2.value.play();
			videoRefTalk.value.play();
			videoRefPresentation.value.play();
			videoRefSphere.value.play();
			videoRefWoman.value.play();
		}

		const stopVideos = () => {
			// video.currentTime = 0
			videoRefGreeveAd2.value.pause();
			videoRefTalk.value.pause();
			videoRefPresentation.value.pause();
			videoRefSphere.value.pause();
			videoRefWoman.value.pause();
		}

		onBeforeRouteLeave(() => {
			stopVideos()
		})

		onMounted(() => {
			//
		})

		return {
			videoRefGreeveAd2,
			videoRefTalk,
			videoRefPresentation,
			videoRefSphere,
			videoRefWoman, headerHeight
		};
	},
});

