
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'LoadingAnimation',
	props: {
		show: { type: Boolean, default: true },
		size: { type: String, default: '150px' }, // Default size
		loadingText: { type: String, default: 'Loading, please wait...' },
		color: { type: String, default: '#ff5e00' },
	},
	computed: {
		spinnerSize() {
			return this.size;
		},
	},
});
