
import {ref, defineComponent, onBeforeMount} from 'vue';
import useTranslation from '@/composable/core/useTranslation';
import useOrder from '@/composable/greeve/useOrder';
import {GreeveApiProfile} from '@/greeve/api/profile';
import useUser from '@/composable/greeve/useUser';
import {UserAddress} from '@/greeve/user/address/user_address.type';
import useAlert from '@/composable/core/useAlert';
import useCountry from '@/composable/core/useCountry';
import UseUserFactory from '@/composable/greeve/useUserFactory';
import StripeBillingPortalButton from '@/components/shop/profile/Stripe/StripeBillingPortalButton.vue';

export default defineComponent({
	name: 'SubscriptionManagement',
	components: {
		StripeBillingPortalButton,
	},
	props: {},
	emits: [],
	setup() {
		const {getBillingAddress, initBillingAddress} = useUser();
		const {getUserAddressFactory} = UseUserFactory();
		const {orders, initOrders} = useOrder();
		const {countries} = useCountry();
		const isLoading = ref(false);
		const billingAddress: UserAddress|any = ref();
		const {addSuccess, addError} = useAlert();
		const {t} = useTranslation();

		async function updateBillingAddress() {
			//TODO loading message
			try {
				isLoading.value = true;
				if (!billingAddress.value) {
					addError({title: t('auth.auth_error_failed_title'), message: t('auth.failed'), active: true});
					return;
				}
				console.log(billingAddress.value);
				await GreeveApiProfile.updateBillingAddress(billingAddress.value);
				const success = {
					id: '',
					title: 'Your Billing Address has been updated!',
					message: '', //TODO
					active: true,
				};
				addSuccess(success);
				initBillingAddress(true);
			} catch (e) {
				//TODO define error message
				addError({title: t('auth.auth_error_failed_title'), message: t('auth.failed'), active: true});
			} finally {
				isLoading.value = false;
			}
		}

		onBeforeMount(() => {
			try {
				initBillingAddress(true).then((response) => {
					if (response) {
						billingAddress.value = getUserAddressFactory().createUserAddressByResponse(getBillingAddress.value);
					}
				});
				initOrders(true); //TODO make an api endpoint to check if something changed !!!
			} catch (e) {
				console.error(e);
			}
		});

		return {
			orders,
			billingAddress,
			updateBillingAddress,
			countries,
		};
	},
});
