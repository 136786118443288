
import {defineComponent, ref} from 'vue';
import useTranslation from '@/composable/translation/useTranslation';
import {ChatBubbleBottomCenterTextIcon, MicrophoneIcon, PhotoIcon, SignalIcon} from '@heroicons/vue/20/solid';
import {ChatType} from '@/views/assistant/Chat/ChatPage.vue';
import {Switch} from '@headlessui/vue';
import {AudioVoice} from '@/greeve/search/item/type/subtype/search_item_text_to_speech.type';
import {ImageSize} from '@/greeve/search/item/type/subtype/search_item_image.type';
import useAssistant from '@/composable/greeve/useAssistant';
import ListSelectDropdown from '@/components/core/ListSelectDropdown.vue';
import { StopIcon, PlayIcon } from '@heroicons/vue/20/solid'

interface OutputMethod {
	name: string;
	type: ChatType;
	icon: any; // Need further details to provide the exact type;
	active: boolean;
}

export default defineComponent({
	name: 'OutputTag',
	components: {ListSelectDropdown, PhotoIcon, MicrophoneIcon, ChatBubbleBottomCenterTextIcon, Switch, StopIcon, PlayIcon},
	props: {
		selectedType: {
			type: String as () => ChatType,
			default: () => ChatType.CHAT,
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['updateSelectedType',],
	setup(props, {emit}) {

		const {t} = useTranslation();
		const {getAudioVoices, getAudioVoiceDemo, getImageSizes} = useAssistant();
		const hd_image = ref(false);
		const selectedAudio = ref<AudioVoice|undefined>()
		const imageSize = ref<ImageSize|undefined>()
		const isAudioVoiceDemoPlaying = ref(false);
		const audio = ref<HTMLAudioElement | null>(null);

		const outputMethode = ref<OutputMethod[]>([
			{name: t('chat.modes.chat'), type: ChatType.CHAT, icon: ChatBubbleBottomCenterTextIcon, active: true},
			{name: t('chat.modes.image'), type: ChatType.IMAGE, icon: PhotoIcon, active: false},
			{name: t('chat.modes.text_to_speech'), type: ChatType.TEXT_TO_SPEECH, icon: SignalIcon, active: false},
		]);

		function getCurrentActiveItem() {
			return outputMethode.value.find(item => item.active);
		}

		function updateImage() {
			const activeItem = getCurrentActiveItem();
			if (activeItem) {
				emit('updateSelectedType', activeItem.type, imageSize.value, hd_image.value, selectedAudio.value);
			}
		}

		function updateImageSize(model: any, key: any, value: ImageSize) {
			const activeItem = getCurrentActiveItem();
			imageSize.value = value;
			if (activeItem) {
				emit('updateSelectedType', activeItem.type, imageSize.value, hd_image.value, selectedAudio.value);
			}
		}

		function updateAudioVoice(model: any, key: any, value: AudioVoice) {
			const activeItem = getCurrentActiveItem();
			if (value !== selectedAudio.value && isAudioVoiceDemoPlaying) {
				pauseVoiceDemo();
			}
			selectedAudio.value = value;
			if (activeItem) {
				emit('updateSelectedType', activeItem.type, imageSize.value, hd_image.value, selectedAudio.value);
			}
		}

		function playVoiceDemo() {
			if (selectedAudio.value) {
				isAudioVoiceDemoPlaying.value = true;
				const audioVoiceDemoUrl = getAudioVoiceDemo(selectedAudio.value);
				audio.value = new Audio(audioVoiceDemoUrl);
				audio.value.addEventListener('ended', () => {
					isAudioVoiceDemoPlaying.value = false;
				});
				audio.value.play()
				.then(() => {
					console.log(`Audio playback started: ${audioVoiceDemoUrl}`);
				})
				.catch((error) => {
					console.error(`Error playing audio: ${error}`);
				});
			}
		}

		function pauseVoiceDemo() {
			if (selectedAudio.value && isAudioVoiceDemoPlaying.value && audio.value) {
				isAudioVoiceDemoPlaying.value = false;
				audio.value.pause();
			}
		}

		function selectType(selectedItem: OutputMethod) {
			outputMethode.value.forEach((method) => {
				if (method.type === selectedItem.type) {
					method.active = true;
					emit('updateSelectedType', selectedItem.type, imageSize.value, hd_image.value, selectedAudio.value);
				} else {
					method.active = false;
				}
			});
		}

		return {
			outputMethode, t, selectType, hd_image, getCurrentActiveItem, updateImage, getImageSizes, imageSize, updateImageSize, getAudioVoices, selectedAudio, updateAudioVoice, getAudioVoiceDemo, pauseVoiceDemo, playVoiceDemo, isAudioVoiceDemoPlaying
		};
	},
});

