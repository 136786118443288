
import {ref, onMounted, defineComponent} from 'vue';
import StripeAddress from '@/components/shop/checkout/process/stripe/StripeAddress.vue';
import useShop from '@/composable/greeve/useShop';
import useAlert from '@/composable/core/useAlert';
import DefaultButton from '@/components/core/DefaultButton.vue';
import LoadingLineAnimation from '@/components/animations/LoadingLineAnimation.vue';

export default defineComponent({
	name: 'ContactInformationProcess',
	components: {LoadingLineAnimation, DefaultButton, StripeAddress},
	props: {},
	emits: ['onSuccess', 'onError'],
	setup(props, {emit}) {
		const {updateCustomerAddressByStripeAddress, cart} = useShop();
		const {addError, clearErrorAlert} = useAlert();
		const address = ref();
		const saveAddress = ref(false);
		const isAddressValid = ref(false);
		const isLoading = ref(true);

		function updateContactAddress(addressData: any) {
			if (addressData) {
				address.value = addressData;
			}
			validateAddress();
		}

		function stopLoading() {
			isLoading.value = false;
		}

		function validateAddress(): void {
			if (address.value && address.value.line1.length > 0) {
				isAddressValid.value = true;
			} else {
				isAddressValid.value = false;
			}
		}

		function onSuccess() {
			clearErrorAlert();
			if (isAddressValid.value) {
				try {
					updateCustomerAddressByStripeAddress(address.value, saveAddress.value);
					goToNextStep();
				} catch (error: any) {
					onError(error);
				}
			} else {
				const error = {
					id: '',
					title: 'Error',
					message: 'Invalid Contact Informations',
					active: true,
				};
				addError(error);
			}
		}

		function goToNextStep() {
			emit('onSuccess');
		}

		function onError(message = '', inlineError = false) {
			emit('onError', message, null, false, inlineError);
		}

		onMounted(() => {
			isLoading.value = true;
			if (cart.value && cart.value.customer?.address) {
				saveAddress.value = cart.value.customer.address.getSaveAddressOption();
			}
		});

		return {
			address, updateContactAddress, onSuccess, onError, isAddressValid, saveAddress, goToNextStep, stopLoading, isLoading
		};
	},
});
