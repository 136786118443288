
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import * as icons from 'ionicons/icons';
import useSystem from '@/composable/core/useSystem';
import useTranslation from '@/composable/translation/useTranslation';
import VoiceAction from '@/components/actions/VoiceAction.vue';
import {IonIcon, IonTextarea} from '@ionic/vue';
import {Popover, PopoverButton, PopoverPanel, PopoverOverlay, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {PhotoIcon, DocumentIcon, MicrophoneIcon, DocumentTextIcon, PaperClipIcon} from '@heroicons/vue/20/solid';
import ProAdDialog from '@/components/modal/ProAdDialog.vue';
import ConfirmDialog from '@/components/modal/ConfirmDialog.vue';
import {useVModel} from '@/composable/input/useVModel';
import useCustomStore from '@/composable/custom/useCustomStore';
import {GreeveSearchItemSubTypeInterface} from '@/greeve/search/item/search_item.interface';

interface WrapProp {
	wrap: 'hard' | 'soft' | 'off';
}

export const enum InputMode {
	DEFAULT = 'default',
	UPLOAD = 'upload',
}

export default defineComponent({
	name: 'TextInput',
	components: {
		ConfirmDialog,
		ProAdDialog,
		TransitionRoot,
		TransitionChild,
		Popover,
		PaperClipIcon,
		VoiceAction,
		IonIcon,
		IonTextarea,
		PopoverOverlay,
		PopoverButton,
		PopoverPanel,
		PhotoIcon,
		DocumentIcon,
		MicrophoneIcon,
		DocumentTextIcon,
	},
	emits: ['onEnter', 'onClear', 'onChangeInput', 'update:searchDefaultValue', 'createUploadPrompt'],
	props: {
		searchDefaultValue: {
			type: String,
			default: '',
		},
		proFeature: {
			type: Boolean,
			default: false,
		},
		uploadModeActive: {
			type: Boolean,
			default: true,
		},
		enableOnBlurAction: {
			type: Boolean,
			default: false,
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
		activateWebSpeech: {
			type: Boolean,
			default: true,
		},
		autoGrow: {
			type: Boolean,
			default: true,
		},
		maxRows: {
			type: Number,
			default: 10,
		},
		wrap: {
			type: String as () => WrapProp['wrap'],
			default: 'soft',
		},
		mode: {
			type: Object as () => InputMode,
			default: InputMode.DEFAULT,
		},
	},
	setup(props, {emit}) {
		const showProAdDialog = ref(false);
		const textInputState = useVModel(props, 'searchDefaultValue');
		// const textInputState = ref(props.searchDefaultValue);
		const {t} = useTranslation();
		const {setIsFooterVisible} = useCustomStore();
		const {isIosOrAndroid} = useSystem();
		let timerId: number | null = null;

		const textareaRef = ref<any>(null);

		const isMobileDevice = computed(() => {
			return useSystem().isMobileDevice();
		})

		// const computedMaxRows = computed(() => {
		// 	const lineCount = textInputState.value.split('\n').length;
		// 	return lineCount > 1 ? lineCount + 1 : 1;
		// });

		// const autoGrowArea = computed(() => {
		// 	if (!props.autoGrow) {
		// 		return false;
		// 	}
		//
		// 	return computedMaxRows.value >= props
		// })

		const computedMaxRows = computed(() => {
			if (!textareaRef.value) return 1;

			const lineCount = textInputState.value.split('\n').length;
			const maxRows = 8;

			// Setzen Sie maxRows auf lineCount + 1, jedoch maximal auf 8
			return Math.min(lineCount + 1, maxRows);
		});

		// const computedMaxRows = computed(() => {
		// 	if (!textareaRef.value) return 1;
		//
		// 	const lineHeight = parseInt(getComputedStyle(textareaRef.value).lineHeight, 10) || 20;
		// 	const currentHeight = textareaRef.value.scrollHeight;
		//
		// 	return Math.max(Math.ceil(currentHeight / lineHeight), 1);
		// });

		const inputMethode = ref([
			{name: t('inputType1'), type: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE, icon: PhotoIcon, active: true, pro: false, coming_soon: false},
			{name: t('inputType4'), type: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT, icon: MicrophoneIcon, active: true, pro: false, coming_soon: false},
			{name: t('inputType3'), type: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_DOCUMENT, icon: DocumentIcon, active: false, pro: true, coming_soon: true},
		]);

		function getComputedMaxRows(): number
		{
			// return 10;
			return computedMaxRows.value;
		}

		function updateTextAreaValueByVoice(value: string) {
			if (value) {
				emit('onChangeInput', value);
			}
		}

		function searchOnEnter(event: Event | any) {
			event.preventDefault();
			const textarea: HTMLTextAreaElement = event.target;
			if (event.shiftKey) {
				addNewLine(textarea);
			} else {
				if (isIosOrAndroid()) {
					if (event.key === 'Enter') {
						addNewLine(textarea);
						return;
					}
				}
				search(event);
			}
		}

		function addNewLine(textarea: HTMLTextAreaElement) {
			const startPos = textarea.selectionStart;
			const endPos = textarea.selectionEnd;
			const value = textarea.value;
			const beforeCursor = value.substring(0, startPos);
			const afterCursor = value.substring(endPos, value.length);
			textarea.value = beforeCursor + '\n' + afterCursor;
			textarea.selectionStart = textarea.selectionEnd = startPos + 1;
		}

		function search(event: Event | undefined = undefined) {
			if (event && isIosOrAndroid()) {
				const element: HTMLInputElement | any = event.target;
				if (element) {
					element.blur();
				}
			}
			emit('onEnter', props.searchDefaultValue);
		}

		function blurSearchInput(event: Event) {
			if (props.enableOnBlurAction) {
				const element: HTMLInputElement | any = event.target;
				if (element) {
					element.blur();
				}
				search(event);
			}
		}

		function createUploadPrompt(subType: GreeveSearchItemSubTypeInterface, close: any) {
			emit('createUploadPrompt', subType);
			close();
		}

		function clearSearch(event: any) {
			event.target.focus();
			emit('onClear');
		}

		const placeholder = ref('');

		//TODO: change Suggestions based on selected Output Type
		const displayTextArray = [
			t('suggestions.text.Type1'),
			t('suggestions.text.Type2'),
			t('suggestions.text.Type3'),
			t('suggestions.text.Type4'),
			t('suggestions.text.Type5'),
			t('suggestions.text.Type6'),
			t('suggestions.text.Type7'),
			t('suggestions.text.Type8'),
		];
		// displayTextArray.value = [
		//	t("suggestions.image.Type1"),
		//	t("suggestions.image.Type2"),
		//	t("suggestions.image.Type3"),
		//	t("suggestions.image.Type4"),
		//	t("suggestions.image.Type5"),
		//	t("suggestions.image.Type6"),
		//	t("suggestions.image.Type7"),
		//	t("suggestions.image.Type8"),
		// ];
		// displayTextArray.value = [
		//	t("suggestions.audio.Type1"),
		//	t("suggestions.audio.Type2"),
		//	t("suggestions.audio.Type3"),
		//	t("suggestions.audio.Type4"),
		//	t("suggestions.audio.Type5"),
		//	t("suggestions.audio.Type6"),
		//	t("suggestions.audio.Type7"),
		//	t("suggestions.audio.Type8"),
		// ];
		const typingSpeed = 100;
		const erasingSpeed = 100;
		const newTextDelay = 200;
		const charIndex = ref(0);
		const displayTextArrayIndex = ref(0);

		watch(
				() => props.searchDefaultValue,
				(newVal) => {
					if (newVal) {
						timerId && clearTimeout(timerId);
						timerId = null;
					} else if (timerId === null) {
						timerId = setTimeout(typeText, newTextDelay + 200);
					}
				},
		);

		const typeText = () => {
			if (charIndex.value < displayTextArray[displayTextArrayIndex.value].length) {
				placeholder.value += displayTextArray[displayTextArrayIndex.value].charAt(charIndex.value);
				charIndex.value += 1;
				timerId = setTimeout(typeText, typingSpeed);
			} else {
				timerId = setTimeout(eraseText, newTextDelay);
			}
		};

		const eraseText = () => {
			if (charIndex.value > 0) {
				placeholder.value = displayTextArray[displayTextArrayIndex.value].substring(0, charIndex.value - 1);
				charIndex.value -= 1;
				timerId = setTimeout(eraseText, erasingSpeed);
			} else {
				displayTextArrayIndex.value += 1;
				if (displayTextArrayIndex.value >= displayTextArray.length) displayTextArrayIndex.value = 0;
				timerId = setTimeout(typeText, typingSpeed + 1000);
			}
		};

		onMounted(() => {
			timerId = setTimeout(typeText, newTextDelay + 200);
		});

		watch(() => props.searchDefaultValue, (newValue) => {
			textInputState.value = newValue;
		});

		return {
			placeholder, showProAdDialog, t, icons, inputMethode, search, clearSearch, blurSearchInput,
			updateTextAreaValueByVoice, searchOnEnter, textInputState, computedMaxRows, textareaRef, setIsFooterVisible, getComputedMaxRows,
			createUploadPrompt, isMobileDevice
		};
	},
});

