
import {defineComponent, onMounted, ref} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import {CreditCardIcon, KeyIcon, UserCircleIcon, UserGroupIcon} from '@heroicons/vue/24/outline'
import LoginRegisterProcess from "@/components/shop/checkout/process/LoginRegisterProcess.vue";
import useSystem from '@/composable/core/useSystem';
import {useHeaderHeight} from "@/composable/global/useHeaderHeight";
import router from '@/router';
import useCustomStore from '@/composable/custom/useCustomStore';
import { useRoute } from 'vue-router';
import useAuth from '@/composable/auth/useAuth';

const LOGIN_SEGMENT = 'login';
const REGISTER_SEGMENT = 'register';

export default defineComponent({
	name: 'AuthenticationPage',
	components: {
		LoginRegisterProcess,
		FooterBar,
		IonPage,
		IonContent
	},
	setup() {
		const { headerHeight } = useHeaderHeight();
		const {isAuthenticated} = useAuth();
		const selectedNavigation = ref('account');
		const navigation = ref([
			{name: 'Account', type: 'account', href: '#', icon: UserCircleIcon, active: true},
			{name: 'Security', type: 'security', href: '#', icon: KeyIcon, active: true},
			{name: 'Plan & Billing', type: 'plan_billing', href: '#', icon: CreditCardIcon, active: true},
			{name: 'Team', type: 'team', href: '#', icon: UserGroupIcon, active: true},]);
		const {addLeadingSlash, getQueryByKey} = useSystem();
		const {setIsGlobalLoading} = useCustomStore();
		const defaultSegment = ref(LOGIN_SEGMENT);
		const route = useRoute();

		function navigationClick(type: string) {
			selectedNavigation.value = type;
		}

		function redirectToNextPage() {
			setIsGlobalLoading(true);
			const redirectQuery = getQueryByKey('redirect');
			if (redirectQuery) {
				setTimeout(() => {
					router.push(addLeadingSlash(redirectQuery));
					setIsGlobalLoading(false);
				}, 500)
			} else {
				router.push('/profile').then(() => {
					setTimeout(() => {
						setIsGlobalLoading(false);
					}, 300)
				});
			}
		}

		onMounted(() => {
			if (isAuthenticated.value) {
				const redirectQuery = getQueryByKey('redirect');
				if (redirectQuery) {
					setTimeout(() => {
						router.push(addLeadingSlash(redirectQuery));
						setIsGlobalLoading(false);
					}, 100)
				}
			} else if (route.path === '/register') {
				defaultSegment.value = REGISTER_SEGMENT;
			}
		})

		return {selectedNavigation, navigation, navigationClick, redirectToNextPage, headerHeight, defaultSegment}
	}
});

