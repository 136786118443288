
import {defineComponent, ref} from 'vue';
import {XMarkIcon} from '@heroicons/vue/24/outline';
import {IonContent, IonModal, IonButton, useBackButton} from '@ionic/vue';
import {SearchItemImageVision} from '@/greeve/search/item/type/subtype/search_item_image_vision.type';
import DefaultOutputElement from '@/components/assistant/Output/Elements/DefaultOutputElement.vue';

export default defineComponent({
	name: 'ImageVisionRequestImageElement',
	components: {DefaultOutputElement, IonContent, IonModal, IonButton, XMarkIcon},
	props: {
		searchItem: {
			type: Object as () => SearchItemImageVision,
		},
	},
	emits: [],
	setup(props) {
		const showModal = ref(false);
		const modalImage = ref('');

		const openModal = (imageUrl: string) => {
			if (!imageUrl) return;
			modalImage.value = imageUrl;
			showModal.value = true;
		};

		const closeModal = () => {
			showModal.value = false;
		};

		const getImages = (): Array<string>|any => {
			if (!props.searchItem) {
				return [];
			}
			return [props.searchItem.getOriginalImage()];
		};

		useBackButton(10, () => {
			closeModal();
		});

		return {
			showModal,
			modalImage,
			openModal,
			closeModal,
			getImages
		};
	}
});

