
import {defineComponent, onMounted, ref} from 'vue';
import {nanoid} from 'nanoid';
import DangerAlert from '@/components/alert/Base/DangerAlert.vue';
import CheckoutNavBar from '@/components/shop/checkout/nav/CheckoutNavBar.vue';
import LoginRegisterProcess from '@/components/shop/checkout/process/LoginRegisterProcess.vue';
import ContactInformationProcess from '@/components/shop/checkout/process/ContactInformationProcess.vue';
import OrderInformation from '@/components/shop/checkout/cart/OrderInformation.vue';
import useShop, {COMPLETED_STEP, UPCOMING_STEP, CURRENT_STEP} from '@/composable/greeve/useShop';
import {StepInterface} from '@/greeve/shop/checkout/steps/step.interface';
import {CheckoutSteps} from '@/greeve/shop/checkout/steps/steps.type';
import useAuth from '@/composable/auth/useAuth';
import OrderConfirmProcess from '@/components/shop/checkout/process/OrderConfirmProcess.vue';

export default defineComponent({
	name: "GreeveShopCheckout",
	components: {
		OrderConfirmProcess,
		OrderInformation,
		ContactInformationProcess,
		LoginRegisterProcess,
		CheckoutNavBar,
		DangerAlert,
	},
	props: {
		id: {type: String, default: nanoid()},
		title: {type: String},
		description: {type: String},
		visible: {type: Boolean, default: false},
	},
	emits: ['closeCheckout'],
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setup(props, {emit}) {
		const showAd = ref(false);
		const showHeader = ref(true);
		const hasErrors: any = ref(false);
		const errorMessage = ref('');
		const errorTitle = ref('');
		const {isAuthenticated} = useAuth();
		const isLoading = ref(false);
		const {
			checkoutSteps,
			updateStepStateById,
			setCheckoutSteps,
			activeStepId,
			cart,
			initCustomerByUser,
		} = useShop();

		function resetCheckout() {
			setCheckoutSteps(new CheckoutSteps());
		}

		function getSelectedStep(): StepInterface | undefined {
			return checkoutSteps.value.steps.find(o => o.id === activeStepId.value);
		}

		function isAllowedToGoBack(selectedStep: StepInterface | undefined = undefined) {
			let result = false;
			if (!selectedStep) {
				selectedStep = getSelectedStep();
			}
			if (selectedStep) {
				result = selectedStep.allowedToGoBack;
			}
			return result;
		}

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		function cancelCheckout() {
			//TODO rollback
			resetCheckout();
			emit('closeCheckout');
		}

		function changeStep(step: any) {
			if (step) {
				const oldStep = checkoutSteps.value.steps.find(({id}) => id === activeStepId.value);
				const newStep = checkoutSteps.value.steps.find(({id}) => id === step);

				if (newStep && oldStep && newStep.id === oldStep.id) {
					return;
				} else if (!newStep && !oldStep) {
					return;
				}
				if (oldStep) {
					let oldStepStatus;
					if (oldStep.id < step) {
						oldStepStatus = COMPLETED_STEP;
					} else {
						oldStepStatus = UPCOMING_STEP;
					}
					if (oldStep && oldStep.status !== oldStepStatus) {
						updateStepStateById(oldStep.id, oldStepStatus);
					}
				}

				if (newStep && ((!oldStep || step >= oldStep.id) || isAllowedToGoBack(oldStep))) {
					updateStepStateById(newStep.id, CURRENT_STEP);
				}
			}
		}

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		function goToNextStep(checkoutData: any | null) {
			hasErrors.value = false;
			errorMessage.value = '';
			if (activeStepId.value <= 2) {
				changeStep(activeStepId.value + 1);
			}
		}

		function goBack() {
			errorMessage.value = '';
			if (activeStepId.value > 1) {
				changeStep(activeStepId.value - 1);
			}
		}

		function showError(errMsg: string, errorCode?: number, shouldGoOneStepBack = false, ignoreMessage = false) {
			hasErrors.value = true;
			if (!ignoreMessage) {
				errorMessage.value = errMsg;
			}
			if (shouldGoOneStepBack) {
				goBack();
			}
		}

		//TODO check url and change activeStepId if allowed

		onMounted(() => {
			setCheckoutSteps(checkoutSteps.value);
			if (activeStepId.value === 1 && isAuthenticated.value) {
				if (!cart.value.customer) {
					initCustomerByUser();
				}
				changeStep(2);
			} else if (activeStepId.value === 2 && isAuthenticated.value && cart.value.isAddressValidated()) { //TODO check has cart address
				changeStep(3);
			}
		});

		return {
			activeStepId,
			checkoutSteps,
			showAd,
			showHeader,
			errorMessage,
			errorTitle,
			goToNextStep,
			showError,
			changeStep,
			hasErrors,
			isAuthenticated,
			isLoading,
		}
	}
})
