
import { defineComponent, ref } from 'vue';
import ThreeColumnLayout from '@/components/core/ThreeColumnLayout.vue';
import ChatPage from "@/views/assistant/Chat/ChatPage.vue";

export default defineComponent({
	name: 'WhatsAppPage',
	components: {
		ChatPage,
		ThreeColumnLayout,
	},
	setup() {
		const users = ref([
			{
				id: 1,
				name: 'Luis1994',
				message: 'Pick me at 9:00 Am',
				image: 'https://source.unsplash.com/_7LbC5J-jw4/600x600',
				active: false,
			},
			{
				id: 2,
				name: 'Everest Trip 2021',
				message: 'Hi Sam, Welcome',
				image: 'https://source.unsplash.com/otT2199XwI8/600x600',
				active: false,
			},
			{
				id: 3,
				name: 'MERN Stack',
				message: 'Lusi : Thanks Everyone',
				image: 'https://source.unsplash.com/L2cxSuKWbpo/600x600',
				active: true,
			},
			{
				id: 4,
				name: 'Javascript Indonesia',
				message: 'Evan : some one can fix this',
				image: 'https://source.unsplash.com/vpOeXr5wmR4/600x600',
				active: false,
			},
		]);

		const messages = ref([
			{
				text: 'Welcome to group everyone !',
				sent: true,
			},
			{
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat at praesentium, aut ullam delectus odio error sit rem. Architecto nulla doloribus laborum illo rem enim dolor odio saepe, consequatur quas?',
				sent: false,
			},
			{
				text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam, repudiandae.',
				sent: true,
			},
			{
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, reiciendis!',
				sent: true,
			},
			{
				text: 'happy holiday guys!',
				sent: false,
			},
		]);

		const newMessage = ref('');

		const sendMessage = () => {
			if (newMessage.value.trim() !== '') {
				messages.value.push({
					text: newMessage.value,
					sent: true,
				});
				newMessage.value = '';
			}
		};

		return {
			users,
			messages,
			newMessage,
			sendMessage,
		};
	},
});
