
import {ref, defineComponent, onMounted} from 'vue';
import useUser from '@/composable/greeve/useUser';
import {GreeveApiProfile, UpdateUserDataName} from '@/greeve/api/profile';
import useAlert from '@/composable/core/useAlert';
import useTranslation from '@/composable/translation/useTranslation';
import useSystem from '@/composable/core/useSystem';
import ConfirmDialog from '@/components/modal/ConfirmDialog.vue';
import OAuthList from '@/components/shop/profile/OAuth/OAuthList.vue';
import LanguageSelector from '@/components/settings/LanguageSelector.vue';

export default defineComponent({
	name: 'AccountManagement',
	components: {LanguageSelector, OAuthList, ConfirmDialog},
	props: {},
	emits: [],
	setup() {
		const {t} = useTranslation();
		const {getProfile, getSubscriptions, initSubscriptions, getEmail} = useUser();
		const {addSuccess, addError, resetAllAlerts} = useAlert();
		const {downloadJsonFile, isFeatureFlagActive} = useSystem();

		const isDownloadingUserData = ref(false);
		const isLoading = ref(false);

		const showCancelSubscriptionModal = ref(false);

		const userInformation = ref({
			firstName: getProfile.value?.first_name ?? '',
			lastName: getProfile.value?.last_name ?? '',
			email: getEmail.value ?? '',
		});

		async function updateUserInformation() {
			const userInformationData: UpdateUserDataName = {
				first_name: String(userInformation.value.firstName),
				last_name: String(userInformation.value.lastName),
			};
			try {
				resetAllAlerts();
				if (getProfile.value.first_name !== userInformationData.first_name || getProfile.value.last_name !==
						userInformationData.last_name) {
					await GreeveApiProfile.updateName(userInformationData);
				}
				const success = {
					id: '',
					title: 'Personal Information updated!',
					message: 'Your First and Lastname successfully updated',
					active: true,
				};
				addSuccess(success);
			} catch (e) {
				console.log(e);
				addError({title: t('auth.auth_error_failed_title'), message: t('auth.failed'), active: true});
			}
		}

		async function updateUserLanguage(language: string) {
			try {
				resetAllAlerts();
				if (language.length === 0) {
					addError({title: t('account.profile.language.updateErrorMessage.general'), message: '', active: true});
					return;
				}
				GreeveApiProfile.updateLanguage(language).then(() => {
					const success = {
						id: '',
						title: t('account.profile.language.update.title'),
						message: '',
						active: true,
					};
					addSuccess(success);
				});
			} catch (e) {
				console.log(e);
				addError({title: t('auth.auth_error_failed_title'), message: t('auth.failed'), active: true});
			}
		}

		async function downloadUserData() {
			//TODO loading message
			try {
				isDownloadingUserData.value = true;
				const userData = await GreeveApiProfile.downloadUserData();
				downloadJsonFile(userData, 'greeve_data_' + getProfile.value.last_name);
				const success = {
					id: '',
					title: 'Personal Information ready to download!',
					message: 'You can download your personal informations!',
					active: true,
				};
				addSuccess(success);
			} catch (e) {
				//TODO define error message
				addError({title: t('auth.auth_error_failed_title'), message: t('auth.failed'), active: true});
			} finally {
				isDownloadingUserData.value = false;
			}
		}

		async function cancelActiveSubscription(subscriptionName: string) {
			//TODO loading message
			try {
				isLoading.value = true;
				await GreeveApiProfile.cancelSubscriptions(subscriptionName);
				const success = {
					id: '',
					title: 'Your Subscription has been canceled!',
					message: 'TODO info it ends with date xyz', //TODO
					active: true,
				};
				addSuccess(success);
			} catch (e) {
				//TODO define error message
				addError({title: t('auth.auth_error_failed_title'), message: t('auth.failed'), active: true});
			} finally {
				showCancelSubscriptionModal.value = false;
				isLoading.value = false;
			}
		}

		onMounted(() => {
			resetAllAlerts();
			initSubscriptions();
			userInformation.value.firstName = getProfile.value?.first_name ?? '';
			userInformation.value.lastName = getProfile.value?.last_name ?? '';
		});

		return {
			userInformation,
			getProfile,
			getSubscriptions,
			updateUserInformation,
			updateUserLanguage,
			downloadUserData,
			isDownloadingUserData,
			cancelActiveSubscription,
			showCancelSubscriptionModal,
			isFeatureFlagActive,
		};
	},
});
