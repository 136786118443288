
import {defineComponent} from 'vue';
import LanguageSelector from "@/components/settings/LanguageSelector.vue";
import router from "@/router";
import useTranslation from "@/composable/translation/useTranslation";
import useSystem from "@/composable/core/useSystem";
import SocialMedia from "@/components/branding/SocialMedia.vue";

export default defineComponent({
	name: 'FooterBar',
	components: {SocialMedia, LanguageSelector},
	setup() {
		const {t} = useTranslation();
		const {appVersion} = useSystem();
		const navigation = {
			main: [
				{name: 'footer.donate', href: '/donate'},
				{name: 'footer.imprint', href: '/imprint'},
				{name: 'footer.press', href: '/press'},
				{name: 'footer.faqs', href: '/faq'},
				{name: 'footer.privacy', href: '/privacy'},
				{name: 'footer.terms', href: '/terms'},
				// { name: 'Pro', href: '/becomePro' },
			],
		}

		function redirectFooterByItem(link: string) {
			router.push(link);
		}

		return {t, navigation, redirectFooterByItem, appVersion}
	}
});

