import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-full pb-1 px-2 lg:px-4" }
const _hoisted_2 = { class: "px-2 sm:px-4 lg:gap-x-5 flex-none nav rounded-2xl backdrop-filter backdrop-blur-xl sticky top-[6.5rem]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notify_feature_component = _resolveComponent("notify-feature-component")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_notify_feature_component, {
                "feature-type": "feature_workflow",
                title: _ctx.$t('feature.workflow.title'),
                description: _ctx.$t('feature.workflow.description')
              }, null, 8, ["title", "description"])
            ])
          ]),
          _createVNode(_component_FooterBar, { class: "mt-12" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}