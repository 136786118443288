
import {defineComponent, onMounted} from 'vue';
import useShop from '@/composable/greeve/useShop';
import CheckoutPayButton from '@/components/shop/checkout/cart/CheckoutPayButton.vue';

export default defineComponent({
	name: "OrderConfirmProcess",
	components: {CheckoutPayButton},
	// components: { Disclosure, DisclosureButton, DisclosurePanel,LockClosedIcon},
	props: {},
	emits: ['onSuccess', 'onError'],
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setup(props, {emit}) {
		const {cart, getOneTimePriceType, getRecurringMonthlySubType, getRecurringYearlySubType} = useShop();

		function onSuccess() {
			//TODO save to cart customer address
			emit('onSuccess');
		}

		function onError(message = '', inlineError = false) {
			emit('onError', message, null, false, inlineError);
		}

		onMounted(() => {
			//todo load setup and check total amount etc...
		})

		return {
			onSuccess, onError,
			cart, getOneTimePriceType,getRecurringMonthlySubType, getRecurringYearlySubType
		};
	}
})
