import {ProfileInterface, UserInterface} from "@/greeve/user/user.interface";

export class User implements UserInterface {
	id: number;
	type: string;
	state: string;
	stripe_id?: string;
	name: string;
	email: string;
	email_verified_at?: Date;
	current_team_id?: number;
	avatarUrl?: string;
	profile?: ProfileInterface;
	data?: any;
	created_at?: Date;
	updated_at?: Date;


	constructor(id: number, type: string, state: string, name: string, email: string, emailVerifiedAt?: Date, current_team_id?: number|undefined, avatarUrl?: string|undefined, profile?: ProfileInterface|undefined, stripe_id?: string|undefined, data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.type = type;
		this.state = state;
		this.stripe_id = stripe_id;
		this.name = name;
		this.email = email;
		this.email_verified_at = emailVerifiedAt;
		this.current_team_id = current_team_id;
		this.avatarUrl = avatarUrl;
		this.profile = profile;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}
}