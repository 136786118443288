
import {defineComponent} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import HeaderLogo from "@/components/branding/HeaderLogo.vue";
import useTranslation from "@/composable/translation/useTranslation";
import SocialMedia from "@/components/branding/SocialMedia.vue";

export default defineComponent({
  name: 'PressPage',
  components: {
    SocialMedia,
    HeaderLogo,
    FooterBar, IonContent, IonPage},

  setup() {

    const {t} = useTranslation();
    return {t}  }
});

