import {computed, ComputedRef, ref} from 'vue';
import store from '@/store';
import {ProfileInterface, UserInterface} from '@/greeve/user/user.interface';
import {User} from '@/greeve/user/user.type';
import {GreeveApiProfile, UpdateUserDataName} from '@/greeve/api/profile';
import {
	SubscriptionList,
} from '@/greeve/shop/subscription/subscription_list.type';
import {UserAddress} from '@/greeve/user/address/user_address.type';
import {QuotaList} from '@/greeve/user/quota/quota_list.type';
import useAuth from '@/composable/auth/useAuth';
import {ApiOAuthInterface} from '@/greeve/user/oauth/api_oauth.interface';
import {GreeveApiOAuth} from '@/greeve/api/oauth';
import {OAuthList} from '@/greeve/user/oauth/oauth_list.type';
import {
	QuotaTransactionList
} from '@/greeve/user/quota/transaction/quota_transaction_list.type';

const {isAuthenticated} = useAuth();

export default function useUser() {
	const user: ComputedRef<User> = computed(() => store.getters['user/getUser']);
	const getName: ComputedRef<string> = computed(
			() => store.getters['user/getName']);
	const getGivenName: ComputedRef<string> = computed(
			() => store.getters['user/getGivenName']);
	const getEmail: ComputedRef<string> = computed(
			() => store.getters['user/getEmail']);
	const getProfileImage: ComputedRef<string> = computed(
			() => store.getters['user/getProfileImage']);
	const getUserAbbr: ComputedRef<string> = computed(
			() => store.getters['user/getAbbr']);
	const getProfile: ComputedRef<ProfileInterface> = computed(
			() => store.getters['user/getProfile']);
	const getSubscriptions: ComputedRef<SubscriptionList> = computed(
			() => store.getters['user/getSubscriptions']);
	const getBillingAddress: ComputedRef<UserAddress> = computed(
			() => store.getters['user/getBillingAddress']);
	const getLatestUserAddress: ComputedRef<UserAddress> = computed(
			() => store.getters['user/getLatestUserAddress']);
	const getQuotas: ComputedRef<QuotaList> = computed(
			() => store.getters['user/getQuotas']);
	const getOAuths: ComputedRef<OAuthList> = computed(
			() => store.getters['user/getOAuths']);

	// const getCustomer: ComputedRef<any> = computed(() => store.getters['user/getCustomer']);

	const isQuotaFetching = ref(false);
	const isQuotaTransactionFetching = ref(false);
	const isSubscriptionFetching = ref(false);

	function setName(name: string) {
		store.commit('user/name', name);
	}

	function setGivenName(name: string) {
		store.commit('user/name', name);
	}

	function setUserId(id: number) {
		store.commit('user/id', id);
	}

	function setEmail(email: string) {
		store.commit('user/email', email);
	}

	function setProfileImage(profileImage: string) {
		store.commit('user/profileImage', profileImage);
	}

	function setProfile(profile: ProfileInterface) {
		store.commit('user/profile', profile);
	}

	function setSubscriptions(subscriptionList: SubscriptionList) {
		store.commit('user/subscriptions', subscriptionList);
	}

	function setQuotas(quotaList: QuotaList) {
		store.commit('user/quotas', quotaList);
	}

	function setQuotaTransactions(quotaUuid: string, quotaTransactionList: QuotaTransactionList) {
		store.commit('user/quotaTransactions', {quotaUuid: quotaUuid, quotaTransactionList: quotaTransactionList});
	}

	function addQuotaTransactions(quotaUuid: string, quotaTransactionList: QuotaTransactionList) {
		store.commit('user/addQuotaTransactions', {quotaUuid: quotaUuid, quotaTransactionList: quotaTransactionList});
	}

	function setBillingAddress(billingAddress: UserAddress) {
		store.commit('user/billingAddress', billingAddress);
	}

	function setLatestUserAddress(latestUserAddress: UserAddress) {
		store.commit('user/latestUserAddress', latestUserAddress);
	}

	function setOAuhtProfiles(oauthProfileList: OAuthList) {
		store.commit('user/oauths', oauthProfileList);
	}

	function updateProfileName(userDataName: UpdateUserDataName) {
		store.commit('user/profileName', userDataName);
	}

	function updateProfileLanguage(language: string) {
		store.commit('user/profileLanguage', language);
	}

	async function getBillingPortalUrl() {
		const result = await GreeveApiProfile.getBillingPortalUrl();
		if (result) {
			return result;
		} else {
			console.log('Nothing found, ' + JSON.stringify(result));
			return false;
		}
	}

	function isSubscriptionUpdateToOld(): boolean
	{
		//TODO get date
		return true;
	}

	async function initSubscriptions(force = false) {
		if (!isAuthenticated.value) {
			return;
		}
		if (((getSubscriptions.value && getSubscriptions.value.length > 0) || isSubscriptionUpdateToOld()) && !force) {
			return;
		}
		if (isSubscriptionFetching.value) {
			return;
		}
		isSubscriptionFetching.value = true;
		return GreeveApiProfile.getSubscriptions().then((result) => {
			isSubscriptionFetching.value = false;
			if (result !== undefined) {
				setSubscriptions(result);
				return result;
			} else {
				throw new Error('Nothing found, ' + JSON.stringify(result));
			}
		});
	}

	async function initQuotas(force = false) {
		//TODO check every x seconds
		if (!isAuthenticated.value) {
			return;
		}

		if (getQuotas.value && getQuotas.value.length > 0 && !force) {
			return;
		}
		if (isQuotaFetching.value) {
			return;
		}
		isQuotaFetching.value = true;
		return GreeveApiProfile.getQuotas().then((result) => {
			isQuotaFetching.value = false;
			if (result) {
				setQuotas(result);
				return result;
			} else {
				throw new Error('Nothing found, ' + JSON.stringify(result));
			}
		});
	}

	async function getQuotaTransactions(quotaUuid: string, page = 1, addToExistingTransactions = false) {
		if (!isAuthenticated.value) {
			return;
		}

		if (isQuotaTransactionFetching.value) {
			return;
		}
		isQuotaTransactionFetching.value = true;
		try {
			return GreeveApiProfile.getQuotaTransactions(quotaUuid, page).then((result) => {
				isQuotaTransactionFetching.value = false;
				if (result) {
					if (addToExistingTransactions) {
						addQuotaTransactions(quotaUuid, result);
					} else {
						setQuotaTransactions(quotaUuid, result);
					}
					return result;
				} else {
					throw new Error('Nothing found, ' + JSON.stringify(result));
				}
			});
		} catch (e) {
			console.error(e);
			isQuotaTransactionFetching.value = false;
		}
	}

	async function initBillingAddress(force = false) {
		if (getSubscriptions.value && getSubscriptions.value.length > 0 && !force) {
			return;
		}
		const result = await GreeveApiProfile.getBillingAddress();
		if (result) {
			setBillingAddress(result);
		} else {
			console.log('Nothing found, ' + JSON.stringify(result));
			return false;
		}
	}

	async function initLastUserAddress() {
		const result = await GreeveApiProfile.getUserAddresses();
		if (result) {
			const lastUserAddressId = result.getLastId();
			if (!lastUserAddressId) {
				return false;
			}
			const userAddress = result.getUserAddress_by_Id(lastUserAddressId);
			if (!userAddress) {
				return false;
			}
			setLatestUserAddress(userAddress);
		} else {
			console.log('Nothing found, ' + JSON.stringify(result));
			return false;
		}
	}

	async function initOAuth()
	{
		try {
			return GreeveApiOAuth.getOAuthList().then((oauthList) =>
					{
						if (oauthList) {
							setOAuhtProfiles(oauthList);
							return oauthList;
						} else {
							throw new Error('Nothing found, ' + JSON.stringify(oauthList));
						}
					},
					error => {
						console.error(error);
						throw error;
					});
		} catch (error: Error | any) {
			console.error(error);
			throw error;
		} finally {
			//
		}
	}

	async function addOAuth(apiOAuthData: ApiOAuthInterface)
	{
		try {
			return GreeveApiOAuth.addOAuthProfile(apiOAuthData).then((oauthList) =>
					{
						if (oauthList) {
							setOAuhtProfiles(oauthList);
							return oauthList;
						} else {
							throw new Error('Nothing found, ' + JSON.stringify(oauthList));
						}
					},
					error => {
						console.error(error);
						throw error;
					});
		} catch (error: Error | any) {
			console.error(error);
			throw error;
		} finally {
			//
		}
	}

	async function deleteOAuth(uuid: string)
	{
		try {
			return GreeveApiOAuth.deleteOAuthProfile(uuid).then((oauthList) =>
					{
						if (oauthList) {
							setOAuhtProfiles(oauthList);
							return oauthList;
						} else {
							throw new Error('Nothing found, ' + JSON.stringify(oauthList));
						}
					},
					error => {
						console.error(error);
						throw error;
					});
		} catch (error: Error | any) {
			console.error(error);
			throw error;
		} finally {
			//
		}
	}

	function initUserByUserType(userResponse: UserInterface) {
		setUserId(userResponse.id);
		setEmail(userResponse.email);
		setName(userResponse.name ?? '');
		setProfileImage(userResponse.avatarUrl ?? '');
		if (userResponse.profile) {
			setProfile(userResponse.profile);
		}
	}

	function resetUserData() {
		setName('');
		setEmail('');
		setProfileImage('');
	}

	return {
		user,
		getName,
		getGivenName,
		getEmail,
		getProfileImage,
		getUserAbbr,
		getProfile,
		getSubscriptions,
		getQuotas,
		getBillingAddress,
		getLatestUserAddress,
		getBillingPortalUrl,
		getOAuths,
		setName,
		setGivenName,
		setEmail,
		setProfileImage,
		setProfile,
		setSubscriptions,
		setBillingAddress,
		setQuotas,
		setOAuhtProfiles,
		updateProfileName,
		updateProfileLanguage,
		resetUserData,
		initUserByUserType,
		initSubscriptions,
		initQuotas,
		initBillingAddress,
		initLastUserAddress,
		addOAuth,
		deleteOAuth,
		initOAuth,
		getQuotaTransactions,
	};
}
