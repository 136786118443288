
import {defineComponent, ref} from 'vue';
import {useVModel} from '@/composable/input/useVModel';
import {ExclamationCircleIcon} from '@heroicons/vue/20/solid'
import {IonInput} from '@ionic/vue';

export default defineComponent({
	name: 'InputNickMail',
	components: {ExclamationCircleIcon, IonInput},
	emits: ['update:email', 'update:password'],
	props: {
		email: String,
		inputType: {
			type: String,
			default: "email"
		},
		inputPlaceholder: {
			type: String,
			default: "you@example.com"
		},
		requiredField: {
			type: Boolean,
			default: true
		},
		errorMessage: {
			type: String,
			default: ""
		},
	},
	setup(props) {

		const errorMsg = ref(props.errorMessage);

		function hasError() {
			return errorMsg.value && errorMsg.value.length > 0;
		}

		return {
			emailState: useVModel(props, 'email'), ExclamationCircleIcon, hasError, errorMsg
		};
	}
})
