
import {defineComponent, HTMLAttributes, onMounted, ref, watch} from 'vue';
import {Popover, PopoverButton, PopoverGroup, PopoverPanel, TransitionRoot} from '@headlessui/vue';
import { UserCircleIcon} from '@heroicons/vue/24/outline';
import {CreditCardIcon, CubeIcon, ChatBubbleLeftIcon} from '@heroicons/vue/20/solid';
import {
	ClipboardDocumentListIcon,
	SwatchIcon,
	Bars3Icon,
	MicrophoneIcon,
	PhoneIcon,
	PlayIcon,
	MagnifyingGlassIcon,
	SparklesIcon,
	XMarkIcon,
} from '@heroicons/vue/20/solid';
import {IonButton, IonIcon, IonRippleEffect} from '@ionic/vue';
import HeaderLogo from '@/components/branding/HeaderLogo.vue';
import CheckoutNavCountry from '@/components/shop/checkout/nav/CheckoutNavCountry.vue';
import {useRoute} from 'vue-router';

interface LinkItem {
	name: string;
	icon: HTMLElement | HTMLAttributes | any;
	href: string;
	active: boolean;
	visible: boolean;
	onlyAuth?: boolean;
}

import CreditsNavCart from '@/components/shop/checkout/nav/CreditsNavCart.vue';
import useAuth from '@/composable/auth/useAuth';
import useTranslation from "@/composable/core/useTranslation";

export default defineComponent({
	name: 'NavbarPro',
	components: {
			TransitionRoot,
		ChatBubbleLeftIcon,
		CubeIcon,
		CreditsNavCart,
		CheckoutNavCountry,
		HeaderLogo,
		CreditCardIcon,
		SparklesIcon,
		PlayIcon,
		MagnifyingGlassIcon,
		PhoneIcon,
		MicrophoneIcon,
		ClipboardDocumentListIcon,
		Bars3Icon,
		XMarkIcon,
		UserCircleIcon,
		SwatchIcon,
		PopoverGroup,
		Popover,
		PopoverButton,
		PopoverPanel,
		IonIcon,
		IonButton,
		IonRippleEffect,
	},
	props: {
		link: {
			type: String,
			default: '',
		},
		headerGradient: {
			type: Boolean,
			default: false,
		},
		routeName: {
			type: String,
			default: '',
		},
		activeRouteList: {
			type: Array,
			default: () => [],
		},
	},
	setup(props) {
		const {t} = useTranslation();
		const {isAuthenticated} = useAuth();
		const route = useRoute();
		const redirectLink = ref(props.link);
		const activeRoute = ref();
		const popoverButton: any = ref(false);
		const popoverHover = ref(false as any);
		const popoverTimeout = ref(null as any);
		const navigation: Array<LinkItem> | any = ref([
			{
				name: t('Workflow'),
			  	icon: SparklesIcon,
			  	href: '/flow',
			  	active: false,
			  	visible: true
			},
			{
				name: t('aiEngine'),
				icon: ChatBubbleLeftIcon,
				href: '/assistant',
				active: false,
				visible: true,
			},
			{
				name: t('pricing'),
				icon: CreditCardIcon,
				href: '/pricing',
				active: false,
				visible: !isAuthenticated.value,
				onlyAuth: false,
			},
			{
				name: t("individual"),
				icon: CubeIcon,
				href: '/enterprise',
				active: false,
				visible: isAuthenticated.value,
				onlyAuth: true,
			},
		]);
		const navigationGroup = ref([
			{name: t("service"), icon: SwatchIcon, href: '/services', active: false},
		]);

		const callsToAction = [
			{name: t("watchDemo"), nameShort: t("watchDemoShort"), href: '/workflow', icon: PlayIcon},
			{name: t("viewAllProducts"), nameShort: t("viewAllProductsShort"), href: '/services', icon: SwatchIcon},
			{name: t("contactSales"), nameShort: t("contactSalesShort"), href: '/support/contact', icon: PhoneIcon},
		];

		const ServiceLinks = [
			{
				name: t('GreeveWhisperSync'),
				icon: MicrophoneIcon,
				href: '/workflow/demo/whisper',
				description: t('VoiceInterviews'),
			},
			{
				name: t('GreeveAnamnesis'),
				icon: ClipboardDocumentListIcon,
				href: '/workflow/demo/anamnesis',
				description: t('AnamnesisAs'),
			},
			// {name: t('GreeveSeo'), icon: ClipboardDocumentListIcon, href: '#', description:  t('CreateSeo'),},
		];

		function isNavbarPopoverVisible(): boolean {
			const element = document.getElementById('navbar-popover');

			if (element) {
				const style = window.getComputedStyle(element);
				return style.display !== 'none' && style.visibility !== 'hidden';
			}

			return false;
		}

		function closeMenuAfterRedirect() {
			if (popoverButton.value) {
				try {
					popoverButton.value.el.click();
				} catch (e) {
					console.error(e);
				}
			} else if (popoverButton.value === null) {
				if (isNavbarPopoverVisible()) {
					popoverButton.value = false;
				}
			}
		}

		const hoverPopover = (e: any, open: any) => {
			popoverHover.value = true;
			if (!open) e.target.click();
		};

		function getVisibleNavigation() {
			return navigation.value.filter((nav: LinkItem) => {
				return nav.visible;
			});
		}

		function updateActiveRouteState(): void {
			let foundItem: any = null;
			if (props.activeRouteList && props.activeRouteList.length > 0) {
				const routeLink = props.activeRouteList.find((routePath) => routePath === route.path);
				foundItem = navigation.value.find((navItem: LinkItem) => navItem.href === routeLink);
			}

			if (!foundItem && navigation.value) {
				foundItem = navigation.value.find((navItem: LinkItem) => {
					let routeFirstPart = route.path.split('/')[1];
					return navItem.href.split('/')[1] === routeFirstPart;
				});
			}

			if (!foundItem && navigationGroup.value) {
				foundItem = navigationGroup.value.find((navGroupItem) => navGroupItem.href === route.path);
			}


			navigation.value.forEach((navItem: LinkItem) => {
				navItem.active = false;
			});
			navigationGroup.value.forEach((navItem) => {
				navItem.active = false;
			});

			if (foundItem) {
				foundItem.active = true;
			}
		}

		function isRouteActive(): boolean {
			if (props.routeName.length > 0 && !props.activeRouteList?.length) {
				return route.name === props.routeName;
			} else if (props.activeRouteList && props.activeRouteList.length > 0) {
				return props.activeRouteList.some((routePath) => routePath == route.path);
			} else if (navigation.value) {
				return navigation.value.some((navItem: LinkItem) => navItem.href == route.path);
			} else if (navigationGroup.value) {
				return navigationGroup.value.some((navGroupItem) => navGroupItem.href == route.path);
			} else {
				return route.path === props.link;
			}
		}

		const closePopover = (close: any) => {
			popoverHover.value = false;
			if (popoverTimeout.value) clearTimeout(popoverHover.value);
			popoverTimeout.value = setTimeout(() => {
				if (!popoverHover.value) close();
			}, 10);
		};

		onMounted(() => {
			updateActiveRouteState();
			activeRoute.value = isRouteActive();
		});

		// eslint-disable-next-line
		watch(route, (route, prevRoute) => {
			updateActiveRouteState();
			activeRoute.value = isRouteActive();
			closeMenuAfterRedirect();
		});

		watch(isAuthenticated, (newValue) => {
			navigation.value.forEach((item: LinkItem) => {
				if (item.onlyAuth && newValue) {
					item.visible = true;
				} else if (item.onlyAuth !== undefined && item.onlyAuth && !newValue) {
					item.visible = false;
				} else if (item.onlyAuth !== undefined && !item.onlyAuth && newValue) {
					item.visible = false;
				} else {
					item.visible = true;
				}
			});
		});

		return {
			getVisibleNavigation,
			callsToAction,
			navigation,
			navigationGroup,
			ServiceLinks,
			hoverPopover,
			closePopover,
			popoverHover,
			popoverTimeout,
			close,
			t,
			redirectLink,
			activeRoute,
			isAuthenticated,
			popoverButton,
		};
	},
});
