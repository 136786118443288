import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "box mt-[3.4em] lg:mt-[12.4em] max-w-4xl mx-auto" }
const _hoisted_2 = { class: "mx-2 box bg-white dark:bg-gr-dark justify-center rounded-xl mb-60 items-center sm:max-w-xl lg:w-4/5 sm:mx-auto" }
const _hoisted_3 = { class: "justify-center items-center sm:max-w-xl py-1 md:py-4 md:py-6 px-4 md:px-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginRegisterProcess = _resolveComponent("LoginRegisterProcess")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", {
            style: _normalizeStyle({ paddingTop: _ctx.headerHeight + 'px' })
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("form", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_LoginRegisterProcess, {
                      segment: _ctx.defaultSegment,
                      onOnSuccess: _ctx.redirectToNextPage,
                      "hide-next-step-button": true,
                      "hard-logout": true
                    }, null, 8, ["segment", "onOnSuccess"])
                  ])
                ])
              ])
            ])
          ], 4),
          _createVNode(_component_FooterBar, { class: "mt-12" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}