
import {defineComponent, onBeforeMount, onMounted, ref, Transition} from 'vue';
import useShop from '@/composable/greeve/useShop';
import useAuth from '@/composable/auth/useAuth';
import {CheckCircleIcon} from '@heroicons/vue/24/outline';
import {Product} from '@/greeve/shop/product/product.type';
import {MicrophoneIcon, CheckIcon, ChevronDownIcon} from '@heroicons/vue/20/solid';
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/vue';

export default defineComponent({
	name: 'GreeveShopProduct',
	components: {
		CheckCircleIcon,
		CheckIcon,
		ChevronDownIcon,
		Listbox,
		ListboxButton,
		ListboxOption,
		ListboxOptions,
		Transition,
	},
	props: {},
	emits: [],
	setup() {
		const {isAuthenticated} = useAuth();
		const isLoading = ref(false);
		const addToCartLoading = ref(false);
		const {initProducts, products, addProductToCart} = useShop();

		function addProductToCart_by_Product(product: Product) {
			addToCartLoading.value = true;
			addProductToCart(product.product_id);
			setTimeout(() => {
				addToCartLoading.value = false;
			}, 200);
			//TODO add product to cart;
		}

		onBeforeMount(() => {
			//
		});

		onMounted(() => {
			//TODO if products and created is older than 1 days reload
			initProducts(true).then(() => {
				isLoading.value = false;
			});
		});

		const ServiceLinks = [
			{
				name: 'Greeve Whisper Sync',
				icon: MicrophoneIcon,
				href: '/services/detail',
				description: 'Voice Interviews generated into Text, consider any dialect.',
				feature: {
					1: 'different languages',
					2: 'Student Help',
					3: 'easy Use',
				},
			},
		];

		const piece = [
			{id: 1, name: '5 min', points: '2 Credits'},
			{id: 2, name: '10 min', points: '4 Credits'},
			{id: 3, name: '40 min', points: '14 Credits'},
			{id: 4, name: '90 min', points: '30 Credits'},
		];

		const selected = ref(piece[1]);

		return {
			// ...toRefs(state),
			piece, selected,
			isAuthenticated,
			isLoading,
			addToCartLoading,
			products,
			addProductToCart_by_Product, ServiceLinks,
		};
	},
});
