
import {defineComponent} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import HeaderLogo from "@/components/branding/HeaderLogo.vue";
import useTranslation from "@/composable/translation/useTranslation";
import SocialMedia from "@/components/branding/SocialMedia.vue";
import PayPalDonation from "@/components/payments/donations/PayPalDonation.vue";
import KoFiDonation from "@/components/payments/donations/KoFiDonation.vue";

export default defineComponent({
  name: 'PressPage',
  components: {
	  KoFiDonation,
	  PayPalDonation,
    SocialMedia,
    HeaderLogo,
    FooterBar, IonContent, IonPage},

  setup() {

    const {t} = useTranslation();
    return {t}  }
});

