
import {defineComponent, onMounted, ref} from 'vue';
import {CheckCircleIcon} from '@heroicons/vue/24/outline';
import {CheckIcon, ChevronDownIcon} from '@heroicons/vue/20/solid';
import useFlow from '@/composable/greeve/useFlow';
import ConfirmDialog from '@/components/modal/ConfirmDialog.vue';
import {FlowTemplate} from '@/greeve/flow/type/template/flow_template.type';
import {AbstractFlow} from '@/greeve/flow/abstract_flow.type';
import useCustomStore from '@/composable/custom/useCustomStore';
import useAuth from '@/composable/auth/useAuth';
import useTranslation from '@/composable/translation/useTranslation';
import router from '@/router';
import {FlowList} from '@/greeve/flow/flow_list.type';

export default defineComponent({
	name: 'TemplateGrid',
	components: {
		ConfirmDialog,
		CheckCircleIcon,
		CheckIcon,
		ChevronDownIcon,
	},
	props: {
		background: {
			type: String,
			default: 'bg-gray-100 dark:bg-gr-dark hover:bg-gray-100 dark:hover:bg-gray-800'
		}
	},
	emits: [],
	setup() {
		const {flowTemplates, convertFlowTemplate} = useFlow();
		const {setIsGlobalLoading} = useCustomStore();
		const {t} = useTranslation();
		const {isAuthenticated} = useAuth();
		const showConfirmConvertTemplateDialog = ref(false);
		const showError = ref(false);
		const errorMessage = ref('');
		const errorTitle = ref('');
		const flowTemplatesForView = ref<FlowList|AbstractFlow[]|undefined>();
		const isLoading = ref(false);

		function clearErrorMessage() {
			errorTitle.value = '';
			errorMessage.value = '';
			showError.value = false;
		}

		function convertTemplateToFlow(flowTemplate: FlowTemplate) {
			if (!isAuthenticated.value) {
				errorTitle.value = t('login.unauthenticated.title');
				errorMessage.value = t('login.unauthenticated.description') + ' <a href="/auth" class="text-black underline">' + t('signIn') + '</a>';
				showError.value = true;
				showConfirmConvertTemplateDialog.value = false;
				return;
			}
			//TODO call api and redirect to flow with uuid from api show loading animation
			setIsGlobalLoading(true);
			const timeoutId = setTimeout(() => {
				setIsGlobalLoading(false);
			}, 10000);
			try {
				convertFlowTemplate(flowTemplate).then((result) => {
					if (result && result.uuid) {
						setTimeout(() => {
							router.push(result.getFlowLink()).then(() => {
								clearTimeout(timeoutId);
								setIsGlobalLoading(false);
							});
						}, 50);
					} else {
						errorTitle.value = t('toast.errorDefault');
						showError.value = true;
					}
				});
			} catch (e) {
				console.error(e);
				clearTimeout(timeoutId);
				setIsGlobalLoading(false);
			} finally {
				showConfirmConvertTemplateDialog.value = false;
			}
		}

		function getBlockQuoteForDialog_by_Template(flowTemplate: FlowTemplate | AbstractFlow) {
			return '<blockquote class="p-4 my-4 border-l-4 border-gray-300 bg-gray-50 dark:border-gray-500 dark:bg-gray-800">\n' +
					'    <p class="italic font-medium leading-relaxed text-gray-900 dark:text-white">' +
					flowTemplate.getTranslatedDescription() + '</p>\n' +
					'</blockquote>';
		}

		onMounted(() => {
			flowTemplatesForView.value = flowTemplates.value;
		});

		return {
			flowTemplatesForView,
			showConfirmConvertTemplateDialog,
			convertTemplateToFlow,
			getBlockQuoteForDialog_by_Template,
			clearErrorMessage,
			errorTitle,
			errorMessage,
			showError,
			isLoading,
		};
	},
});
