
import {ref, onMounted, defineComponent} from 'vue';
import useTranslation from '@/composable/translation/useTranslation';
import {ChevronUpIcon, XMarkIcon} from '@heroicons/vue/20/solid';
import {Popover, PopoverButton, PopoverOverlay, PopoverPanel, TransitionChild, TransitionRoot} from '@headlessui/vue';
import useShop from '@/composable/greeve/useShop';
import ErrorComponent from '@/components/errors/ErrorComponent.vue';
import * as icons from 'ionicons/icons';
import {IonIcon} from '@ionic/vue';
import CheckoutNavCart from '@/components/shop/checkout/nav/CheckoutNavCart.vue';
import CheckoutNavCountry from '@/components/shop/checkout/nav/CheckoutNavCountry.vue';
import ListSelectDropdown from '@/components/core/ListSelectDropdown.vue';
import {CartItem} from '@/greeve/shop/cart/item/cart_item.type';
import InputVoucherField from '@/components/inputs/InputVoucherField.vue';

export default defineComponent({
	name: 'OrderInformation',
	components: {
		InputVoucherField,
		ListSelectDropdown,
		CheckoutNavCountry,
		CheckoutNavCart,
		IonIcon,
		ErrorComponent,
		ChevronUpIcon,
		Popover,
		PopoverButton,
		PopoverOverlay,
		PopoverPanel,
		TransitionChild,
		TransitionRoot,
		XMarkIcon,
	},
	props: {},
	emits: [],
	setup() {
		const {t} = useTranslation();
		const {
			cancelCheckout,
			cart,
			removeCartItem_by_Id,
			addDiscountToCart,
			isDiscountValid,
			removeDiscountFromCart,
			changeCartItemCount,
			addProductToCart,
			getRecurringPriceType,
		} = useShop();

		const voucherCode = ref('');
		const isCheckingDiscount = ref(false);
		const discountErrorMessage = ref('');

		function updateCartItemCount(cartItem: CartItem, key: any, value: any) {
			changeCartItemCount(cartItem, value);
		}

		async function addDiscount() {
			//TODO
			discountErrorMessage.value = '';
			isCheckingDiscount.value = true;
			let isValid = false;
			if (voucherCode.value.length > 0) {
				try {
					if (await isDiscountValid(voucherCode.value)) {
						isValid = true;
						await addDiscountToCart(voucherCode.value);
					}
				} catch (e) {
					isValid = false;
				}
			}
			if (!isValid) {
				discountErrorMessage.value = t('error.invalid_discount');
				//Todo throw
			}
			isCheckingDiscount.value = false;
		}

		onMounted(() => {
			//
		});

		return {
			cancelCheckout,
			cart,
			removeCartItem_by_Id,
			icons,
			updateCartItemCount,
			addProductToCart,
			getRecurringPriceType,
			removeDiscountFromCart,
			addDiscount,
			voucherCode,
			discountErrorMessage,
			isCheckingDiscount,
		};
	},
});
