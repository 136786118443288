
import {defineComponent} from 'vue';
import useSystem from '@/composable/core/useSystem';
import {BookmarkIcon, ClipboardIcon, ArrowDownIcon} from '@heroicons/vue/24/outline';
import useTranslation from '@/composable/translation/useTranslation';
import {ChatType} from '@/views/assistant/Chat/ChatPage.vue';
import useToastMessage from '@/composable/core/useToastMessage';
import i18n from '@/i18n';

export default defineComponent({
	name: 'InteractionAction',
	props: {
		reference: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		dialogTitle: {
			type: String,
			//TODO add Translation
			default: 'Look at my greeve search result 😉',
		},
		shareImage: {
			type: String,
			default: '',
		},
		sharedParam: {
			type: String,
			default: 'shared', //if you want to use a group reference change the prop to groupShared
		},
		shareLink: {
			type: String,
			default: '',
		},
		shareMode: {
			type: String as () => ChatType,
			default: () => ChatType.CHAT,
		},
		isBookMarked: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		BookmarkIcon, ClipboardIcon, ArrowDownIcon,
	},
	emits: ['rate', 'bookMark'],
	setup(props, {emit}) {
		const {t} = useTranslation();
		const {copyContent, copyImageToClipboard, extractImageUrl_by_HtmlImage, downloadImage} = useSystem();

		function bookMarkAction() {
			emit('bookMark', !props.isBookMarked);
		}

		function copyContentToClipboard()
		{
			if (props.shareMode === ChatType.IMAGE) {
				copyImageToClipboard(props.description);
			} else {
				copyContent(props.description);
			}
		}

		function downloadImageByDescription()
		{
			const url = extractImageUrl_by_HtmlImage(props.description);
			if (url) {
				downloadImage(url);
			} else {
				useToastMessage().openToast(i18n.global.t("toast.errorDownloadImage"), 'danger', 'top', true, 10000, undefined, true)
			}
		}

		return {t, copyContentToClipboard, bookMarkAction, downloadImageByDescription};
	},
});
