
import {defineComponent, onMounted, ref} from 'vue';
import {IonIcon} from '@ionic/vue';
import * as icons from 'ionicons/icons';
import useTranslation from "@/composable/translation/useTranslation";
import useCustomStore from "@/composable/custom/useCustomStore";

export default defineComponent({
	name: 'VoiceAction',
	props: {
		lang: {
			type: String,
			default: '',
		},
		continuous: {
			type: Boolean,
			default: false,
		},
	},
	components: {IonIcon},
	emits: ['sendVoiceText', 'startVoiceRecognition', 'endVoiceRecognition'],
	setup(props, {emit}) {
		const {t} = useTranslation();
		const {getLanguageLocale} = useCustomStore();
		const isRecording = ref(false);
		const isAvailable = ref(true);
		const runtimeTranscription = ref('');
		const transcription: any = ref([]);
		const recognition: any = ref();

		async function toggleSpeaking() {
			if (isRecording.value) {
				stopRecognition();
			} else {
				startRecognition();
			}
		}

		function createRecognition() {
			window.SpeechRecognition =
				window.SpeechRecognition ||
				window.webkitSpeechRecognition;
			const recognition = new window.SpeechRecognition();
			recognition.lang = getLanguageLocale.value;
			recognition.interimResults = true;

			return recognition;
		}

		function initRecognition() {
			recognition.value = createRecognition();
			recognition.value.addEventListener("result", (event: any) => {
				const text = Array.from(event.results)
					.map((result:any) => result[0])
					.map(result => result.transcript)
					.join("");
				runtimeTranscription.value = text;
				emit('sendVoiceText', runtimeTranscription.value)
			});
			recognition.value.addEventListener("end", () => {
				transcription.value.push(runtimeTranscription.value);
				stopRecognition();
			});
		}

		function startRecognition() {
			recognition.value.start();
			emit('startVoiceRecognition');
			isRecording.value = true;
		}

		function stopRecognition() {
			recognition.value.stop();
			emit('endVoiceRecognition', runtimeTranscription.value);
			runtimeTranscription.value = "";
			isRecording.value = false;
		}

		onMounted(() => {
			initRecognition();
		})

		return {t, icons, isRecording, isAvailable, toggleSpeaking, runtimeTranscription}
	}
})
