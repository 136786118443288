import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "rounded-3xl px-3 font-comfortaa py-0 bg-black dark:bg-gray-400 font-bold text-white dark:text-black mt-0 float-right text-tc-bg text-xs sm:text-base sm:mr-1.5 -mt-1 sm:-mt-2" }
const _hoisted_2 = { class: "bg-tc-bg" }
const _hoisted_3 = { class: "mx-4 md:mx-7" }
const _hoisted_4 = { class: "mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:py-40 lg:px-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderLogo = _resolveComponent("HeaderLogo")!
  const _component_FaqSection = _resolveComponent("FaqSection")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_HeaderLogo, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('beta')), 1)
            ]),
            _: 1
          }),
          _createTextVNode(),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_FaqSection)
                ])
              ])
            ])
          ]),
          _createVNode(_component_FooterBar)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}