import useAssistantFactory from '@/composable/greeve/useAssistantFactory';
import {SearchGroupList} from '@/greeve/search/group/search_group_list.type';
import {SearchGroup} from '@/greeve/search/group/search_group.type';
import {
	AbstractSearchItem
} from '@/greeve/search/item/abstract_search_item.type';
import {SearchItemList} from '@/greeve/search/item/search_item_list.type';
import {
	GreeveSearchItemStateInterface, GreeveSearchItemSubTypeInterface,
} from '@/greeve/search/item/search_item.interface';
import {
	SearchItemUploadInteraction
} from '@/greeve/search/item/type/search_item_upload_interaction.type';
import {MediaFileInfo} from '@/greeve/media/media_file_info.type';

const {getSearchGroupFactory, getSearchItemFactory} = useAssistantFactory();

/* eslint-disable @typescript-eslint/no-unused-vars */

export interface SetSearchItem {
	searchGroupId: number,
	searchItem: AbstractSearchItem,
}

export interface UpdateSearchItem {
	searchItem: AbstractSearchItem,
	content: string,
}

export interface UpdateSearchItemState {
	searchItem: AbstractSearchItem,
	itemState: GreeveSearchItemStateInterface,
}

export interface UpdateSearchItemUploadInteractionMediaFileInfo {
	searchItem: SearchItemUploadInteraction,
	fileInfo: MediaFileInfo,
}

export interface UpdateSearchItemSubType {
	searchItem: SearchItemUploadInteraction,
	subType: GreeveSearchItemSubTypeInterface,
}

export interface UpdateSearchGroupName {
	searchGroupUuid: string,
	name: string,
}

export interface UpdateSearchItemDefault {
	defaultSearchItem: AbstractSearchItem,
	newSearchItem: AbstractSearchItem,
}

const assistant = {
	namespaced: true,
	state: () => ({
		searchGroups: SearchGroupList,
		lastSearchGroupUuid: "",
	}),
	mutations: {
		setSearchGroups(state: any, searchGroups: SearchGroupList) {
			state.searchGroups = searchGroups;
		},
		addSearchGroup(state: any, searchGroup: SearchGroup) {
			if (state.searchGroups) {
				const index = state.searchGroups.findIndex((group: SearchGroup) => {
					return group.uuid === searchGroup.uuid;
				});

				if (index !== -1) {
					state.searchGroups[index] = searchGroup;
				} else {
					state.searchGroups.push(searchGroup);
				}
			} else {
				if (!state.searchGroups || !(state.searchGroups instanceof SearchGroupList) ) {
					state.searchGroups = [];
					// state.searchGroups = getSearchGroupFactory().createEmptySearchGroupList();
				}
				state.searchGroups.push(searchGroup);
			}
		},
		updateSearchGroupName(state: any, {searchGroupUuid, name}: UpdateSearchGroupName) {
			if (!state.searchGroups) {
				return;
			}
			const index = state.searchGroups.findIndex((searchGroup: SearchGroup) => {
				return searchGroup.uuid === searchGroupUuid;
			});

			if (index !== -1) {
				state.searchGroups[index].name = name;
			}
		},
		setSearchItems(state: any, {searchGroupId, searchItemList}: any) {
			if (!state.searchGroups) {
				return;
			}
			const index = state.searchGroups.findIndex((searchGroup: SearchGroup) => {
				return searchGroup.id === searchGroupId;
			});

			if (index !== -1) {
				state.searchGroups[index].search_items = searchItemList;
			} else {
				console.error("GROUP " + searchGroupId + " not found!");
			}
		},
		setSearchItem(state: any, {searchGroupId, searchItem}: SetSearchItem) {
			if (!state.searchGroups) {
				return;
			}
			const index = state.searchGroups.findIndex((searchGroup: SearchGroup) => {
				return searchGroup.id === searchGroupId;
			});

			if (index !== -1) {
				if (!state.searchGroups[index].search_items) {
					state.searchGroups[index].search_items = getSearchItemFactory().createEmptySearchItemList(); //TODO check
				}

				const searchItemIndex = state.searchGroups[index].search_items.findIndex((searchItemInGroup: AbstractSearchItem) => {
					return searchItemInGroup.uuid === searchItem.uuid;
				});
				const newSearchItemList = state.searchGroups[index];
				if (newSearchItemList && searchItemIndex !== -1) {
					newSearchItemList.search_items[searchItemIndex] = searchItem;
					if (state.searchGroups) {
						state.searchGroups[index].search_items = newSearchItemList;
					} else {
						state.searchGroups = [];
						state.searchGroups.search_items.push(newSearchItemList);
					}
				}
			}
		},
		updateSearchItemResponse(state: any, {searchItem, content}: UpdateSearchItem) {
			if (!state.searchGroups) {
				return;
			}
			// Assuming searchItem.search_item_group_id or searchItem.search_group?.id is always defined
			const groupId = searchItem.search_item_group_id || searchItem.search_group?.id;

			// Find the group directly without iterating
			const searchGroup = state.searchGroups.find((group:SearchGroup) => group.id === groupId);
			// const searchGroupIndex = state.searchGroups.findIndex((group:SearchGroup) => group.id === groupId);
			if (!searchGroup) {
				return;
			}

			if (!searchGroup.search_items) {
				searchGroup.search_items = [];
			}

			// Find the search item index directly
			const itemIndex = searchGroup.search_items.findIndex((item:AbstractSearchItem) => item.uuid === searchItem.uuid);

			if (itemIndex === -1) {
				return;
			}

			const itemToUpdate: AbstractSearchItem = searchGroup.search_items[itemIndex];
			itemToUpdate.addContent(content);
			// searchItem.addContent(content);
		},
		updateSearchItemState(state: any, {searchItem, itemState}: UpdateSearchItemState) {
			if (!state.searchGroups) {
				return;
			}
			const index = state.searchGroups.findIndex((searchGroup: SearchGroup) => {
				const groupId = searchItem.search_item_group_id ? searchItem.search_item_group_id : searchItem.search_group?.id;
				return searchGroup.id === groupId;
			});

			if (index !== -1) {
				if (!state.searchGroups[index].search_items) {
					state.searchGroups[index].search_items = [];
				}

				const searchItemIndex = state.searchGroups[index].search_items.findIndex((searchItemInGroup: AbstractSearchItem) => {
					return searchItemInGroup.uuid === searchItem.uuid;
				});
				const newSearchItemList = state.searchGroups[index].search_items;
				if (newSearchItemList && searchItemIndex !== -1) {
					const newItem = searchItem;
					newItem.state = itemState;
					newSearchItemList[searchItemIndex] = newItem;
					state.searchGroups[index].search_items = newSearchItemList;
				}
			}
		},
		updateSearchItemUploadInteractionMediaFileInfo(state: any, {searchItem, fileInfo}: UpdateSearchItemUploadInteractionMediaFileInfo) {
			if (!state.searchGroups) {
				return;
			}
			const index = state.searchGroups.findIndex((searchGroup: SearchGroup) => {
				const groupId = searchItem.search_item_group_id ? searchItem.search_item_group_id : searchItem.search_group?.id;
				return searchGroup.id === groupId;
			});

			if (index !== -1) {
				if (!state.searchGroups[index].search_items) {
					state.searchGroups[index].search_items = [];
				}

				const searchItemIndex = state.searchGroups[index].search_items.findIndex((searchItemInGroup: AbstractSearchItem) => {
					return searchItemInGroup.uuid === searchItem.uuid;
				});
				const newSearchItemList = state.searchGroups[index].search_items;
				if (newSearchItemList && searchItemIndex !== -1) {
					const newItem = searchItem;
					newItem.mediaFileInfo = fileInfo;
					newSearchItemList[searchItemIndex] = newItem;
					state.searchGroups[index].search_items = newSearchItemList;
				}
			}
		},
		updateSearchItemSubType(state: any, {searchItem, subType}: UpdateSearchItemSubType) {
			if (!state.searchGroups) {
				return;
			}
			const index = state.searchGroups.findIndex((searchGroup: SearchGroup) => {
				const groupId = searchItem.search_item_group_id ? searchItem.search_item_group_id : searchItem.search_group?.id;
				return searchGroup.id === groupId;
			});

			if (index !== -1) {
				if (!state.searchGroups[index].search_items) {
					state.searchGroups[index].search_items = [];
				}

				const searchItemIndex = state.searchGroups[index].search_items.findIndex((searchItemInGroup: AbstractSearchItem) => {
					return searchItemInGroup.uuid === searchItem.uuid;
				});
				const newSearchItemList = state.searchGroups[index].search_items;
				if (newSearchItemList && searchItemIndex !== -1) {
					const newItem = searchItem;
					newItem.subType = subType;
					newSearchItemList[searchItemIndex] = newItem;
					state.searchGroups[index].search_items = newSearchItemList;
				}
			}
		},
		updateSearchItemDefaultByItem(state: any, {defaultSearchItem, newSearchItem}: UpdateSearchItemDefault) {
			if (!state.searchGroups) {
				return;
			}
			const index = state.searchGroups.findIndex((searchGroup: SearchGroup) => {
				const groupId = defaultSearchItem.search_item_group_id ? defaultSearchItem.search_item_group_id : defaultSearchItem.search_group?.id;
				return searchGroup.id === groupId;
			});

			if (index === -1) {
				return;
			}

			if (!state.searchGroups[index].search_items) {
				state.searchGroups[index].search_items = [];
			}

			const searchItemIndex = state.searchGroups[index].search_items.findIndex((searchItemInGroup: AbstractSearchItem) => {
				return searchItemInGroup.uuid === defaultSearchItem.uuid;
			});
			const newSearchItemList = state.searchGroups[index].search_items;
			if (newSearchItemList && searchItemIndex !== -1) {
				newSearchItemList[searchItemIndex] = newSearchItem;
				state.searchGroups[index].search_items = newSearchItemList;
			}
		},
		addSearchItem(state: any, searchItem: AbstractSearchItem) {
			if (!searchItem || !searchItem.uuid) {
				throw new Error('Invalid Item! - ' + JSON.stringify(searchItem));
			}

			const index = state.searchGroups.findIndex((searchGroup: SearchGroup) => {
				const groupId = searchItem.search_item_group_id ? searchItem.search_item_group_id : searchItem.search_group?.id;
				return searchGroup.id === groupId;
			});

			if (index !== -1) {
				const searchItems = state.searchGroups[index].search_items;

				// Ensure search_items is an array or convert it to a plain array
				if (!(searchItems instanceof SearchItemList)) {
					state.searchGroups[index].search_items = new SearchItemList(Array.isArray(searchItems) ? searchItems : []);
				}

				// Add the new search item
				state.searchGroups[index].search_items.add(searchItem);
			}
		},
		setLastSearchGroupUuid(state: any, lastSearchGroupUuid: string) {
			state.lastSearchGroupUuid = lastSearchGroupUuid;
		},
		addSearchGroupToList(state: any, searchGroup: SearchGroup) {
			if (!Array.isArray(state.searchGroups)) {
				state.searchGroups = [];
			}
			if (!searchGroup || !searchGroup.id) {
				throw new Error('Invalid Group!');
			}
			if (!(state.searchGroups instanceof SearchGroupList)) {
				if (!Array.isArray(state.searchGroups)) {
					state.searchGroups = [];
				}
				state.searchGroups.push(searchGroup);
			} else {
				state.searchGroups.push(searchGroup);
			}
			// state.searchGroups.recalculate = true;
		},
		removeSearchGroupFromList(state: any, searchGroup_Id: number) {
			if (!state.searchGroups) {
				state.searchGroups = [];
			}
			const index = state.searchGroups.findIndex((searchGroup: SearchGroup) => {
				return searchGroup.id === searchGroup_Id;
			});

			if (index !== -1) {
				state.searchGroups.splice(index, 1);
				// state.searchGroups.recalculate = true;
			}
		},
		removeSearchGroupByUuidFromList(state: any, searchGroup_Uuid: string) {
			if (!state.searchGroups) {
				state.searchGroups = [];
			}
			const searchGroupIndex = state.searchGroups.findIndex((searchGroup: SearchGroup) => {
				return searchGroup.uuid === searchGroup_Uuid;
			});

			if (searchGroupIndex !== -1) {
				const searchGroups = state.searchGroups;
				if (Array.isArray(searchGroups)) {
						if (searchGroups instanceof SearchGroupList) {
							const arrayWithoutProxy = [...searchGroups]; // Create a shallow copy
							arrayWithoutProxy.splice(searchGroupIndex, 1); // Remove the item
							state.searchGroups = arrayWithoutProxy; // Set the modified array back to the state
						} else {
							searchGroups.splice(searchGroupIndex, 1);
							state.searchGroup = [...searchGroups]; // Ensure we replace the array with a new one
						}
				} else {
					console.error("search groups is not an array:", searchGroups);
				}
			}
		},
		removeSearchItemFromList(state: any, searchItem: AbstractSearchItem) {
			if (!searchItem || !searchItem.uuid) {
				throw new Error('Invalid Item! - ' + JSON.stringify(searchItem));
			}

			const groupIndex = state.searchGroups.findIndex((searchGroup: SearchGroup) => {
				const groupId = searchItem.search_item_group_id ? searchItem.search_item_group_id : searchItem.search_group?.id;
				return searchGroup.id === groupId;
			});

			if (groupIndex !== -1) {
				const searchItems = state.searchGroups[groupIndex].search_items;

				if (Array.isArray(searchItems)) {
					const searchItemIndex = searchItems.findIndex((searchItemInGroup: AbstractSearchItem) => {
						return searchItemInGroup.uuid === searchItem.uuid;
					});

					if (searchItemIndex !== -1) {
						if (searchItems instanceof SearchItemList) {
							const arrayWithoutProxy = [...searchItems]; // Create a shallow copy
							arrayWithoutProxy.splice(searchItemIndex, 1); // Remove the item
							state.searchGroups[groupIndex].search_items = arrayWithoutProxy; // Set the modified array back to the state
						} else {
							searchItems.splice(searchItemIndex, 1);
							state.searchGroups[groupIndex].search_items = [...searchItems]; // Ensure we replace the array with a new one
						}
					}
				} else {
					console.error("search_items is not an array:", searchItems);
				}
			}
		}
	},
	actions: {
		resetGroups({ commit, state, getters }: any) {
			try {
				commit('setSearchGroups', getSearchGroupFactory().createEmptySearchGroupList());
			} catch (error: any) {
				console.error(error);
			}
		},
		addSearchGroup({ commit, state, getters }: any, {searchGroup}: any|SearchGroup) { //TODO define interface
			try {
				let foundSearchGroup;
				if (getters.getGroups) {
					foundSearchGroup = getters.getGroups.find((group: SearchGroup) => {
						return group.uuid === searchGroup.uuid;
					});
				} else {
					commit('setSearchGroups', getSearchGroupFactory().createEmptySearchGroupList());
				}

				if (foundSearchGroup) {
					commit('addSearchGroup', searchGroup);
				} else {
					commit('addSearchGroupToList', searchGroup);
				}
				// commit('recalculateGroups');
			} catch (error: any) {
				console.error(error);
			}
		},
	},
	getters: {
		getGroups: (state: any): SearchGroupList|undefined => {
			if (state.searchGroups instanceof SearchGroupList) {
				return state.searchGroups;
			} else if (!(state.searchGroups instanceof SearchGroupList)) {
				return getSearchGroupFactory().createSearchGroupListByResponse(state.searchGroups);
			} else {
				return undefined;
			}
		},
		getGroup_by_Id: (state: any, getters: any) => (group_id: string): SearchGroup | undefined => {
			const group = getters.getGroups.getItemById(group_id);
			if (group) {
				return group;
			}
			return undefined;
		},
		getGroup_by_Uuid: (state: any, getters: any) => (group_uuid: string): SearchGroup | undefined => {
			const group = getters.getGroups.getItemByUuId(group_uuid);
			if (group) {
				return group;
			}
			return undefined;
		},
		getLastGroupUuid: (state: any, getters: any): string|null|undefined => {
			if (state.lastSearchGroupUuid) {
				return state.lastSearchGroupUuid;
			} else {
				return getters.getGroups.getLastUuid();
			}
			return;
		},
		getItems_by_GroupUuid: (state: any, getters: any) => (group_uuid: string): SearchItemList | undefined => {
			const group = getters.getGroups.getItemByUuId(group_uuid);
			if (group && group.search_items) {
				return group.search_items;
			}
			return undefined;
		},
		getItem_by_SearchItem: (state: any, getters: any) => (searchItem: AbstractSearchItem): AbstractSearchItem | undefined => {
			// Find the group directly without iterating
			const searchGroup = state.searchGroups.find((group:SearchGroup) => group.id === searchItem.search_item_group_id);
			if (!searchGroup) {
				return;
			}

			if (!searchGroup.search_items) {
				return;
			}

			// Find the search item index directly
			const itemIndex = searchGroup.search_items.findIndex((item:AbstractSearchItem) => item.uuid === searchItem.uuid);

			if (itemIndex) {
				return searchGroup.search_items[itemIndex];
			}
			return undefined;
		},
		getItem_by_Uuid: (state: any, getters: any) => (uuid: string): AbstractSearchItem | undefined => {
			for (const group of getters.getGroups) {
				if (group.search_items) {
					const item = group.search_items.find((item: AbstractSearchItem) => item.uuid === uuid);
					if (item) {
						return item;
					}
				}
			}
			return undefined; // Return undefined if item not found
		},

	}
}

export default assistant;