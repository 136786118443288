
import {defineComponent, onMounted, ref} from 'vue';
import useUser from '@/composable/greeve/useUser';
import useAlert from '@/composable/core/useAlert';
import useSystem from '@/composable/core/useSystem';
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue';
import {EllipsisHorizontalIcon, PlusIcon} from '@heroicons/vue/20/solid';
import MetaOAuthComponent from '@/components/auth/MetaOAuthComponent.vue';
import useCustomStore from '@/composable/custom/useCustomStore';
import GoogleLoginComponent from '@/components/auth/GoogleLoginComponent.vue';

export default defineComponent({
	name: 'OAuthList',
	components: {
		GoogleLoginComponent,
		MetaOAuthComponent, EllipsisHorizontalIcon, Menu, MenuButton, MenuItem, MenuItems, PlusIcon},
	props: {},
	emits: [],
	setup() {
		const {getProfile, getSubscriptions, getOAuths, deleteOAuth, initOAuth} = useUser();
		const {addSuccess} = useAlert();
		const { setIsGlobalLoading } = useCustomStore();
		const {isFeatureFlagActive} = useSystem();

		const OAuthType = {
			OAUTH_TYPE_META: 'meta',
			OAUTH_TYPE_META_INSTAGRAM: 'meta_instagram',
			OAUTH_TYPE_META_WHATSAPP: 'meta_whatsapp',
			OAUTH_TYPE_GOOGLE: 'google',
		}

		const isDownloadingUserData = ref(false);
		const isLoading = ref(false);

		function deleteOAuthToProfile(uuid: string) {
			isLoading.value = true;
			setIsGlobalLoading(true);
			deleteOAuth(uuid).then(() => {
				const success = {
					id: "",
					title: "OAuth Account removed!",
					message: "",
					active: true,
				}
				addSuccess(success);
				isLoading.value = false;
				setIsGlobalLoading(false);
			});
		}

		// async function updateUserInformation() {
		// 	const userInformationData: UpdateUserDataName = {
		// 		first_name: String(userInformation.value.firstName),
		// 		last_name: String(userInformation.value.lastName),
		// 	};
		// 	try {
		// 		resetAllAlerts();
		// 		if (getProfile.value.first_name !== userInformationData.first_name || getProfile.value.last_name !==
		// 				userInformationData.last_name) {
		// 			await GreeveApiProfile.updateName(userInformationData);
		// 		}
		// 		const success = {
		// 			id: '',
		// 			title: 'Personal Information updated!',
		// 			message: 'Your First and Lastname successfully updated',
		// 			active: true,
		// 		};
		// 		addSuccess(success);
		// 	} catch (e) {
		// 		console.log(e);
		// 		addError({title: t('auth.auth_error_failed_title'), message: t('auth.failed'), active: true});
		// 	}
		// }

		onMounted(() => {
			//TODO load oauth list via api
			initOAuth();
		});

		return {
			getProfile,
			getSubscriptions,
			isDownloadingUserData,
			isFeatureFlagActive,
			deleteOAuthToProfile,
			getOAuths,
			OAuthType,
		};
	},
});
