import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "float-right ml-2 mt-0.5" }
const _hoisted_3 = { class: "flex select-none" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "ml-3" }
const _hoisted_6 = {
  key: 0,
  class: "list-disc pl-5 space-y-1",
  role: "list"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "text-lg font-bold leading-6" }
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_XCircleIcon = _resolveComponent("XCircleIcon")!
  const _component_CheckCircleIcon = _resolveComponent("CheckCircleIcon")!
  const _component_ExclamationTriangleIcon = _resolveComponent("ExclamationTriangleIcon")!

  return (_ctx.showAlert && ( (_ctx.getAllAlerts() && _ctx.getAllAlerts()[_ctx.alertType] && _ctx.getAllAlerts()[_ctx.alertType].length > 0) || _ctx.alertMessage))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([[_ctx.alertPadding], "w-full relative z-10 sticky mt-7 my-4 mb-2 mb-2 sm:mb-4 md:mb-4 p-0 rounded-xl"])
      }, [
        _createElementVNode("div", {
          id: _ctx.alertId,
          class: _normalizeClass([[_ctx.alertPosition, 'rounded-xl backdrop-filter backdrop-blur-3xl bg-opacity-20', _ctx.isError() ? 'bg-red-400 dark:bg-red-700 ' : '', _ctx.isSuccess() ? 'bg-green-400 dark:bg-green-700' : '', _ctx.isWarning() ? 'bg-yellow-400 dark:bg-yellow-700' : '', _ctx.isSecondary() ? 'bg-gray-400 dark:bg-gray-700' : ''], "md:mb-6 align-middle rounded-xl rounded-2xl p-2 sm:p-4 pl-3 sm:pl-5 text-md text-gray-900 dark:text-gray-400"])
        }, [
          _withDirectives(_createElementVNode("div", _hoisted_2, [
            _createVNode(_component_XMarkIcon, {
              class: "w-5 cursor-pointer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeAlert(_ctx.alertId)))
            })
          ], 512), [
            [_vShow, _ctx.showCloseButton]
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _withDirectives(_createVNode(_component_XCircleIcon, {
                "aria-hidden": "true",
                class: "h-12 w-12 text-red-400"
              }, null, 512), [
                [_vShow, _ctx.isError()]
              ]),
              _withDirectives(_createVNode(_component_CheckCircleIcon, {
                "aria-hidden": "true",
                class: "h-12 w-12 text-green-400"
              }, null, 512), [
                [_vShow, _ctx.isSuccess()]
              ]),
              _withDirectives(_createVNode(_component_ExclamationTriangleIcon, {
                "aria-hidden": "true",
                class: "h-12 w-12 text-yellow-400"
              }, null, 512), [
                [_vShow, _ctx.isWarning()]
              ]),
              _withDirectives(_createVNode(_component_ExclamationTriangleIcon, {
                "aria-hidden": "true",
                class: "h-12 w-12 text-gray-400"
              }, null, 512), [
                [_vShow, _ctx.isSecondary()]
              ]),
              _createElementVNode("div", _hoisted_5, [
                _withDirectives(_createElementVNode("h3", {
                  class: _normalizeClass(['text-lg font-bold leading-6',  _ctx.isError() ? 'text-red-800 dark:text-red-300' : '', _ctx.isSuccess() ? 'text-green-800 dark:text-green-300' : '', _ctx.isWarning() ? 'text-yellow-800 dark:text-yellow-300' : '', _ctx.isSecondary() ? 'text-gray-800 dark:text-gray-300' : ''])
                }, _toDisplayString(_ctx.alertTitle), 3), [
                  [_vShow, _ctx.showTitle && _ctx.alertTitle.length > 0]
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(['mt-1 text-sm md:text-base line-clamp-3', _ctx.isError() ? 'text-red-700 dark:text-red-300' : '', _ctx.isSuccess() ? 'text-green-700 dark:text-green-300' : '', _ctx.isWarning() ? 'text-yellow-700 dark:text-yellow-300' : '', _ctx.isSecondary() ? 'text-gray-700 dark:text-gray-300' : ''])
                }, [
                  (_ctx.showAsList)
                    ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAllAlerts()[_ctx.alertType], (item) => {
                          return (_openBlock(), _createElementBlock("li", { key: item }, _toDisplayString(item), 1))
                        }), 128))
                      ]))
                    : (!_ctx.showAsList && _ctx.hasAlertMessageList())
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAllAlerts()[_ctx.alertType], (item) => {
                            return (_openBlock(), _createElementBlock("div", { key: item }, [
                              _createElementVNode("div", _hoisted_8, _toDisplayString(item.title), 1),
                              _createTextVNode(" " + _toDisplayString(item.message), 1)
                            ]))
                          }), 128))
                        ]))
                      : (_openBlock(), _createElementBlock("p", {
                          key: 2,
                          innerHTML: _ctx.alertMessage
                        }, null, 8, _hoisted_9))
                ], 2)
              ])
            ])
          ])
        ], 10, _hoisted_1)
      ], 2))
    : _createCommentVNode("", true)
}