
import {defineComponent, ref} from 'vue';
import 'aos/dist/aos.css';
import useTranslation from '@/composable/translation/useTranslation';
import {CheckCircleIcon, XMarkIcon, XCircleIcon} from '@heroicons/vue/20/solid';
import useCustomStore from '@/composable/custom/useCustomStore';
import useContact from '@/composable/greeve/useContact';
import useSystem from '@/composable/core/useSystem';

export const enum ContactTypes {
	CONTACT_JOB = 'job',
	CONTACT_DEFAULT = 'default',
	CONTACT_ENTERPRISE = 'enterprise',
}

export interface CONTACT_FORM_INTERFACE {
	type: ContactTypes;
	email: string;
	agreePrivacy: boolean;
	name?: string;
	firstName?: string;
	lastName?: string;
	language?: string;
	data?: {
		company?: string,
		phone?: string,
		help?: string,
		subject?: string,
		message?: string,
		howDidYouHearAboutUs?: string,
	};
}

export default defineComponent({
	name: 'InquiryComponent',
	components: {CheckCircleIcon, XMarkIcon, XCircleIcon},
	props: {
		contactType: {
			type: Object as () => ContactTypes,
			default: ContactTypes.CONTACT_DEFAULT,
		},
		contactCss: {
			type: String,
			default: 'mx-4 nav rounded-2xl p-4 lg:pt-20 nav lg:px-20',
		},
		contactHeader: {
			type: String,
			default: '',
		},
		contactHeaderCss: {
			type: String,
			default: 'mt-4 text-black dark:text-black text-3xl font-extrabold tracking-tight sm:text-4xl',
		},
		contactHeaderInfo: {
			type: String,
			default: '',
		},
		contactHeaderInfoCss: {
			type: String,
			default: 'text-black dark:text-black mt-4 text-lg sm:mt-3',
		},
		showCompany: {
			type: Boolean,
			default: false,
		},
		showHowDidYouHearAboutUs: {
			type: Boolean,
			default: false,
		},
		contactSubmitButton: {
			type: String,
			default: '',
		},
	},
	methods: {},
	setup(props) {
		const {t} = useTranslation();
		const {getLanguageLocale} = useCustomStore();
		const {sendContact} = useContact();
		const {scrollToElement} = useSystem();

		const contactData = ref<CONTACT_FORM_INTERFACE>({
			'type': props.contactType,
			'email': '',
			'name': '',
			'firstName': '',
			'lastName': '',
			'agreePrivacy': false,
			'language': getLanguageLocale.value,
			'data': {
				'company': '',
				'phone': '',
				'help': '',
				'subject': '',
				'message': '',
				'howDidYouHearAboutUs': '',
			},
		});
		const successMessage = ref('');
		const errorMessage = ref('');

		function scrollToAlertMessage() {
			scrollToElement('contact-alert-message');
		}

		function resetContactData() {
			contactData.value = {
				'type': props.contactType,
				'email': '',
				'name': '',
				'firstName': '',
				'lastName': '',
				'agreePrivacy': false,
				'language': getLanguageLocale.value,
				'data': {
					'company': '',
					'phone': '',
					'help': '',
					'subject': '',
					'message': '',
					'howDidYouHearAboutUs': '',
				},
			};
		}

		function sendContactForm() {
			successMessage.value = '';
			errorMessage.value = '';
			contactData.value.language = getLanguageLocale.value;
			if (!contactData.value.name || contactData.value.name.length === 0) {
				contactData.value.name = contactData.value.firstName + ' ' + contactData.value.lastName;
			}
			sendContact(contactData.value).then(() => {
						successMessage.value = t('inquiry.FormSuccess');
						resetContactData();
						scrollToAlertMessage();
					},
					error => {
						console.log(error);
						errorMessage.value = t('inquiry.FormError');
						scrollToAlertMessage();
					});
		}

		const primaryFeatures = [
			{
				name: 'Push to deploy.',
				description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
				icon: CheckCircleIcon,
			},
			{
				name: 'SSL certificates.',
				description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
				icon: CheckCircleIcon,
			},
			{
				name: 'Database backups.',
				description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.',
				icon: CheckCircleIcon,
			},
		];
		const secondaryFeatures = [
			{
				name: 'Push to deploy',
				description:
						'Commodo nec sagittis tortor mauris sed. Turpis tortor quis scelerisque diam id accumsan nullam tempus. Pulvinar etiam lacus volutpat eu. Phasellus praesent ligula sit faucibus.',
				href: '#',
				icon: CheckCircleIcon,
			},
			{
				name: 'SSL certificates',
				description:
						'Pellentesque enim a commodo malesuada turpis eleifend risus. Facilisis donec placerat sapien consequat tempor fermentum nibh.',
				href: '#',
				icon: CheckCircleIcon,
			},
			{
				name: 'Simple queues',
				description:
						'Pellentesque sit elit congue ante nec amet. Dolor aenean curabitur viverra suspendisse iaculis eget. Nec mollis placerat ultricies euismod ut condimentum.',
				href: '#',
				icon: CheckCircleIcon,
			},
		];

		return {t, contactData, secondaryFeatures, primaryFeatures, successMessage, errorMessage, sendContactForm};
	},
});

